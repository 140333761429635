import _ from "lodash"
import { BrandType, Trademark } from "../../trademarks/TrademarksProvider"

const ns1Uri = 'http://www.wipo.int/standards/XMLSchema/ST96/Common'
const ns2Uri = 'http://www.wipo.int/standards/XMLSchema/ST96/Trademark'

// TODO: simulate errors

export function getCaseInfo(applicationNumber: string) {
    // TODO: check if applicationNumber is valid
    return fetch(`/api/uspto/pass-through/ts/cd/casestatus/sn${applicationNumber}/info`)
}

export function parseXmlResponse(xmlDocument: XMLDocument): Partial<Trademark> & {classes: {number: number, description: string}[], image?: string, owner?: string} {
    //const elements = xmlDocument.getElementsByTagName('ns1:RegistrationOfficeCode')

    function getValue(uri: string, name) {
        return xmlDocument.getElementsByTagNameNS(uri, name)?.item(0)?.textContent
    }
    const countryCode = getValue(ns1Uri, 'RegistrationOfficeCode')

    const applicationNumber = getValue(ns1Uri, 'ApplicationNumberText')
    const registrationNumber = getValue(ns1Uri, 'RegistrationNumber')

    const applicationDate = getValue(ns2Uri, 'ApplicationDate')?.slice(0, 10)
    const registrationDate = getValue(ns1Uri, 'RegistrationDate')?.slice(0, 10)

    const words = getValue(ns2Uri, 'MarkVerbalElementText')    
    const image = getValue(ns1Uri, 'FileName')

    const events = _(xmlDocument.getElementsByTagNameNS(ns2Uri, 'MarkEventCode') ?? [])
        .map(e => e.textContent)
        .value()
    
    const status = 
        events.includes('CAEXO') ? 'stopped' :
        events.includes('R.PRA') ? 'registered' :
        'applied'

    let brandType: BrandType = 'Other'
    if (words !== undefined && image !== undefined) {
        brandType = 'Combined'
    } else if (words !== undefined) {
        brandType = 'Word'
    } else if (image !== undefined) {
        brandType = 'Image'
    } // TODO check for sound 

    const classes = Array.from(xmlDocument.getElementsByTagNameNS(ns2Uri, 'ClassDescriptionBag'))
        .map(e => {
            const number = parseInt(e.getElementsByTagNameNS(ns2Uri, 'ClassNumber').item(0)?.textContent)
            const description = e.getElementsByTagNameNS(ns2Uri, 'GoodsServicesDescriptionText')
                .item(0)?.textContent
                ?.split('\n').map(e => e.trim()).join(' ')
            return { number, description }
        })
        .filter(e => e.number !== undefined && e.description !== undefined)

    const owner = getValue(ns1Uri, 'EntityName')
    
    return {
        countryCode,
        applicationNumber, applicationDate,
        registrationNumber, registrationDate,
        words, image, classes, brandType,
        status, owner,
    }
}