import { Helmet } from "react-helmet-async"
import { useTranslation } from "react-i18next"
import { FamilyMemberStatus, IpType, Member } from "./patents"
import { emptyStringAsUndefined } from "../utils/strings"
import TagList from "../components/TagList"
import { useState } from "react"
import _ from "lodash"
import { useMessages } from "../Messages"
import { usePatents } from "./PatentsProvider"
import { IconSpinner } from "../components/icons"


export default function BulkEdit() {
    const { t } = useTranslation()

    const [inputFields, setInputFields] = useState([])
    const [input, setInput] = useState('')
    const [data, setData] = useState(undefined)
    const {setErrorMessage} = useMessages()

    const showUpdateDate = data !== undefined

    const availableTags = _(memberParser).keys().sortBy(tag => t(tag)).value()
    const tagDisplays = _(availableTags).map((tag) => [tag, t(tag)]).fromPairs().value()

    function doWork() {
        const data = input.split('\n').map(line => {
            return line.split('\t')
        })
        if (data[0].length !== (inputFields.length + 1)) {
            setErrorMessage(`Number of fields in input (${data[0].length}) does not match the number of selected fields (${inputFields.length + 1})`)
            return 
        }
        //console.log({data})
        setData(data)
    }

    return <>
        {/* @ts-ignore */}
        <Helmet>
            <title>{t('bulk-edit')} | Patent Cockpit</title>
        </Helmet>
        <div className="modern-header-row">
            <h2 className="modern-h2">Bulk Edit</h2>
        </div>
        <div className="main-content">
            {showUpdateDate
                ? <MembersDiffTable {...{ setData, data, inputFields }} />
                : <>
                    <div className="">
                        <label className="label mb-2">{t('fields')}</label>
                        <div className="flex flex-row gap-1">
                            <span className="ribbon text-sm">{t('internalReference')}</span>
                            <TagList {...{
                                name: "searchFields",
                                dragable: true,
                                availableTags,
                                tags: inputFields,
                                setTags: setInputFields,
                                tagDisplays,
                                placeholder: t('field'),
                            }} />
                        </div>
                    </div>
                    <div className="py-6">
                        <div className="max-w-lg flex flex-row items-baseline gap-6 mb-2">
                            <label htmlFor="input" className="label mb-2">Input</label>
                            <button onClick={doWork} className="btn-primary py-0.5">Update</button>
                        </div>
                        <textarea id="input" className="form-textarea h-80 resize" value={input} onChange={e => setInput(e.target.value)} />
                    </div>
                </>}
        </div>
    </>
}

type MemberKeys = keyof Member; // Union of all keys: 'internalReference' | 'externalReference' | ...

const memberParser: { [K in keyof Partial<Member>]: (value: string) => Member[K] } = {
    // internalReference: (value) => value,
    externalReference: emptyStringAsUndefined,
    title: emptyStringAsUndefined,
    applicationDate: emptyStringAsUndefined,
    applicationNumber: emptyStringAsUndefined,
    publicationDate: emptyStringAsUndefined,
    publicationNumber: emptyStringAsUndefined,
    patentDate: emptyStringAsUndefined,
    patentNumber: emptyStringAsUndefined,
    familyMemberStatus: (value) => value as FamilyMemberStatus,
    ipType: (value) => (value ? value as IpType : undefined),
    countryCode: (value) => value,
    numberClaims: (value) => value ? parseInt(value, 10) : undefined,
    patentOfficeLink: emptyStringAsUndefined,
    expiryDate: emptyStringAsUndefined,
    abandonmentDate: emptyStringAsUndefined,
    firstFiling: asBoolean,
    validated: asBoolean,
    pctRouteFiling: asBoolean,
    unitaryPatent: asBoolean,
    optOut: asBoolean,
};

function asBoolean(value: string): boolean | undefined {
    const trimmed = value?.trim()?.toLowerCase() ?? '';
    return trimmed !== "" ? trimmed === "true" : undefined;
}


function parseMember(data: string[], keys: string[]): Partial<Member> {
    return _(keys).map((key, index) => {
        if (key in memberParser) {
            const fieldKey = key as MemberKeys;
            const value = memberParser[fieldKey](data[index]);
            return [key, value]
        } else {
            return undefined
        }
    }).filter(Boolean).fromPairs().value() as Partial<Member>
}

function MembersDiffTable({data, inputFields, setData}: {data: string[][], inputFields: string[], setData: (data: string[][]) => void}) {
    const {t} = useTranslation()

    const [isLoading, setIsLoading] = useState(false)

    const {memberByReference, postMember, reload} = usePatents()

    const fields = ['internalReference', ...inputFields]

    const updatedMembers = _(data)
        .map((row) => {
            const internalReference = row[0]
            const original = memberByReference[internalReference]
            return ({...parseMember(row, fields), internalReference, original})
        })
        .filter(row => row.original !== undefined)
        .value()
    // console.log({updatedMembers})

    async function doUpdate() {
        setIsLoading(true)
        await Promise.all(updatedMembers.map((member) => postMember({...member.original, ...member})))
        reload()
        setIsLoading(false)
        setData(undefined)
    }

    return <>
        <div className="flex flex-row gap-4 text-sm mb-2" >
            <button className="btn-secondary" onClick={() => setData(undefined)}>{t('cancel')}</button>
            <button className="btn-primary" onClick={() => doUpdate()}>{t('update')} {isLoading && <IconSpinner className="size-3 ml-1 mb-0.5 animate-spin inline" />}</button>
        </div>
        <table>
            <thead>
                <tr className="border-b-pcx-300 border-b-2">
                    {fields.map(field => <th key={field} className="px-2 py-1 uppercase font-semibold text-pcx-600 tracking-wider text-xs text-left">{t(field)}</th>)}
                </tr>
            </thead>
            <tbody>
                {updatedMembers.map((member, index) => {
                    const reference = member.internalReference
                    const original = member.original

                    return <tr key={index}>
                        {fields.map((field) => {
                            const value = member[field]
                            const old = original[field]
                            return <td key={field} className="px-2 py-px text-xs">
                                {value} {old !== value && old !== undefined && old !== '' && <span className="text-slate-500 line-through">({old})</span>}
                            </td>
                        })}
                    </tr>
                })}
            </tbody>
        </table>
    </>

}