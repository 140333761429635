import { isTrademark, searchBrand, toPcTrademark } from "./euipo/backend"
import { downloadTrademark as downloadUsptoTrademark } from "./uspto/trademarks"

export type ImportFormat = 'uspto' | 'euipo' | 'unknown'

export function parseTrademarkRegistrationNumber(applicationNumber: string): {format: ImportFormat, number: string} {
    const usmatch = applicationNumber.match(/^US(\d{8})$/)
    if (usmatch) return {format: 'uspto', number: usmatch[1]}

    const emmatch = applicationNumber.match(/^E[M|U](\d{8,9})$/)
    if (emmatch){
        const number = emmatch[1]?.padStart(9, '0')
        return {format: 'euipo', number}
    }

    return {format: 'unknown', number: applicationNumber}
}

export const formats = [
    {office: 'USPTO', format: 'USNNNNNNNN', comment: '(8 digits)'},
    {office: 'EUIPO', format: 'EMNNNNNNNNN', comment: '(8 or 9 digits)'},
]

export async function downloadTrademark(parserResult: { format: ImportFormat, number: string }) {
    if (parserResult.format === 'euipo') {
        const response = await searchBrand(parserResult.number)
        //console.log({response})
        // Show error when error, else go to add with import state
        if (isTrademark(response)) {
            const pcTrademark = toPcTrademark(response)
            return pcTrademark
        } else {
            if ('title' in response)
                throw new TrademarkError(response.title, response.detail)
            else
                throw new TrademarkError('Error while importing EUIPO trademark', response?.['httpMessage'])
        }
    } else if (parserResult.format === 'uspto') {
        try {
            const response = await downloadUsptoTrademark(parserResult.number)
            return response
        } catch (error) {
            throw new TrademarkError('Error while importing USPTO trademark', error.message)
        }
    }
}

export class TrademarkError extends Error {
    constructor(public title: string, public detail?: string) {
        super(detail ? (title + ' - ' + detail) : title)
    }
}