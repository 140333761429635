
import { Fragment, useState } from "react";
import { useTranslation } from "react-i18next";
import Modal from "../components/Modal";
import { Link, useNavigate  } from "react-router-dom";
import { CheckCircleIcon } from "@heroicons/react/20/solid";
import { useTrademarkFamily } from "./TrademarkFamily";
import _ from "lodash";
import { downloadTrademark, formats, parseTrademarkRegistrationNumber, TrademarkError } from "../import/trademarks";

export function TrademarkSingleImport() {
    const {t} = useTranslation()

    const [isSubmitting, setIsSubmitting] = useState(false)
    const [registrationNumber, setRegistrationNumber] = useState('')
    const [error, setError] = useState(undefined)

    const navigate = useNavigate()

    const family = useTrademarkFamily()
    const familyId = family?.familyId

    const parsedResult = parseTrademarkRegistrationNumber(registrationNumber)
    const isCorrect = parsedResult.format !== 'unknown'

    async function doImport() {
        // fill up reigstration number with zeros from the left until we have 9 characters
        if (parsedResult.format === 'unknown') {
            setError({ title: 'Could not parse Number' })
            return
        }
        
        setIsSubmitting(true)
        try {
            const result = await downloadTrademark(parsedResult)
            navigate({ pathname: '../add', search: `?familyId=${familyId}` }, { state: { prefill: {...result, familyId} } })
        } catch (error) {
            console.warn('Import error: ', error)
            if (error instanceof TrademarkError)
                setError({ title: error.title, detail: error.detail })
            else
                setError({ title: 'Error while importing trademark', detail: error.message })
        }
        setIsSubmitting(false)
    }

    return (
        <Modal>
            <div className="p-4">
                <h3 className="mb-1">{t('import')}</h3>
                <dl className="text-sm text-slate-500 mb-2 grid grid-cols-[auto_1fr] gap-x-2">
                    {_(formats).map(({format, comment, office}) =>
                        <Fragment key={office}>
                            <dt className="font-semibold">{office}:</dt>
                            <dd className=""><span className="font-mono tracking-wide font-light">{format}</span> {comment}</dd>
                        </Fragment>
                    ).value()}
                </dl>
                <label className="label block mb-2" htmlFor="applicationNumber">{t('applicationNumber')}</label>
                <div className="mb-4 relative">
                    <input
                        id='applicationNumber' className="form-input w-full pr-10" autoFocus required
                        value={registrationNumber} onChange={e => setRegistrationNumber(e.target.value)} />
                    {isCorrect && <CheckCircleIcon className="size-5 absolute right-2 top-1/2 transform -translate-y-1/2 text-pcx-400"/>}
                </div>
                {error &&
                    <div className="text-warn-900 max-w-2xs text-xs pl-3 pr-1 py-1 border-l-4 border-warn-500 bg-warn-50">
                        <h4 className="font-semibold text-xs text-warn-900 mb-1">{error.title}</h4>
                        <p>{error.detail ?? ''}</p> 
                        {/* <a href={`mailto:support@patent-cockpit.com?subject=EIOPA-${encodeURIComponent(error.title + ' -- ' + error.detail)}`}>
                            <EnvelopeIcon className="size-3 inline mb-px mr-0.5"/> <span  className="underline">{t('support')}</span>
                        </a> */}
                    </div>}
            </div>
            <div className="flex flex-row-reverse gap-4 p-4 bg-pcx-200">
                <button className="btn-primary" onClick={doImport}>{t('import')} </button>
                {/* @ts-ignore */}
                <Link to={-1} className="btn-secondary">{t('cancel')}</Link>
            </div>
        </Modal>
    )
}