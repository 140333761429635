import { parseDate, today, getLocalTimeZone } from "@internationalized/date";
import _ from "lodash";

import { Member } from "../patents/patents";
import { missingInformation, requiresGrant } from "./check";
import { Instruction, IpRight, MaintenanceAction, MaintenanceActionPhase, PcInstruction, PcIpRight, ValidationMessage } from "./DennemeyerProvider";
import { DueDateStatus } from "./settings/instruction_timeline";

// Preparing functions for Notion Flow Chart 
// https://www.notion.so/patent-cockpit/Flow-Charts-for-Renewal-States-566df9d644f34769a7b66f368526600c?pvs=4

export type RenewalStatus =
    'not-handled' |
    'ready' 

export type RenewalSubStatus =
    'ignored' |
    'stopped' |
    'inactive' |
    'missing-data' |
    'has-errors' |
    'can-import' |
    'paid-for-life' |
    'back-office-processing' |
    'fully-ready'

export type RenewalStatusInfo = {
    status: RenewalStatus,
    substatus: RenewalSubStatus,
    messages?: string[],
    reason?: string,
}

// TODO: just focus on not-handled cases
export function renewalStatusToString(status: RenewalStatusInfo, t: (key: string, options?: object) => string): string {
    //console.log({status})
    if (status.status === 'not-handled') {
        if (status.substatus === 'ignored' && status.reason) {
            return `${t(status.status)} (${t(status.reason)})`
        } else if (status.substatus === 'missing-data' || status.substatus === 'has-errors') {
            return `${t(status.status)} (${t(status.substatus)})`
        } else if (status.substatus === 'inactive' || status.substatus === 'stopped') {
            return `${t(status.status)} (${t(status.substatus)})`
        } else {
            return `${t(status.status)}`
        }
    // we shouldn't go below this
    } else if (status.substatus === 'fully-ready') {
        return t(status.status)
    } else {
        return t(status.substatus)
    }
}

export function isImported(status: RenewalStatusInfo) {
    return status.status === 'ready' || status.substatus === 'inactive' || status.substatus === 'has-errors'
}

export function isNotImportable(renewalStatus: RenewalStatusInfo) {
    return (renewalStatus.substatus === 'ignored')
}

export function renewalStatusExtraction(
    {member, pcIpRight, ipRight, validationErrors}:
    {member: Member, pcIpRight?: PcIpRight, ipRight?: IpRight, validationErrors?: ValidationMessage[]}
): RenewalStatusInfo {
    if (member.countryCode === 'WO') {
        return {status: 'not-handled', substatus: 'ignored', reason: 'pct-not-importable'}
    } else if (pcIpRight?.dennemeyerId) {
        return differentiateImportState({member, ipRight, pcIpRight, validationErrors})
    } else if (member.familyMemberStatus === 'in-preparation') {
        return {status: 'not-handled', substatus: 'ignored', reason: 'in-preparation'}
    } else if (member.familyMemberStatus === 'stopped') {
        return {status: 'not-handled', substatus: 'stopped'}
    } else if (requiresGrant(member)) {
        return {status: 'not-handled', substatus: 'ignored', reason: 'not-granted'}
    } else if (member.countryCode === 'EP') {
        // EP differentiation
        if (member.familyMemberStatus === 'granted' && member.unitaryPatent !== true) {
            return {status: 'not-handled', substatus: 'ignored', reason: 'granted-ep'}
        } else {
            return {status: 'not-handled', substatus: 'can-import'}
        }
    // } else if (grantRequired && member.familyMemberStatus !== 'granted') {
    //     return {status: 'ignored', reason: 'not-granted'}
    } else {
        const missing = missingInformation(member)
        if (missing.length > 0) {
            return { status: 'not-handled', substatus: 'missing-data', messages: missing }
        } else {
            return { status: 'not-handled', substatus: 'can-import' }
        }
    }
}

function hasRecentChanges(pcIpRight: PcIpRight) {
    const now = today(getLocalTimeZone())
    const updated = parseDate(pcIpRight.updated.slice(0, 10))
    return (now.subtract({ days: 7 }) < updated && !pcIpRight.dirty)
}

function differentiateImportState({ipRight, pcIpRight, validationErrors}: {member: Member, ipRight?: IpRight, pcIpRight: PcIpRight, validationErrors?: ValidationMessage[]}): RenewalStatusInfo {
    //console.log({ipRight, pcIpRight, validationErrors})
    // Ignore errors if it's actually ready at DM
    /*if (ipRight?.Status?.includes('Granted') && ipRight?.IntegrationStatus === 'Imported') {
        return {status: 'ready', substatus: 'fully-ready'}
    } else*/ if (validationErrors?.length > 0) {
        return {status: 'not-handled', substatus: 'has-errors', messages: validationErrors.map(e => e.message)}
    } else if (ipRight?.Status?.includes('Inactive')) {
        if (pcIpRight.updated !== undefined && (pcIpRight.status === 'Granted' || pcIpRight.status === 'Pending')) {
            if (hasRecentChanges(pcIpRight)) {
                // The IP Right was stopped and now revived again but the back office didn't see it yet
                return { status: 'ready', substatus: 'back-office-processing' }
            }
        } // default case:
        return { status: 'not-handled', substatus: 'stopped' }
    } else if (ipRight === undefined) {
        return {status: 'ready', substatus: 'back-office-processing'}
    } else if (ipRight?.NextMaintenanceActionDueDate !== undefined && ipRight?.NextMaintenanceActionDueDate === ipRight?.ExpiryDate) {
        return {status: 'ready', substatus: 'paid-for-life'}
    } else {
        if (pcIpRight.status === 'Inactive' && hasRecentChanges(pcIpRight)) {
            return { status: 'not-handled', substatus: 'stopped' }
        }
        // default case
        return {status: 'ready', substatus: 'fully-ready'}
    }
}



export function isMaintenanceActionPhaseOpen(phase: MaintenanceActionPhase) {
    return phase === "Auto-Pay in Progress" || phase === "Waiting For Instruction"
}



export type MaintenanceActionStatus = 
        'not-ready' | 'ready' /* Means no instructions */ | 'instructed' | 'payment-will-be-triggered' | 'payment-has-been-triggered' |
        'fee-will-not-be-paid-unless-instructed' | 'fee-will-not-be-paid' | 'fee-has-not-been-paid'

export type MaintenanceActionStatusInfo = {
    status: MaintenanceActionStatus,
    dennemeyerId?: string,
    instruction?: Instruction,
    date?: string,
}

export function maintenanceActionStatusToString(status: MaintenanceActionStatusInfo, t: (key: string, options?: object) => string): string {
    // console.log({status})
    
    const instructed_s = () =>
        (status.instruction === 'Pay')
            ? t('ma.instructed-pay-on', status)
            : (status.instruction === 'Cancel')
            ? t('ma.instructed-cancel-on', status)
            : t('ma.unknown-instruction', status)

    switch (status.status) {
        case 'not-ready': return '' // This should never happen...
        case 'instructed': return instructed_s()
        case 'payment-will-be-triggered': return t('ma.payment-will-be-triggered-on', status)
        case 'payment-has-been-triggered': return t('ma.payment-has-been-triggered-on', status)
        case 'fee-will-not-be-paid-unless-instructed': return t('ma.fee-payment-no-instructed-yet')
        case 'fee-will-not-be-paid': return t('ma.fee-will-not-be-paid')
        case 'fee-has-not-been-paid': return t('ma.fee-has-not-been-paid')
        case 'ready': return t('ready')
    }
}


// Make sure to run `eliminateDoubleMaintenanceActions` first on the maintenanceActions before passing them in
export function maintenanceActionsStatus({
    renewalStatus, maintenanceActions, calculateDueDates, instructionByDennemeyerId,
}: {
    renewalStatus: RenewalStatus, 
    maintenanceActions?: MaintenanceAction[],
    calculateDueDates: (dueDate: string) => {instructionDueDate: string, status: DueDateStatus} | undefined,
    instructionByDennemeyerId: Record<string, PcInstruction>,
}): MaintenanceActionStatusInfo & {total: number} {
    if ((maintenanceActions ?? []).length === 0) {
        const status = renewalStatus === 'ready' ? 'ready' : 'not-ready'
        return {status, total: 0}
    } else {
        const lastMaintenanceAction = _.maxBy(maintenanceActions, ma => ma.DueDate)
        const dueDate = calculateDueDates(lastMaintenanceAction.DueDate)
        const maintenanceAction = {...lastMaintenanceAction, ...(dueDate ?? {})}
        return {
            ...maintenanceActionStatus({ maintenanceAction, instruction: instructionByDennemeyerId[lastMaintenanceAction.DennemeyerId]}), 
            total: maintenanceActions.length,
            dennemeyerId: maintenanceAction.DennemeyerId,
        }
    }
}

function maintenanceActionStatus(
    { maintenanceAction, instruction }: 
    { maintenanceAction: MaintenanceAction & {instructionDueDate?: string, status?: DueDateStatus}, instruction: PcInstruction }
): MaintenanceActionStatusInfo {

    // if there is no instruction, let's not consider it as instructed although DM says so
    const isInstructed = (maintenanceAction.Phase === "Instructed / In Progress" && (instruction !== undefined || maintenanceAction.InstructionInfo !== undefined)) 
        || maintenanceAction.Phase === "Closed" || instruction !== undefined

    if (isInstructed) {
        if (maintenanceAction.InstructionInfo?.Instruction === 'Pay' || instruction?.instruction === 'Pay') {
            return {status: 'instructed', instruction: 'Pay', date: (maintenanceAction.InstructionInfo?.InstructedAt ?? instruction?.created)?.slice(0, 10)}
        } else if (maintenanceAction.InstructionInfo?.Instruction === 'Cancel' || instruction?.instruction === 'Cancel') {
            return {status: 'instructed', instruction: 'Cancel', date: (maintenanceAction.InstructionInfo?.InstructedAt ?? instruction?.created)?.slice(0, 10)}
        } else {
            return {status: 'instructed', date: (maintenanceAction.InstructionInfo?.InstructedAt ?? instruction?.created)?.slice(0, 10)}
        }
    } else {
        if (maintenanceAction.PermanentOrder) { // auto-pay
            if (maintenanceAction.Phase.includes('Inactive')) {
                return { status: 'fee-will-not-be-paid' }
            }
            const today = new Date().toISOString().slice(0, 10)
            const isPast = maintenanceAction.instructionDueDate <= today
            return { status: isPast ? 'payment-has-been-triggered' : 'payment-will-be-triggered', date: maintenanceAction.instructionDueDate }
        } else {
            if (maintenanceAction?.status === 'open' || maintenanceAction?.status === 'early' || maintenanceAction?.status === 'critical') {
                return { status: 'fee-will-not-be-paid-unless-instructed' }
            } else if (maintenanceAction?.status === 'manual-instruction') {
                return { status: 'fee-will-not-be-paid' }
            } else {
                return { status: 'fee-has-not-been-paid' }
            }
        }
    }
}

export const renewalChangeModalStr = 'renewal-change'
export type RenewalChange = 'can-be-imported' | 'has-been-stopped' | undefined

export function canBeImported(
    {oldMember, newMember, pcIpRight, ipRight}:
    {oldMember?: Member, newMember: Member, pcIpRight: PcIpRight, ipRight: IpRight, validationErrors?: ValidationMessage[]}) {
    const newStatus = renewalStatusExtraction({member: newMember, pcIpRight, ipRight})
    //console.log({newStatus})
    if (newStatus.substatus === 'can-import') {
        if(oldMember === undefined) {
            return true
        }
        const oldStatus = renewalStatusExtraction({member: oldMember, pcIpRight, ipRight})
        //console.log({oldStatus})
        //console.log({oldStatus, result: !(oldStatus.status === 'can-import' || isImported(oldStatus.status, ipRight)), imported: isImported(oldStatus.status, ipRight)})
        return !(oldStatus.substatus === 'can-import' || isImported(oldStatus))
    } else {
        return false
    }
}

export function canBeStopped(
    {oldMember, newMember, pcIpRight, ipRight, validationErrors}: 
    { oldMember?: Member, newMember: Member, pcIpRight?: PcIpRight, ipRight?: IpRight, validationErrors?: ValidationMessage[] }) {

    if (oldMember?.familyMemberId === undefined) return false

    const oldStatus = renewalStatusExtraction({ member: oldMember, pcIpRight, ipRight, validationErrors })
    return oldStatus.status === 'ready' && newMember.familyMemberStatus === 'stopped' && oldMember.familyMemberStatus !== 'stopped'
}