import React, { Dispatch, SetStateAction, useEffect } from "react"
import { Helmet } from "react-helmet-async"
import { parseDate, getLocalTimeZone, today as createToday } from '@internationalized/date';
import { useTranslation } from "react-i18next"

import { useLocalState } from "../settings/localStorage"
import { useHints } from "./Hints";
import DateRangePicker from "../components/input/DateRangePicker";
import clsx from "clsx";
import { useDmSettings, useMaintenances, useTimeline } from "./DennemeyerProvider";
import { eliminateDoubleMaintenanceActions, useAugmentMaintenanceAction } from "./utils";
import _ from "lodash";
import { StatusView } from "./MaintenanceActionInstruction";
import { memberUrl } from "../patents/utils";
import { Link } from "react-router-dom";
import { SortButton } from "../patents/datawizard/DataWizard";
import { AugmentedMaintenanceAction } from "./utils";
import { IconSpinner } from "../components/icons";

export default function MaintenanceHistory() {
    const {t} = useTranslation()

    const {hint, isHintNeeded} = useHints()

    return <>
        {/* @ts-ignore */}
        <Helmet>
            <title>{t('past-maintenances')} | {t('maintenances')} | {t('renewal-fees')} | Patent Cockpit</title>
        </Helmet>

        <MaintenanceHistoryProvider>
            <div className="header-row pb-1 flex flex-row gap-1 items-center max-w-4xl pr-2">
                <h2 className="modern-h2 mr-auto">{t('past-maintenances')}</h2>
                <MaintenanceHistoryFilter />
            </div>
            <div className="px-2 pb-6 max-w-4xl">
                {isHintNeeded ? hint : <MaintenanceHistoryTable />}
            </div>
        </MaintenanceHistoryProvider>
    </>
}

const today = createToday(getLocalTimeZone())

const sortByValues = [
    'internalReference',
    'due',
    'status',
]

const MaintenanceFilterContext = React.createContext({
    minDate: undefined as string,
    setMinDate: (() => {}) as Dispatch<SetStateAction<string>>,
    maxDate: undefined as string,
    setMaxDate: (() => {}) as Dispatch<SetStateAction<string>>,
    sortBy: sortByValues[0], 
    setSortBy: (() => {}) as Dispatch<SetStateAction<string>>,
    sortOrder: 1 as number, 
    setSortOrder: (() => {}) as Dispatch<SetStateAction<number>>,
})

function MaintenanceHistoryProvider({children}) {
    const [sortBy, setSortBy] = useLocalState('maintenances-sorty-by', sortByValues[0])
    const [sortOrder, setSortOrder] = useLocalState('maintenances-sorty-order', -1)

    const [minDate, setMinDate] = useLocalState('maintenances-history-min-date', today.add({months: -6}).toString())
    const [maxDate, setMaxDate] = useLocalState('maintenances-history-max-date', today.toString())

    const value = {sortBy, setSortBy, sortOrder, setSortOrder, minDate, setMinDate, maxDate, setMaxDate}

    return <MaintenanceFilterContext.Provider {...{value}}>
        {children}
    </MaintenanceFilterContext.Provider>
}

function useMaintenanceHistoryFilter() {
    return React.useContext(MaintenanceFilterContext)
}

function MaintenanceHistoryFilter() {
    const {t} = useTranslation()

    const {settings} = useDmSettings()
    const {minDate, setMinDate, maxDate, setMaxDate} = useMaintenanceHistoryFilter()

    return <DateRangePicker
        name="date range maintenances"
        className={clsx("text-sm py-1 px-2 bg-transparent w-fit")}
        title={t('instruction-due-within')}
        errorPosition="bottom-right"
        {...{ minDate, maxDate, setMinDate, setMaxDate, minValue: settings?.startDate ? parseDate(settings?.startDate) : undefined }} 
    />
}

function MaintenanceHistoryTable() {
    const {t} = useTranslation()
    const {
        minDate, maxDate,
        sortBy, setSortBy, sortOrder, setSortOrder,
    } = useMaintenanceHistoryFilter()

    const {firstPossibleFeeDueDate, latestPossibleFeeDueDate} = useTimeline()
    const {augmentMaintenanceAction} = useAugmentMaintenanceAction()

    const {data, fetchNextPage, hasNextPage, isLoading} = useMaintenances({
        minDate: firstPossibleFeeDueDate(minDate), maxDate: latestPossibleFeeDueDate(maxDate),
    })
    // Load the rest of the maintenance actions
    useEffect(() => {
        if (hasNextPage && !isLoading)
            fetchNextPage()
    }, [fetchNextPage, hasNextPage, isLoading])

    //console.log({data})

    const sortFunction = sortBy === 'due'
        ? (m: AugmentedMaintenanceAction) => m.instructionDueDate
        : sortBy === 'status'
        ? (m: AugmentedMaintenanceAction) => m.Phase + '-' + (m?.instruction ? `${m.instruction.instruction}-${m.instruction.created}` : '')
        : (m: AugmentedMaintenanceAction) => m.member.internalReference

    const maintenanceActions = _(data?.pages?.flatMap(d => d.Data?.Page ?? []) ?? [])
        .filter(Boolean)
        .map(augmentMaintenanceAction)
        .filter(m => m.member !== undefined)
        .sortBy(sortFunction)
        .tap(ms => sortOrder > 0 ? ms : _.reverse(ms))
        .value()

    const headers = [
        {key: 'patent', label: t('patent'),
            button: <SortButton {...{searchField: 'internalReference', sortField: sortBy, sortOrder, setSortField: setSortBy, setSortOrder}}/>},
        {key: 'due', label: t('instruction-due'),
            button: <SortButton {...{searchField: 'due', sortField: sortBy, sortOrder, setSortField: setSortBy, setSortOrder}}/>},
        {key: 'status', label: t('status'),
            button: <SortButton {...{searchField: 'status', sortField: sortBy, sortOrder, setSortField: setSortBy, setSortOrder}}/>},
    ]

    const activeMaintenanceActions = new Set(
        _(maintenanceActions)
            .groupBy(({ member }) => member.familyMemberId)
            .values()
            .flatMap(ms => _.values(eliminateDoubleMaintenanceActions(ms)).map(m => m.DennemeyerId))
            .value()
    )

    return (
        <div className="grid grid-cols-[auto_auto_auto] gap-x-4 gap-y-2">
            <div className="grid grid-cols-subgrid col-span-3 border-b-2 border-pcx-300">
                {headers.map((h, i) =>
                    h.key
                        ? <div key={h.key ?? i} className={clsx(
                            "py-2 xpx-3 text-sm text-pcx-600 font-semibold uppercase tracking-wider",
                            "first:pl-4 last:pr-4",
                        )}>{h.label} {h.button}</div>
                        : <div key={i} />
                )}
            </div>
            <div className="grid grid-cols-subgrid col-span-3 gap-y-1 py-3 px-2 bg-white rounded-xl shadow-lg border border-pcx-200">
                {isLoading  && <div className="col-span-3 text-xl text-pcx-500"><IconSpinner className="size-5 inline mr-2 animate-spin" />{t('loading')}...</div>}
                {maintenanceActions.map(maintenance => {
                    const { member, status, instruction } = maintenance
                    const deprecated = !activeMaintenanceActions.has(maintenance.DennemeyerId)
                    return (
                        <div className="grid grid-cols-subgrid col-span-3 px-2 py-0.5 hover:bg-pcx-200 rounded-md" key={maintenance.DennemeyerId} >
                            <div><Link className="underline-link" to={memberUrl(member)}>{maintenance.member.internalReference}</Link></div>
                            <div>{maintenance.instructionDueDate}</div>
                            <div className="self-center"><StatusView {...{ maintenanceAction: maintenance, status, instruction, deprecated }} /></div>
                        </div>
                    )})}
            </div>
        </div>
    )
}