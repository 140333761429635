import { Member } from "../patents/patents";
import { isEmptyDate, isEmptyString } from "../utils/strings";

/*
Required values for all
  application date 
  application number
  publication date
  publication number
  patent date
  patent number

  status == 'granted'

Required values for JP, KR, ID, PH and VN
  number of claims
*/
const countriesWithNumberOfClaims = new Set(['JP', 'KR', 'ID', 'PH', 'VN'])

const countriesNeedGrant = new Set(['AU', 'JP', 'KR', 'US', 'MX', 'MY'])

// translate with useTranslation
export function missingInformation(member: Member) {

  const grantNecessary = countriesNeedGrant.has(member.countryCode)

  const missinngInformation = [
    isEmptyDate(member.applicationDate) && 'applicationDate',
    isEmptyDate(member.publicationDate) && 'publicationDate',
    grantNecessary && isEmptyDate(member.patentDate) && 'patentDate',
    isEmptyString(member.applicationNumber) && 'applicationNumber',
    isEmptyString(member.publicationNumber) && 'publicationNumber',
    grantNecessary && isEmptyString(member.patentNumber) && 'patentNumber',
    grantNecessary && member.familyMemberStatus !== 'granted' && 'status-not-granted',
    !grantNecessary  && !(member.familyMemberStatus === 'granted' || member.familyMemberStatus === 'pending') && 'in-preparation-or-stopped',
    
    countriesWithNumberOfClaims.has(member.countryCode) && (member.numberClaims === undefined || member.numberClaims === 0) && 'numberClaims',
  ]

  return missinngInformation.filter(Boolean)
}

export function requiresGrant(member: Member) {
  return countriesNeedGrant.has(member.countryCode) && member.familyMemberStatus !== 'granted'
}