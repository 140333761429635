import { useEffect } from "react"
import { useTranslation } from 'react-i18next'

import { IconLoad } from "./icons"
import clsx from "clsx"


export function LoadingModal() {
    const { t } = useTranslation()
    return (
        <Modal><div className="p-3 flex flex-row gap-2">{t('loading')}... <IconLoad /></div></Modal>
    )
}

/**
 * Switch off overflow hidden if you have complex popovers in the modal. You might need to switch on corner clipping yourself (with rounded-lg)
 */
export interface ModalProps {
    children: React.ReactNode;
    blurClick?: () => void;
    escAction?: () => void;
    id?: string;
    overflowHidden?: boolean;
}

export default function Modal({
    children, 
    blurClick = () => {}, 
    escAction,
    id = "modal-overlay",
    overflowHidden = true,
}: ModalProps) {
    useEffect(() => {
        function handleEscapeKey(event) {
            if (event.code === 'Escape') {
                if (escAction === undefined)
                    blurClick()
                else
                    escAction()
            }
        }
        document.addEventListener(`keydown`, handleEscapeKey)
        return () => document.removeEventListener(`keydown`, handleEscapeKey)
    }, [blurClick, escAction])

    // adapted based on https://headlessui.com/react/dialog#scrollable-dialogs
    return (
        <div
            className="fixed inset-0 z-40"
            id={id}
            onClick={(e) => {
                if ((e.target as HTMLElement).id === id)
                    blurClick()
            }}
        >
            {/* Backdrop */}
            <div aria-hidden className="fixed inset-0 bg-black bg-opacity-30" />

            <div className="absolute inset-0 w-screen overflow-y-auto">
                <div className="flex min-h-full items-center justify-center p-4">
                    {/* The container of the modal */}
                    <div className={clsx("w-fit h-fit bg-white rounded-lg shadow-xl my-auto z-40", overflowHidden && "overflow-hidden")} >
                        <div className="min-h-fit overflow-visible">
                            {children}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}