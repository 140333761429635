import React, { Dispatch, SetStateAction, useEffect, useState } from "react"
import { useContext } from "react"
import { Navigate, useLocation, useNavigate } from "react-router"

import { 
    getTeams, 
    getTeamToken as getTeamToken_req, 
    login, 
    signup as signup_req, 
    signupTeam as signupTeam_req,
    signout, 
    management 
} from "../backend"
import { annuities_flag, claim_scope_flag, new_innovation_flag, product_country_flag, portfolio_update_flag, brand_flag, documents_flag, invention_flag, tasks_flag, excel_import_flag, excel_export_flag, costs_flag, tracking_flag, prio_sort_flag, annuities_admin_flag, euipo_import_flag, abandonment_date_flag, bulk_edit_flag, active_changes_flag, revenues_flag, } from "../data"
import { useMessages } from "../Messages"
import { useTeamFlags } from "../settings/TeamFlags"

function storeLocalUser(user: string) {
    window.localStorage.setItem('user', user)
}
function loadLocalUser(): string {
    return window.localStorage.getItem('user')
}
function removeLocalUser() {
    window.localStorage.removeItem('user')
}

function storeLocalTeam(team) {
    window.localStorage.setItem('team', team)
}

export function loadLocalTeam() {
    return window.localStorage.getItem('team')
}
function removeLocalTeam() {
    window.localStorage.removeItem('team')
}

const AuthContext = React.createContext({
    signin: (user: string, password: string) => Promise.resolve({}),
    signup: ({username, password, email}: {username: string, password: string, email: string}) => Promise.resolve({}),
    signout: () => Promise.resolve({}),
    //getRealms: () => Promise.resolve([]),
    getTeamToken: (team: string) => Promise.resolve({} as Response),
    signupTeam: ({teamName, displayName}: {teamName: string, displayName: string}) => Promise.resolve({}),
    user: {
        name: undefined,
        roles: [],
    },
    team: undefined as (string | undefined),
    teams: [],
    strike: 0,
    setStrike: ((s: number) => {}) as Dispatch<SetStateAction<number>>,
})

// TODO: split into UserProvider and TeamProvider
export function AuthProvider({children}) {

    const [isLoggedIn, setIsLoggedIn] = useState(false)

    const [user, setUser] = useState(loadLocalUser())
    const [team, setTeam] = useState(loadLocalTeam())
    const [teams, setTeams] = useState([])
    //console.log(realms)

    // Count strikes for unauthorized requests
    const [strike, setStrike] = useState(0)

    useEffect(() => {
      getTeams()// args passed in to make the linter happy
        .then(setTeams) 
    }, [user, team])
    

    // This is a bit annoying as the roles are per use but require per-team token for authroization
    const [roles, setRoles] = useState(undefined)
    //console.log({roles})
    useEffect(() => {
        if (roles === undefined && team) {
            management({username: user, operation: "get", type: "flag"})
                .then(flag => setRoles(flag.filter(f => f.username === user).map(f => f.flag)))
                .then(() => management({ userName: user, realm: team, operation: "get", type: "user-realm" }))
                .then(u => setRoles(rs => [...(rs ?? []), ...(u[0].userRoles ?? [])]))
                .then(() => setStrike(0))
                .catch(e => { 
                    if (e.message === "unauthorized") {
                        setStrike(s => s + 1)
                    }
                })
        }
    }, [roles, team, user, isLoggedIn])


    async function signin(user: string, password: string) {
        return login(user, password).then(res => {
            setUser(user)
            storeLocalUser(user)
            setIsLoggedIn(true)
            setStrike(0)
            return res
        })
    }

    function signup({ username, email, password }) {
        return signup_req({ username, email, password })
            .then(() => signin(username, password))
    }

    async function getTeamToken(team: string) {
        return getTeamToken_req(team).then(res => {
            setTeam(team)
            storeLocalTeam(team)
            setIsLoggedIn(true)
            setStrike(0)
            return res
        })
    }

    function signupTeam({teamName, displayName}) {
        return signupTeam_req({teamName, displayName})
            //.then(() => getTeamToken(teamName, true))
    }

    const value = {
        signin,
        signup,
        signupTeam,
        signout: () => {
            setUser(null)
            setTeam(null)
            setStrike(0)
            removeLocalUser()
            removeLocalTeam()
            setTeams([])
            setIsLoggedIn(false)
            setRoles(undefined)
            return signout()
        },
        getTeamToken,
        user: {
            name: user,
            roles: roles ?? [],
        },
        team,
        teams,
        strike,
        setStrike,
    }

    return (
        <AuthContext.Provider value={value}>
            {children}
        </AuthContext.Provider>
    )
}

export function useAuth() {
    return useContext(AuthContext)
}

export function useRoles() {
    const {user: {roles: userFlags}, team, setStrike} = useAuth()
    //console.log(roles)
    const {flags: teamFlags} = useTeamFlags(team, setStrike)

    const roles = [...userFlags, ...teamFlags]
    //console.log({team, teamFlags, roles})

    const isDev = roles.indexOf('dev') >= 0
    const isAdmin = roles.indexOf("admin-role") >= 0
    const isSuperAdmin = roles.indexOf("super-admin") >= 0
    const isEditUser = roles.indexOf("user-role") >= 0 || isAdmin

    const hasClaimScopes = roles.indexOf(claim_scope_flag) >= 0 || isDev
    const hasAnnuities = roles.indexOf(annuities_flag) >= 0 || isDev
    const isAnnuityAdmin = roles.indexOf(annuities_admin_flag) >= 0 || isDev
    const hasProductCountries = roles.indexOf(product_country_flag) >= 0 || isDev

    const hasRevenues = roles.indexOf(revenues_flag) >= 0 || isDev
    const hasCosts = roles.indexOf(costs_flag) >= 0 || isDev
    const hasTasks = roles.indexOf(tasks_flag) >= 0 || isDev
    const hasExcelImport = roles.indexOf(excel_import_flag) >= 0 || isDev
    const hasExcelExport = roles.indexOf(excel_export_flag) >= 0 || isDev
    const hasBulkEdit = roles.indexOf(bulk_edit_flag) >= 0 || isDev

    const hasInnovation = roles.indexOf(invention_flag) >= 0 || isDev
    const hasNewInnovations = roles.indexOf(new_innovation_flag) >= 0 || isDev
    const hasPortfolioUpdate = roles.indexOf(portfolio_update_flag) >= 0 || isDev
    const hasBrands = roles.indexOf(brand_flag) >= 0 || isDev
    const hasDocuments = roles.indexOf(documents_flag) >= 0 || isDev

    const hasTracking = roles.indexOf(tracking_flag) >= 0

    const hasEuipoImport = roles.indexOf(euipo_import_flag) >= 0 || isDev
    const hasActiveChangesChart = roles.indexOf(active_changes_flag) >= 0 || isDev

    const hasSortByPrio = roles.indexOf(prio_sort_flag) >= 0 || isDev

    const hasAbandonmentDate = roles.indexOf(abandonment_date_flag) >= 0 || isDev

    return { 
        isDev, isEditUser, isAdmin,
        isSuperAdmin, hasClaimScopes, 
        hasAnnuities, isAnnuityAdmin,
        hasProductCountries, 
        hasInnovation, hasNewInnovations,
        hasPortfolioUpdate, hasBulkEdit,
        hasRevenues, hasCosts, hasTasks, hasExcelImport, hasExcelExport,
        hasBrands,
        hasDocuments,
        hasSortByPrio, hasEuipoImport, hasAbandonmentDate,
        hasActiveChangesChart, hasTracking,
    }
}


const strikeLimit = 3
export function RequireAuth({autoSignOut = true, children}) {

    const {signout, user, team, strike} = useAuth()
    const location = useLocation()
    const navigate = useNavigate()
    const { setErrorMessage, setInfoMessage } = useMessages()
    
    // TODO: if more than 3? Strikes, explicitly check token and if then it is still invalid, sign out
    if (strike > 0) {
        console.warn(`Unauthorized request! Strike ${strike} of ${strikeLimit}.`)
    }
    const isNotValid = strike > strikeLimit

    if (isNotValid) {
        console.warn(`More than ${strikeLimit} strikes! Signing out...`)
    }

    if (autoSignOut && isNotValid && !location.pathname.startsWith("/teams")) {
        setInfoMessage(undefined)
        setErrorMessage(undefined)
        signout().then(() =>
            navigate("/login", {
                state: { from: location.pathname !== '/teams' && location },
                replace: true,
            }))
    }
    
    if (!user?.name || isNotValid || (!team && !location.pathname.startsWith("/teams"))) {
        return <Navigate to="/login" state={{ from: location.pathname !== '/teams' && location }} replace />
    } else
        return children
}

export function SignOut() {
    const {signout} = useAuth()
    const {setErrorMessage, setInfoMessage} = useMessages()
    const [signoutState, setSignoutState] = useState(1) // 1: not yet, 2: in the process, 3: done

    useEffect(() => {
        if (signoutState === 1) {
            setSignoutState(2)
            setErrorMessage(undefined)
            setInfoMessage(undefined)
            signout().then(() => setSignoutState(3))
        }
    }, [signoutState, signout, setErrorMessage, setInfoMessage])
    
    if (signoutState === 3)
        return <Navigate to="/login" />
    else   
        return <div>Signing out...</div>
}
