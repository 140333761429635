import { useTranslation } from "react-i18next"
import { useMember } from "../patents/FamilyMember"
import { Member } from "../patents/patents"
import { IpRight, PcIpRight, useDennemeyer, useDmSettings, useIpRight, useTimeline } from "./DennemeyerProvider"
import { isImported, maintenanceActionStatusToString, maintenanceActionsStatus, MaintenanceActionStatusInfo, renewalStatusExtraction, RenewalStatusInfo, renewalStatusToString } from "./modern_states"
import { paymentHandlingAction, renewalHandlingActionName } from "./import/ImportModal"
import { IconEdit, IconSpinner } from "../components/icons"
import { createSearchParams, Link } from "react-router-dom"
import clsx from "clsx"
import { eliminateDoubleMaintenanceActions } from "./utils"
import _ from "lodash"

export function ModernMemberOverview() {
    const {member} = useMember()
    // const {memberById} = usePatents()
    // const member = memberById[familyMemberId]
    const {ipRightByMemberId} = useDennemeyer()

    const familyMemberId = member?.familyMemberId
    const pcIpRight = ipRightByMemberId[familyMemberId]
    // console.log({pcIpRight})

    const {ipRight: ipRightResp, isLoading} = useIpRight(pcIpRight?.dennemeyerId)
    const ipRight = ipRightResp?.Data

    if (member === undefined || familyMemberId === undefined) return null

    const params: DmParams =  {
        familyMemberId: familyMemberId,
        ipRightId: pcIpRight?.ipRightId,
        dennemeyerId: pcIpRight?.dennemeyerId,
    }

    return <RenewalFields {...{ member, pcIpRight, ipRight, params, isLoading }} />
}

export type DmParams = {
    familyMemberId: number,
    ipRightId?: number,
    dennemeyerId?: string
}

export function toQueryParams(params: DmParams) {
    return createSearchParams(_.pickBy({
        familyMemberId: params?.familyMemberId?.toString(),
        ipRightId: params?.ipRightId?.toString(),
        dennemeyerId: params?.dennemeyerId?.toString(),
    }, v => v !== undefined)).toString()
}

const labelStyle = "sm:text-right text-gray-500 whitespace-nowrap"
const valueStyle = "max-sm:pl-4"

function RenewalFields({member, pcIpRight, ipRight, params, isLoading}: {params: DmParams, member: Member, pcIpRight: PcIpRight, ipRight: IpRight, isLoading: boolean}) {
    const {t} = useTranslation()

    const { validationsByIpRightId, instructionByDennemeyerId } = useDennemeyer()
    const { settings } = useDmSettings()
    const startDate = settings?.startDate
    const { calculateDueDates } = useTimeline()

    const renewalStatus = renewalStatusExtraction({ member, pcIpRight, ipRight, validationErrors: validationsByIpRightId[pcIpRight?.ipRightId] })
    const validMaintenanceActions = _(ipRight?.MaintenanceActions ?? [])
        .filter(ma => {
            if (startDate === undefined) return true
            const {instructionDueDate} =  calculateDueDates(ma.DueDate) 
            return instructionDueDate >= startDate
        })
        .value()
    const maintenanceActions = _.values(eliminateDoubleMaintenanceActions(validMaintenanceActions))
    // console.log({ipRight, maintenanceActions, validMaintenanceActions})
    const maintenanceActionStatus = maintenanceActionsStatus({renewalStatus: renewalStatus.status, maintenanceActions, calculateDueDates, instructionByDennemeyerId})

    return <>
        <div className={labelStyle} onClick={() => console.log({ipRight, pcIpRight, renewalStatus, maintenanceActionStatus})}>{t('renewals-status')}</div>
        <div className={valueStyle}><RenewalStatusValue {...{params, renewalStatus, isLoading, maintenanceActionStatus}} /></div>

        {ipRight !== undefined && <>
            <div className={labelStyle}>{t('history')}</div>
            <div className={valueStyle}><HistoryLink {...{maintenanceActionStatus, params}} /></div>
        </>}
    </>
}


// if not handled, then
//  - if revivable: show 'revive' button
//  - if newly importable: show 'initiate' button

// if active: show 'edit' button
// if active: 
//  - if has future MA: show upcomming renewal fee as link to Instruct
//  - if has past MA: show last renewal fee
// 
function RenewalStatusValue(
    {maintenanceActionStatus, params, renewalStatus, isLoading}:
    {maintenanceActionStatus: MaintenanceActionStatusInfo, params: DmParams, renewalStatus: RenewalStatusInfo, isLoading?: boolean}) {
    const {t} = useTranslation()
    // if not handled: not-handled (reason)
    const isNotHandled = renewalStatus.status === 'not-handled' || renewalStatus.substatus === 'back-office-processing'

    const imported = isImported(renewalStatus)
    const action = paymentHandlingAction({status: renewalStatus, imported})

    const isEdit = action === 'edit'
    //console.log({renewalStatus, imported})

    return (
        <div className="flex flex-row w-full gap-2 justify-between">
            {(isNotHandled || maintenanceActionStatus.dennemeyerId === undefined)
                ? <div>{renewalStatusToString(renewalStatus, t)} {isLoading && <IconSpinner className="h-3 w-3 mb-1 inline animate-spin text-pcx-600" />}</div>
                : <Link className="underline-link" to={`instruct/${maintenanceActionStatus.dennemeyerId}`}>
                    {maintenanceActionStatusToString(maintenanceActionStatus, t)}
                </Link>}

            <Link title={`${t('renewals')}: ${action}`}
                to={{ pathname: "renewals", search: toQueryParams(params) }}
                className={clsx("btn-secondary text-sm self-start whitespace-nowrap", isEdit ? "p-px" : "py-px")}
            >
                {isEdit
                    ? <IconEdit className="size-4" />
                    : t(renewalHandlingActionName(action))}
            </Link>
        </div>
    )
}

function HistoryLink(
    { maintenanceActionStatus, params }:
    { maintenanceActionStatus: MaintenanceActionStatusInfo & { total: number }, params: DmParams, }
) {
    const {t} = useTranslation()

    if (maintenanceActionStatus.status === 'ready') {
        return <span>{t('no-history')}</span>
    }

    return (
        <Link
            to={{ pathname: "renewals/history", search: toQueryParams(params) }}
            className="underline-link pl-0 sm:pl-0"
        >
            {t('ma.instructions', { count: maintenanceActionStatus.total })}
        </Link>
    )
}