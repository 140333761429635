import kanji2roman from '../utils/kanji'
import { capitalize } from '../utils/strings'

export default function KanjiTip({kanjiOpt, children}: {kanjiOpt?: string, children?: JSX.Element}) {
    const trans = kanjiOpt && kanji2roman(kanjiOpt)
    //console.log({kanjiOpt, trans, children})
    if (trans)
        return <span title={capitalize(trans)}>{children}</span>
    else 
        return children || null
}