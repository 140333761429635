import React, { useContext, useState } from "react";
import { useParams } from "react-router";
import _ from 'lodash'

import { DateOrString } from "../utils/dates";
import { usePatents } from "../patents/PatentsProvider";
import { useClaims } from "./ClaimsProvider";

export interface Claim {
    claimId?: number;
    claimText: string;
    claimNumber: number;
    claimType: ClaimType;
    familyMemberId: number;
    claimScopeId?: number;
    version: string;
    versionDate: string;
}

type ClaimType = 'independent-claim' | 'dependent-claim'

const ClaimsContext = React.createContext({
    familyMemberId: -1 as number | undefined,
    patentFamilyId: -1 as number | undefined,
    claimVersion: {version: '', versionDate: ''},
    availableVersions: [] as {version: string; versionDate: DateOrString}[],
    setClaimVersion: ({version, versionDate}: {version: string; versionDate: DateOrString}) => {},
    claims: [] as Claim[],
    memberClaims: [] as Claim[],
    familyClaims: [] as Claim[],
    currentClaims: [] as Claim[],
    addClaim: (claim: Claim) => Promise.resolve({}),
    deleteClaim: (claim: Claim) => Promise.resolve({}),
    updateClaim: (claim: Claim) => {console.log('sss'); return Promise.resolve({})},
})

export function useMemberClaims() {
    return useContext(ClaimsContext)
}

export function MemberClaimsProvider({children}) {
    const { internalReference } = useParams()
    const { memberByReference } = usePatents()
    const {aggregatedClaims, claims, postClaim, deleteClaim} = useClaims()
    const member = memberByReference[internalReference ?? '']
    const familyMemberId = member?.familyMemberId
    const patentFamilyId = member?.patentFamilyId

    const familyClaims = _(aggregatedClaims[patentFamilyId]?.claimsByMemberId ?? []).flatMap(claimVersions => claimVersions.flatMap(v => v.claims)).value()

    const _memberClaims = aggregatedClaims[patentFamilyId]?.claimsByMemberId[familyMemberId] ?? []
    const memberClaims = _memberClaims.flatMap(v => v.claims)

    const availableVersions = _(_memberClaims).map(v => ({version: v.version, versionDate: v.versionDate})).reverse().value()

    const [_claimVersion, setClaimVersion] = useState(undefined)
    const fallBackVersion = availableVersions[0] ?? {version: '1', versionDate: new Date().toISOString().substring(0, 10)}
    const claimVersion = _claimVersion ?? fallBackVersion
    const currentClaims = _(memberClaims).filter(claim => claim.version === claimVersion.version).sortBy('claimNumber').value()
    //console.log({_claimVersion, claimVersion})

    const addClaim = async ({claimNumber, claimType, claimText}: Claim) => {
        return postClaim({...claimVersion, familyMemberId, claimNumber, claimType, claimText})
    }
    const updateClaim = async ({claimId, claimNumber, claimType, claimText, claimScopeId}: Claim) => {
        //console.log('updating...')
        return postClaim({...claimVersion, familyMemberId, claimId, claimNumber, claimType, claimText, claimScopeId})
    }

    const value = {
        familyMemberId,
        patentFamilyId,
        availableVersions,
        claimVersion,
        setClaimVersion,
        claims,
        memberClaims,
        familyClaims,
        currentClaims,
        addClaim,
        deleteClaim,
        updateClaim,
    }
    return <ClaimsContext.Provider value={value}>
        {children}
    </ClaimsContext.Provider>
}
