import React, { useContext, useEffect, useState } from "react"
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import _ from 'lodash'

import { management } from './backend'
import { ErrorMessage, useMessages } from "./Messages"
import { useAuth } from "./user/Auth"

export interface TeamSettings {
    realm: string;
    displayName?: string;
    currency: string;
}

export interface Team {
    name: string;
    realmId?: number;
    active?: boolean;
    displayName?: string;
}

export function useTeamSettings(realm: string) {
    const {setStrike} = useAuth()
    const {setErrorMessage} = useMessages()

    const queryClient = useQueryClient()

    const {data: team, isError: isSettingsError, error: settingsError} = useQuery<TeamSettings, ErrorMessage>({
        queryKey: ['realm-settings', realm], 
        queryFn: () => management({realm, type: "realm-settings", operation: "get"}),
    })

    if (isSettingsError)
        setErrorMessage(settingsError.message)

    const { data: _realm, isError: isRealmError, error: realmError } = useQuery<Team, ErrorMessage>({
        queryKey: ['realm', realm],
        queryFn: () => management({ name: realm, type: "realm", operation: "get" }).catch(err => {
            if (err.status === 'unauthorized')
                setStrike(s => s + 1)
        }),
    })

    const realmId = _realm?.realmId

    if (isRealmError)
        setErrorMessage(realmError.message)

    const mutation = useMutation<Omit<TeamSettings, 'realm'>, ErrorMessage, Omit<TeamSettings, 'realm'>>({
        mutationFn: (team: TeamSettings) => management({...team, realm, type: "realm-settings", operation: "update"}),
        onSuccess: (data) => queryClient.setQueryData(['realm-settings', realm], data),
        onError: (error) => setErrorMessage(error.message),
    })

    return {team, realmId, updateTeam: mutation.mutateAsync}
}

export function useSingleTeamMangement() {
    const {team: realm} = useAuth()

    return  useTeamSettings(realm)
}

export interface SignupProps {
    user: string;
    password: string;
    email: string;
    userRoles: string[];
}

const TeamManagementContext = React.createContext({
    users: [],
    addLogin: ({user = '', password = ''}) => Promise.resolve({}),
    createUser: ({user, password, email, userRoles}: SignupProps) => Promise.resolve({}),
    updateUser: (user: UserRealmProps) => Promise.resolve(user),
    removeUser: (user: string) => Promise.resolve({}),
    addUser: (user: UserRealmProps) => Promise.resolve(user),
    hasLoaded: false,
})

export interface UserRealmProps {
    userName?: string;
    userRoles?: string[],
}

export function TeamManagementProvider({team, triggerReload = () => {}, children}) {
    const {setErrorMessage} = useMessages()

    const [hasLoaded, setHasLoaded] = useState(false)
    const [users, setUsers] = useState([])

    const type = "user-realm"

    useEffect(() => {
        if (!hasLoaded) {
            setHasLoaded(true)
            triggerReload()
            management({ realm: team, operation: "get", type })
                .then(users => setUsers(_.sortBy(users, u => u.userName)))
                .then(() => setHasLoaded(true))
                .catch(err => setErrorMessage(err.message))
        }
    }, [hasLoaded, team, setErrorMessage, triggerReload])

    useEffect(() => {
      setHasLoaded(false)
    }, [team])

    async function handle(op: Promise<any>) {
        return op
            .then(res => {setHasLoaded(false); return res})
            .catch((err: ErrorMessage) => setErrorMessage(err.message))
    }

    function updateUser(user: UserRealmProps) {
        return handle(management({...user, realm: team, operation: "update", type}))
    }

    function removeUser(userName: string) {
        return handle(management({userName, realm: team, operation: "delete", type}))
    }

    function addUser(user: UserRealmProps) {
        return handle(management({...user, realm: team, operation: "add", type}))
    }

    function addLogin({user, password}) {
        return handle(management({user, password: btoa(password), operation: "add", type: "login"}))
    }

    function createUser({user, password, email, userRoles}) {
        return handle(management({user, password: btoa(password), team, email, userRoles, operation: "add", type: "create"}))
    }
    
    return <TeamManagementContext.Provider value={{users, updateUser, removeUser, addUser, addLogin, createUser, hasLoaded}}>
        {children}
    </TeamManagementContext.Provider>
}

export function useTeamManagementContext() {
    return useContext(TeamManagementContext)
}

const AdminManagmentContext = React.createContext({
    users: [] as string[],
    teams: [] as Team[],
    addTeam: (team: Team) => Promise.resolve(team as Team | void),
    updateTeam: (team: Team) => Promise.resolve(team as Team | void),
    triggerReload: () => {},
}) 

export function AdminManagmentProvider({children}) {
    const { setErrorMessage } = useMessages()

    const [hasLoaded, setHasLoaded] = useState(false)
    //console.log(hasLoaded)

    const [users, setUsers] = useState([])
    const [teams, setTeams] = useState([])

    //console.log({users, teams})

    useEffect(() => {
        if (!hasLoaded) {
            setHasLoaded(true)
            management({type: "realm", operation: "get"}).then(setTeams)
                .then(() => 
                    management({type: "login", operation: "get"}).then(us => setUsers(_.sortBy(us, u => u.toLowerCase()))))
                .then(() => setHasLoaded(true))
                .catch(err => setErrorMessage(err.message))
        }
    }, [hasLoaded, setErrorMessage])

    async function addTeam(team: Team) {
        return management({ type: "realm", operation: "add", ...team })
            .then(() => {setHasLoaded(false); return team})
            .catch(err => setErrorMessage(err.message))
    }

    async function updateTeam(team: Team) {
        return management({ type: "realm", operation: "update", ...team })
            .then(() => { setHasLoaded(false); return team })
            .catch(err => setErrorMessage(err.message))
    }
    
    const triggerReload = () => Promise.resolve(setHasLoaded(false))

    return (
        <AdminManagmentContext.Provider value={{users, teams, addTeam, updateTeam, triggerReload}}>
            {children}
        </AdminManagmentContext.Provider>
    )
}

export function useAdminManagementContext() {
    return useContext(AdminManagmentContext)
}


export function useUserTeams(userName: string) {
    const { setErrorMessage } = useMessages()
    const queryClient = useQueryClient()

    const queryKey = ['user-realms', userName]

    const {data: teams} = useQuery({
        queryKey,
        queryFn: () => management({userName, type: "user-realms", operation: "get"})
    })

    const removeUserFromTeam = ({userName, team}) => 
        management({userName, realm: team, operation: "delete", type: "user-realm"})
            .catch(err => setErrorMessage(err.message))
            .finally(() => queryClient.invalidateQueries({queryKey}))

    return {teams, removeUserFromTeam}
}