import React, {useState} from 'react'
import { useField } from 'formik'
import { XMarkIcon } from '@heroicons/react/20/solid'
import { useDrag, useDrop } from 'react-dnd'
import _ from 'lodash'

import { IconPlus } from './icons'
import clsx from 'clsx'
import { dragIndexTo } from '../collection'

// Used as drop target
// tag: the tag of the ribbon within
function TagRibbonDropZone({ children, target, setTags, tags, type }) {
    // For more info about the drag-n-drop library
    // https://react-dnd.github.io/react-dnd/docs/tutorial
    const [{ isOver, canDrop }, drop] = useDrop(() => ({
        accept: type,
        drop: (item: {source: number}) => setTags(dragIndexTo(tags, item.source, target)),
        canDrop: (item: {source: number}) => item.source !== target && (target - item.source) !== 1,
        collect: monitor => ({
            isOver: !!monitor.isOver(),
            canDrop: !!monitor.canDrop(),
        }),
    }), [target, tags])

    return <div className='relative' ref={drop}>
        {isOver && canDrop && <div className='bg-pcx-700 h-6 w-1 rounded-sm absolute -left-1'></div>}
        {children}
    </div>
}

function DragableRibbon({ primary, tag, tagDisplays, disabled, setTags, tags, type }) {
    const source = tags.indexOf(tag)
    const [{isDragging}, drag] = useDrag(() => ({
        type,
        item: {tag, source},
        collect: monitor => ({
            isDragging: !!monitor.isDragging(),
        }),
    }), [tag, source])
    return <div className={clsx(isDragging && 'opacity-50', 'cursor-pointer')} ref={drag}>
        <TagRibbon {...{primary, tag, tagDisplays, disabled, setTags, tags}} />
    </div>
}

function TagRibbon({ primary, tag, tagDisplays, disabled, setTags, tags }) {
    return (
        <div 
            className={clsx(
                primary 
                    ? "bg-pcx-500 hover:bg-pcx-400 text-pc-100" 
                    : "bg-pcx-200/50 hover:bg-pcx-100",
                "hover:shadow-md",
                " px-1 pl-2 h-6 rounded-sm whitespace-nowrap w-fit text-sm select-none",
                "flex flex-row gap-1 items-center "
            )} 
        >
            <div className="pr-1">
                {tagDisplays[tag] ?? tag}
            </div>
            {!disabled && <div
                className="cursor-pointer"
                onClick={() => setTags(tags.filter(t => t !== tag))}
            >
                <XMarkIcon className={clsx("w-5 h-5", primary ? "hover:text-pcx-900" : "hover:text-pcx-700")} />
            </div>}
        </div>
    )
}

export interface TagListProps {
    name?: string;
    dragable?: boolean;
    tags?: string[];
    setTags: (tags: string[]) => void;
    availableTags: string[] | Record<string, string[]>;
    maxNumber?: number;
    primary?: boolean;
    placeholder?: string;
    tagDisplays?: Record<string, string>;
    allowNew?: boolean;
    disabled?: boolean;
    addButton?: React.ReactNode;
}


export default function TagList({
    name = undefined, 
    dragable = false,
    tags = [], 
    setTags, 
    availableTags, 
    maxNumber, 
    primary = false, 
    placeholder = "Tag", 
    tagDisplays = {}, 
    allowNew = false,
    disabled = false,
    addButton = undefined,
}: TagListProps) {

    // is name is not set, you can also not drag
    const dragableType = dragable ? name : undefined


    //console.log(availableTags)
    //console.log(tags)
    // eslint-disable-next-line
    const tagComperator = (a, b) => a == b
    const notSetTags = Array.isArray(availableTags) 
        ? _.differenceWith(availableTags, tags, tagComperator)
        : _(availableTags).toPairs()
            .map(([group, ts]) => [group, _.differenceWith(ts, tags, tagComperator)])
            .filter(([group, ts]) => ts.length > 0)
            .fromPairs().value()

    //console.log(notSetTags)
    //console.log(availableTags)

    function asOption(tag) {
        return <option key={tag} value={tag} label={tagDisplays[tag] ?? tag} />
    }

    function AddTag() {
        const [newTag, setNewTag] = useState("")
        if (!allowNew) {
            if (_.size(notSetTags) === 0) return null
            return (
                <div className={clsx(
                    "align-bottom flex flex-row items-center",
                    "text-slate-500",
                    primary ? "bg-pcx-100" : "bg-white shadow-sm"
                )}>
                    <select
                        className="form-select border-0 hover:shadow-md focus:shadow-sm focus text-sm focus:outline-none bg-transparent w-32 py-px pr-3 pl-2"
                        onChange={(e) => setTags([...tags, e.target.value])}
                        name={name}
                    >
                        <option>{placeholder ?? "Select"}</option>
                        {Array.isArray(notSetTags)
                            ? notSetTags.map(asOption)
                            : Object.entries(notSetTags).map(([group, gts]) => 
                                <optgroup key={group} label={group}>{gts.map(asOption)}</optgroup>
                            ) 
                        }
                    </select>
                </div>
            )
        } else {
            const remaining = notSetTags.filter(t => t.toLowerCase().includes(newTag.toLowerCase()))
            const submit = (t: string) => {
                if (t !== "") {
                    const existing = remaining.find(r => r.toLowerCase() === t.toLowerCase())
                    if (existing) {
                        setTags([...tags, existing])
                    } else {
                        setTags([...tags, t])
                    }
                    setNewTag("")
                }
            }
            return (
                <div className={clsx(
                    primary ? "bg-pcx-300/20" : "bg-white border border-gray-300",
                    "align-bottom flex flex-row items-center pl-2 relative group"
                )}>
                    <input
                        className={`text-sm focus:outline-none rounded-sm bg-transparent w-16 h-5`}
                        placeholder={placeholder}
                        type="text"
                        value={newTag}
                        onChange={(e) => setNewTag(e.target.value)}
                        name={name}
                        list={name}
                        onKeyDown={(e) => {
                            if (e.code === 'Enter') {
                                submit(newTag)
                            }
                        }}
                    />
                    {remaining.length > 0 &&
                        <div role="listbox" className="absolute min-w-full top-6 left-0 border border-pc-300 bg-pc-100 hidden group-focus-within:flex peer-focus:flex focus:flex hover:flex flex-col">
                            {remaining.map(r =>
                                <div 
                                    className="text-left text-sm px-1 hover:bg-pc-200 focus:bg-pc-200 focus:outline-none cursor-pointer whitespace-nowrap" 
                                    // @ts-ignore
                                    tabIndex="0"
                                    role="option"
                                    aria-selected="false"
                                    key={r} 
                                    onClick={() => submit(r)} 
                                    onKeyDown={e => e.code === 'Enter' && submit(r)}
                                >
                                    {tagDisplays[r] ?? r}
                                </div>)}
                        </div>}
                    <button
                        type="submit"
                        className="text-black w-5 h-5"
                        disabled={newTag === ""}
                        onClick={() => submit(newTag)}
                    >
                        <IconPlus />
                    </button>
                </div>
            )
        }
    }

    return (
        <div className="flex flex-wrap gap-1">
            {tags.map((tag, index) =>
                dragableType 
                    ? <TagRibbonDropZone key={tag} {...{ tags, target: index, setTags, type: dragableType }}>
                        <DragableRibbon {...{ tag, primary, tagDisplays, disabled, setTags, tags, type: dragableType }} />
                    </TagRibbonDropZone>
                    : <TagRibbon key={tag} {...{ tag, primary, tagDisplays, disabled, setTags, tags }} />
            )}
            {(!disabled && (maxNumber === undefined || tags.length < maxNumber) && (_.size(notSetTags) > 0 || allowNew)) &&
                dragableType
                ? <TagRibbonDropZone {...{ tags, setTags, target: tags.length, type: dragableType }}>
                    <AddTag />
                </TagRibbonDropZone>
                : <AddTag />}
            {addButton}
        </div>
    )
}


export function TagListField(props) {
    // eslint-disable-next-line
    const [field, meta, helpers] = useField(props);
    return <TagList id={props.name} {...{...props, tags: props.tags ?? meta.value, setTags: helpers.setValue}} />
}