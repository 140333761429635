import React, { useState, useEffect, useContext } from "react"
import { useNavigate, useParams } from "react-router"
import { Link } from "react-router-dom"
import _ from 'lodash'

import { deleteBill as _deleteBill, getAllBills, getAllInformations, getAllPlans, postBill as _postBill, postBillingPlan, postBillingInformation } from "../../backend"
import Modal from "../../components/Modal"
import { IconEdit, IconX } from "../../components/icons"
import { useAdminManagementContext } from "../../Management"
import { Outlet } from "react-router"
import { BillingInformationForm, Invoice } from "./Billing"
import { Field, Form, Formik } from "formik"
import { DatePicker } from "../../components/input/DatePicker"
import { update } from "../../collection"
import { plusPeriod } from "../../utils/dates"
import { initialBillingInformation } from "./BillsProvider"

const AdminBillingContext = React.createContext({
    bills: [],
    information: [],
    plans: [],
    prices: [],
    hasLoaded: false,
    postBill: () => Promise.resolve({}),
    deleteBill: () => Promise.resolve({}),
    addPlan: () => Promise.resolve({})
})

export function AdminBillingProvider({children}) {

    const [bills, setBills] = useState([])
    const [information, setInformation] = useState([])
    const [plans, setPlans] = useState([])
    const [prices, setPrices] = useState([])

    function postBill(bill) {
        return _postBill(bill).then(res => 
            bill?.billId 
                ? setBills(update(bills, bill, b => b.billId))  
                : setBills(bs => [...bs, res]))
    }

    function deleteBill(team, billId) {
        return _deleteBill(team, billId).then(() => setBills(bs => bs.filter(b => b.billId !== billId)))
    }

    function addPlan(plan) {
        return postBillingPlan(plan).then(res => setPlans(ps => [...ps, res]))
    }

    function updateBillingInformation(info) {
        postBillingInformation(info)
        setInformation(infos => _.uniqBy([...infos, info], i => i.realm))
    }


    const [hasLoaded, setHasLoaded] = useState(false)

    useEffect(() => {
      if (!hasLoaded) {
        getAllBills().then(setBills)
            .then(() => getAllPlans().then(setPlans))
            .then(() => getAllInformations().then(setInformation))
            // TODO: get all prices
            .then(() => setPrices([]))
            .then(() => setHasLoaded(true))
      }
    }, [hasLoaded])

    return (
        <AdminBillingContext.Provider value={{bills, information, plans, prices, hasLoaded, postBill, deleteBill, addPlan, updateBillingInformation}}>
            {children}
        </AdminBillingContext.Provider>

    )
}

function useAdminBillingContext() {
    return useContext(AdminBillingContext)
}

export default function AdminBilling() {
    const {teams} = useAdminManagementContext()
    const {bills, information, plans, prices} = useAdminBillingContext()

    const billsByTeam = _.groupBy(bills, 'realm')
    const informationByTeam = _.keyBy(information, 'realm')
    const plansByTeam = _.groupBy(plans, 'realm')
    const pricesByTeam = _.groupBy(prices, 'realm')
    //console.log(information)
    
    return (
        <div className="">
            <h3>Billing</h3>
            <div className="flex flex-row gap-10 pt-2">
                <div className="flex flex-col gap-2 max-h-[75vh] overflow-y-auto">
                    {_(teams)
                        .filter(t => t.active)
                        .sortBy(t => (t.displayName ?? t.name).toLowerCase())
                        .map(t => <TeamPlan key={t.name} {...{
                            ...t,
                            plans: plansByTeam[t.name] ?? [],
                            information: informationByTeam[t.name],
                            prices: pricesByTeam[t.name] ?? pricesByTeam[''] ?? [],
                            bills: billsByTeam[t.name] ?? []
                        }} />)
                        .value()
                    }
                </div>
                <Outlet />
            </div>
        </div>
    )
}

function TeamPlan({plans, bills, information, prices, displayName, name, created}) {
    // TODO: add indication for unpaid/overdue bills, special prices etc.
    const informationLacking = information === undefined
    const lastPlan = _(plans).sortBy(p => p.created).last()
    return (
        <Link to={encodeURIComponent(name)} className="p-3 border border-pc-250 bg-pc-100/50 rounded-md">
            <div className="flex flex-row justify-between">
                <h4>{displayName ?? name}</h4>
                {created && <div className="text-sm text-slate-500">Since {created}</div>}
            </div>
            {
                informationLacking 
                    ? <div>No Information Present!</div>
                    : <div className="flex flex-row gap-10 items-end">
                        <div className="w-48">
                            {information.companyName} <br />
                            {information.address1} <br />
                            {information.address2 && <>{information.address2} <br /></>}
                            {information.zip} {information.city} {information.countryCode}
                        </div>
                        <div>
                            {lastPlan 
                                ?  <>
                                        <span className="text-2xl">{lastPlan.price}</span> <span className="text-slate-500">{lastPlan.currency}</span>
                                        <br/>
                                        <span className="text-slate-500">Every {lastPlan.frequency === "1M" ? "month" : "year"}</span>
                                    </>
                                : "No Plan Defined"
                            }
                        </div>
                    </div>
            }
        </Link>
    )
}

export function AllBills() {
    const {bills} = useAdminBillingContext()
    return (
        <div className="flex flex-col gap-2">
            <h3>Unpaid / Overdue Bills</h3>
            {_(bills).filter(b => !b.paid).sortBy(b => b.due).map(b =>
                <Invoice key={b.billId} {...b} />
            ).value()}
        </div>
    )
}

export function AdminTeamDetails() {
    const { team: teamName } = useParams()
    const { teams } = useAdminManagementContext()
    const team = teams.find(t => t.name === teamName)

    const [showInformationForm, setShowInformationForm] = useState(false)

    const {bills: _bills, plans: _plans, information, updateBillingInformation} = useAdminBillingContext()
    const plans = _(_plans).filter(x => x.realm === teamName).sortBy('created').reverse().value()
    const bills = _bills.filter(x => x.realm === teamName)
    const teamInformation = information.find(i => i.realm === teamName)

    return (
        <div className="border border-pc-300 rounded-md p-2 px-4">
            <div className="flex flex-row gap-2 items-center">
                <h4 className="grow">Billing Details {team?.displayName ?? team?.name ?? ''}</h4>
                <button onClick={() => setShowInformationForm(!showInformationForm)} className="w-6 h-6"><IconEdit /></button>
                <Link to=".."><IconX /></Link>
            </div>
            {(teamInformation === undefined || showInformationForm) &&
                <BillingInformationForm {...{
                    billingInformation: teamInformation ?? initialBillingInformation,
                    updateBillingInformation: info => updateBillingInformation({...info, realm: teamName})
                }} />
            }
            <div className="flex flex-row gap-8 min-w-sm pt-3">
                <div>
                    <h5>Plans</h5>
                    <div className="flex flex-col gap-2 pt-2 w-64">
                        <Link to="add-plan" className="btn-secondary bg-transparent p-2  border border-pc-250">Add Plan</Link>
                        {plans.map((p, pi) => <BillingPlan key={pi} {...p} />)}
                    </div>
                </div>
                <div>
                    <h5>Invoices</h5>
                    <div className="flex flex-col gap-2 pt-2 min-w-xs">
                        <Outlet />
                        {bills.map(b => 
                            <Link key={b.reference} to={'' + b.billId}>
                                <Invoice {...b} />
                            </Link>
                        )}
                    </div>
                </div>
            </div>
        </div>
    )
}

export function AddBillingPlan() {
    const { team: realm } = useParams()
    const {addPlan} = useAdminBillingContext()

    const navigate = useNavigate()
    return (
        <Modal escAction={() => navigate("..")}>
            <Formik
                initialValues={{
                    frequency: "1Y",
                    currency: "CHF",
                }}
                onSubmit={(plan) => addPlan({...plan, realm}).then(() => navigate("..")) }
            >
                <Form>
                    <div className="flex flex-col gap-4 min-w-xs p-4">
                        <h4>Add Billing Plan</h4>
                        <Field name="frequency" className="form-select" as="select">
                            <option value="1Y">Billed Yearly</option>
                            <option value="1M">Billed Monthly</option>
                        </Field>
                        <Field name="currency" className="form-select" as="select">
                            <option value="CHF">CHF</option>
                            <option value="EUR">EUR</option>
                        </Field>
                    </div>
                    <div className="flex flex-row-reverse gap-4 p-4">
                        <input
                            type="submit"
                            value="Add"
                            className="btn-primary disabled:btn-disabled"
                        />
                        <Link to=".." className="btn-secondary">Cancel</Link>
                    </div>
                </Form>
            </Formik>
        </Modal>
    )
}

export function AddInvoiceButton() {
    return (
        <Link
            to="add"
            className="btn-secondary bg-transparent p-2  border border-pc-250"
        >Add Invoice</Link>
    )
}

function BillingPlan({price, currency, users, frequency, created}) {
    return (
        <div className="bg-pc-100/50 p-2 rounded-sm border border-pc-250 text-right">
            <span className="text-slate-500">
                <span className="text-2xl text-slate-900">{users}</span> users for <span className="text-2xl text-slate-900">{price}</span> {currency}
            </span> 
            <br />
            <span className="text-slate-700">Every {frequency === "1M" ? "month" : "year"}</span> <br/>
            <span className="text-slate-500 text-sm">{created.slice(0, 19).replace('T', ' ')}</span> 

        </div>
    )
}

export function InvoiceEdit() {
    const {postBill, deleteBill, bills, hasLoaded, plans} = useAdminBillingContext()

    const { billId: _billId, team } = useParams()
    const billId = parseInt(_billId)
    const navigate = useNavigate()
    //console.log(_billId, billId, team)

    const [wantToDelete, setWantToDelete] = useState(false)

    const lastPlan = _(plans).filter(p => p.realm === team).sortBy(p => p.created).last()
    //console.log(lastPlan)

    const calcEndDate = () => {
        if (lastPlan?.created && lastPlan?.frequency) {
            const period = lastPlan.frequency === "1Y" ? {years: 1} : {months: 1}
            return plusPeriod(lastPlan?.created, period)
        } else
            return ''

    }

    const originalBill = bills.find(b => b.billId === billId)
    const isAdding = originalBill === undefined
    const initialValues = isAdding
        ? { 
            reference: '', 
            amount: lastPlan?.price ?? 0.0, 
            currency: lastPlan?.currency ?? 'CHF', 
            start: lastPlan?.created ? lastPlan.created.substring(0, 10) : '', 
            end: calcEndDate(), 
            due: lastPlan?.created ? plusPeriod(lastPlan.created, {months: 1}) : '', 
            paid: false 
        }
        : originalBill

    return (
            <div className="bg-white rounded-sm border border-pc-250">
                {hasLoaded &&
                    <Formik
                        initialValues={initialValues}
                        onSubmit={(invoice) => {
                            postBill({ ...invoice, realm: team }).then(() => navigate(".."))
                        }}
                    >
                        <Form>
                            <div className="flex flex-col p-4">
                                <h5>{isAdding ? "Add Invoice" : `Edit ${initialValues.reference}`}</h5>
                                <label>
                                    <div className="label">Reference</div>
                                    <Field name="reference" className="form-input" required autoFocus />
                                </label>
                                <label htmlFor="amount">
                                    <div className="label">Amount</div>
                                </label>
                                <div className="w-full flex flex-row gap-2">
                                    <Field id="amount" name="amount" type="number" className="form-input w-40" required />
                                    <Field name="currency" as="select" className="form-select" >
                                        <option value="CHF">CHF</option>
                                        <option value="EUR">EUR</option>
                                    </Field>
                                </div>
                                <label className="inline-flex items-center gap-2 pt-4 w-fit">
                                    <div className="text-slate-500 w-12">From:</div>
                                    <Field name="start" className="form-input" as={DatePicker} required />
                                </label>
                                <label className="inline-flex items-center gap-2 pt-4 w-fit">
                                    <div className="text-slate-500 w-12">To:</div>
                                    <Field name="end" className="form-input" as={DatePicker} required />
                                </label>
                                <label className="inline-flex items-center gap-2 pt-4 w-fit">
                                    <div className="text-slate-500 w-12">Due:</div>
                                    <Field name="due" className="form-input" as={DatePicker} required />
                                </label>
                                <label className="inline-flex items-center gap-2 pt-4 pr-4 w-fit">
                                    <div className="text-slate-500 w-12">Paid</div>
                                    <Field name="paid" type="checkbox" className="form-checkbox" />
                                </label>
                            </div>
                            <div className="bg-pc-200 p-4 flex flex-row-reverse gap-2">
                                <input type="submit" value={isAdding ? "Add" : "Update"} className="btn-primary" />
                                <Link className="btn-secondary" to="..">Cancel</Link>
                                <div className="grow" />
                                {!isAdding &&
                                    <div className="btn-warn" onClick={() => setWantToDelete(true)}>Delete</div>
                                }
                            </div>
                        </Form>
                    </Formik>}
                {wantToDelete &&
                    <div className="bg-pc-200 p-4 flex flex-row gap-2 items-center">
                        <div className="grow">Really delete?</div>
                        <button className="btn-primary" onClick={() => setWantToDelete(false)}>No</button>
                        <button className="btn-warn" onClick={() => deleteBill(team, billId).then(() => navigate('..'))}>Yes</button>
                    </div>
                }
            </div>
    )
}