import { Fragment, useEffect, useMemo, useState } from "react";
import { useQuery } from "@tanstack/react-query";
import { XMarkIcon, ArrowUturnLeftIcon, ArrowDownTrayIcon, ArrowsPointingOutIcon } from "@heroicons/react/20/solid";
import { Link, useParams } from "react-router-dom";
import { Outlet } from "react-router-dom";
import { useTranslation } from "react-i18next";
import clsx from "clsx";
import { RiFocus3Line } from "react-icons/ri";
import { parseOneAddress } from "email-addresses";

import { ErrorMessage } from "../Messages";
import { useDms } from "./DocumentsSession";
import { createPreviewTicket, useAgorumObject, analysePath, useFolderReload, downloadLink, htmlDecode, adaptMailEmbeddedImageSrc } from "./backend";
import { AgorumObject, AgorumObjectProvider, useBrowser, useObject } from "./DocumentsProvider";
import { collectFirst } from "../collection";
import { prettyDate } from "../utils/dates";
import { useRoles } from "../user/Auth";
import { FileEditMenu } from "./DocumentFiles";
import _ from "lodash";
import { defaultPreviewProperties } from "../backend";

interface Ticket {
    height: number;
    lastModified: number;
    masterHeight: number;
    masterWidth: number;
    ticket: number;
    width: number;
}
export function TicketPreview({uuid}: {uuid: string}) {
    const {data} = useQuery<Ticket[], ErrorMessage, Ticket[]>({
        queryKey: ['ticket', uuid], 
        queryFn: async () => createPreviewTicket(uuid)
    })

    const ticket = data?.[0]

    //console.log({data})
    if (ticket?.ticket === undefined) return null // TODO: Download link

    
    const previewUrl = `/api/dms/download/api/rest/object/preview/${ticket.ticket}.png`
    
    return <img src={previewUrl} alt="Attachement" className="max-w-full max-h-full object-contain mx-auto" />
}


export function DocumentPreview() {
    const params = useParams()
    const documentId = params.id

    return (
        <AgorumObjectProvider
            uuid={documentId}
            properties={defaultPreviewProperties}>
            <div className="p-4 bg-white rounded-xl shadow sticky top-0">
                <RealPreview /*uuid={documentId}*/ />
            </div>
            <Outlet />
        </AgorumObjectProvider>
    )
}


function extractDocumentType(object?: AgorumObject): DocumentType {
    return object === undefined 
            ? 'none'
        : object.contentType?.startsWith('image') || object.name?.endsWith('.svg')  // TODO or check for image extension
            ? 'image'
        : object.contentType?.startsWith('application/pdf') // TODO or check for pdf extension
            ? 'pdf'
        : (object.contentType === 'message/rfc822' || object.name?.endsWith('.msg'))
            ? 'mail'
        : object.contentType?.startsWith('video')
            ? 'video'
        : 'other'
}

function DownloadButton({uuid, className, name}: {uuid: string, className?: string, name?: string}) {
    const {t} = useTranslation()

    return (
        <a
            className={className ?? "text-sm text-gray-500 hover:text-pcx-500"}
            href={downloadLink({uuid})} target="_blank" rel="noreferrer"
            title={t('download')}
            download={name}
        >
            <ArrowDownTrayIcon className="inline h-4 w-4 mb-0.5" />
        </a>
    )
}

const previewWidth = "w-full lg:w-[24rem] 2xl:w-[34rem] 3xl:w-[44rem]"

/*
 Some ideas
  delete: 
    - Check with modal
    - Mark object alread as in trash by updating the file list
    - In file list gray out the file 
    - Once deleted, refresh folder/file list and object it self
    - Close modal
    - Now show "Revert" button

  revert:
    - Check with modal (?)
    - add object to file list, folder but still as in trash => greyed out
    - refresh folder/file list and object it self

 - after move: update preview path
*/

export function RealPreview() {
    const {t} = useTranslation()
    const {isEditUser} = useRoles()

    const {fullpageLink, previewBackLink, openPath} = useBrowser()
    const {object, revert, error, fallback} = useObject()
    const {reload} = useFolderReload()

    if (error || object === undefined)
        return <ErrorPreview {...{error, fallback}} />

    const documentType: DocumentType = extractDocumentType(object)

    const context = collectFirst(object.allFolderPath ?? [], analysePath)
    const pathParts = context?.parts?.slice(0, -1) ?? []
    //console.log({object, context, pathParts})

    // TODO: translate labels
    const metaFields = [
        { key: 'name', label: 'name', transform: htmlDecode },
        { key: 'description', label: 'description' },
        { key: 'createDate', label: 'created', transform: prettyDate },
        { key: 'updateDate', label: object.inTrash ? 'deleted' : 'updated', transform: prettyDate },
    ]


    const name = htmlDecode(object.name)

    return (
        <>
            {object.inTrash && isEditUser &&
                <div className="w-full pb-1 text-center">
                    <button className="btn-secondary rounded py-0.5 mx-auto text-sm" onClick={() => revert().then(({target}) => reload(target))}>
                       <ArrowUturnLeftIcon className="h-4 w-4 inline mr-1 mb-0.5" /> {t('restore')} 
                    </button>
                </div>}
            <div className={clsx(previewWidth, "grid grid-cols-2 grid-flow-row-dense lg:grid-cols-[auto_1fr_auto] items-center gap-1 mb-2")}>
                <div className="flex flex-row gap-1 items-center">
                    <button title={t('open-location')} onClick={() => openPath(object.uuid, pathParts)} className="text-slate-500 hover:text-pcx-500"><RiFocus3Line /></button>
                    <Link to={previewBackLink} title={t('close')} className="text-slate-500 hover:text-pcx-500 text-sm"><XMarkIcon className="h-4 w-4" /></Link>
                </div>
                {context
                    ? <Link to={context.url} className="max-lg:col-span-2 mx-auto text-slate-500 hover:text-pcx-500 text-sm">{pathParts.join(' / ')}</Link>
                    : <div className="max-lg:col-span-2 mx-auto text-slate-500 text-sm">{name}</div> /* Show name for deleted objects (without context) */}
                <div className="justify-self-end flex flex-row gap-1 items-center">
                    <DownloadButton {...{uuid: object.uuid, name}} />
                    <Link to={fullpageLink(object)} title={t("full-screen")} className="text-gray-500 hover:text-pcx-500"><ArrowsPointingOutIcon className="h-4 w-4" /></Link>
                    {isEditUser ? <FileEditMenu /> : <div />}
                </div>
            </div>

            <div
                className={clsx(
                    (documentType === 'other' || documentType === 'pdf') && "h-[18rem] lg:h-[32rem] 2xl:h-[48rem] 3xl:h-[60rem]",
                    (documentType === 'image') && "min-h-0 max-h-[18rem] lg:max-h-[32rem] 2xl:max-h-[48rem]",
                    (documentType === 'video') && "h-80 min-h-[18rem] max-h-[18rem] lg:max-h-[32rem] 2xl:max-h-[48rem]",
                    previewWidth,
                    "border border-slate-300 shadow-sm rounded overflow-hidden bg-white text-slate-800 table-cell text-center"
                )}>
                <ScalablePreview {...{object, documentType}} />
            </div>

            <div className={clsx(previewWidth, "grid grid-cols-[auto_1fr] gap-x-2 gap-y-1 pt-4 text-xs")}>
                {metaFields.map(({key, label, transform}) => 
                    object[key] && <Fragment key={key}>
                        <div className="text-slate-600">
                            {t(label)}:
                        </div>
                        <div className="text-slate-900 truncate" title={object[key]}>
                            {(transform ?? (x => x))(object[key])}
                        </div>
                    </Fragment>)}
            </div>
        </>
    )
}

function ErrorPreview({error, fallback}: {error?: ErrorMessage, fallback: string | undefined}) {
    const {t} = useTranslation()
    return <>
        <div className={clsx("w-full text-sm text-gray-700 min-w-0 truncate text-center", previewWidth)}>
            {fallback ?? t('error')}
        </div>
        {error && <div className={clsx("pt-4 text-sm text-wrap break-all", previewWidth)}>
            {error.message}
        </div>}
    </>
}


function ScalablePreview({ object, documentType, className = '' }: { object: AgorumObject, documentType: DocumentType, className?: string}) {
    const {baseurl, sessionId} = useDms()
    //const embed_path = (documentType === 'pdf' || documentType === 'image') 
    //    ? `/api/dms/download/api/rest/object/embed/${uuid}` 
    //    : `/api/dms/download/api/rest/object/embed/${uuid}/pdf`
    const view = useMemo(() => {
        const uuid = object.uuid

        if (uuid === undefined || baseurl === '' || sessionId === '') return null

        const asPdf = !(documentType === 'pdf' || documentType === 'image' || documentType === 'video')
        const embed_path = asPdf
            ? `https://${baseurl}/api/rest/object/embed/${uuid}/pdf?sessionId=${sessionId}`
            : `https://${baseurl}/api/rest/object/embed/${uuid}?sessionId=${sessionId}`

        const isOctetStreamImage = documentType === 'image' && object.contentType === 'application/octet-stream'
        return isOctetStreamImage
            ? <SvgOctetStream url={embed_path} />
                        //? <TicketPreview uuid={uuid} />
            : documentType === 'none'
            ? null
            : documentType === 'mail'
                ? <MailView {...{ object }} />
                : documentType === 'pdf'
                    ? <div className="h-full w-full">
                        <object data={embed_path} width="100%" height="100%">
                            <div>
                                <p>Cannot display file. You have to download manually.</p>
                                <DownloadButton {...{uuid, name: htmlDecode(object.name)}} />
                            </div>
                        </object>
                    </div>
                    // TODO: very high images are going to stay very high and not be limited by container :-/
                    : <object data={embed_path}
                        type={asPdf ? undefined : (object.contentType ?? "image")}
                        width="100%"
                        height={(asPdf || documentType === 'video') ? "100%" : "auto"}
                        className={className}
                    >
                        <TicketPreview uuid={uuid} />
                    </object>
    }, [documentType, baseurl, sessionId, className, object])

    return view
}

function SvgOctetStream({url, className}: {url: string, className?: string}) {
    const [innerHTML, setInnerHTML] = useState(undefined)

    useEffect(() => {
        if (!innerHTML) {
            fetch(url)
                .then(response => {
                    if (!response.ok) {
                        throw new Error('Network response was not ok ' + response.statusText);
                    }
                    return response.text()
                })
                .then(svg => setInnerHTML(svg))
                .catch(err => console.error({err}))
        }
    }, [url, innerHTML])

    if (!innerHTML) return null

    return <div className={className ?? "svg-container w-full h-full"} dangerouslySetInnerHTML={{__html: innerHTML}} />
}

type DocumentType = 'none' | 'pdf' | 'image' | 'video' | 'mail' | 'other'

type EmailData = { fromAddress?: string[], toAddress?: string[], ccAddress?: string[], subject?: string }
function MailView({object}: {object: AgorumObject & EmailData}) {
    //console.log({object})
    const preview = fixImageLinks(object['bestBodyPart.content'])
    const attachement_uuids: string[] = object['mailAttachments.pluck(uuid)']
    const attachement_names: string[] = object['mailAttachments.pluck(name)']
    const attachements = _.zip(attachement_uuids, attachement_names).map(([uuid, name]) => ({uuid, name}))
    return <>
        <MailHeader {...object} />
        <iframe title={object.name} srcDoc={preview} className="text-left p-2 text-slate-900 min-h-[60vh] max-h-[60vh] w-full overflow-y-auto" />
        {/* <div className="text-left p-2 text-slate-900 max-h-[60vh] overflow-y-auto" dangerouslySetInnerHTML={{__html: preview}}>
        </div> */}
        <div className="empty:hidden flex flex-row flex-wrap gap-2 p-2 border-t border-gray-300">
            {attachements.map(({uuid, name}) => <MailAttachement key={uuid} {...{name, uuid}} />)}
        </div>
        <div className="flex flex-col text-left px-2 pb-2 text-sm">
            {_(attachements)
                .sortBy(a => a.name?.toLocaleLowerCase())
                .map(({uuid, name}) => <MailAttachementListItem key={uuid} {...{name, uuid}} />)
                .value()}
        </div>
    </>
}

const listStyle = "after:content-[',_'] last:after:content-[''] hover:text-pcx-600 hover:underline"

function MailHeader({subject, fromAddress, toAddress, ccAddress}: EmailData) {
    const {t} = useTranslation()
    return (
        <div className="flex flex-col gap-y-1 text-slate-900 text-sm text-left p-2 border-b border-gray-300">
            <div>
                <span className="text-slate-500 max-xl:hidden">{t('e-mail.subject')}:</span> <span className="font-semibold">{subject}</span>
            </div>
            <div className="flex flex-row flex-wrap">
                <div className="text-slate-500 max-xl:hidden">{t('e-mail.from')}:</div>
                {fromAddress?.map((address, a) => <MailAddress key={a} long address={address} className={clsx("xl:pl-1 first:pl-0 font-semibold", listStyle)} />)}
            </div>
            <div className="flex flex-row flex-wrap gap-1">
                {toAddress?.length > 0 && <div className="text-slate-500">{t('e-mail.to')}:</div>}
                {toAddress?.map((address, a) => <MailAddress key={`to-${a}`} {...{address}} className={listStyle}/>)}
                {ccAddress?.length > 0 && <div className="text-slate-500">{t('e-mail.cc')}:</div>}
                {ccAddress?.map((address, a) => <MailAddress key={`cc-${a}`} {...{address}} className={listStyle}/>)}
            </div>
        </div>
    )
}

function MailAddress({address, className, long = false}: {address: string, className?: string, long?: boolean}) {
    const parsed = parseOneAddress(address)
    const name = parsed.name
    const email = parsed.type === 'mailbox' ? parsed.address : (parsed.addresses?.[0]?.address ?? address)
    return <a href={`mailto:${email}`} title={email} className={className}>
        <span className="lg:hidden">{name}</span>
        <span className="hidden lg:inline">{long ? address : name}</span>
    </a>

}

function MailAttachement({uuid, name}: {uuid: string, name: string}) {
    const {t} = useTranslation()

    return (
        <a
            title={t('download') + ' - ' + name}
            className="block h-24 w-20 border border-gray-300 rounded-xs overflow-hidden relative group"
            href={downloadLink({uuid})} target="_blank" rel="noreferrer"
        >
            <div className="hidden absolute group-hover:flex items-center bg-pcx-900/10 w-full h-full">
                <ArrowDownTrayIcon className="h-10 w-10 m-auto text-pcx-900/70" />
            </div>
            <TicketPreview uuid={uuid} />
            <div className="h-4 mt-auto px-px text-ellipsis overflow-hidden text-xs whitespace-nowrap w-full">
                {name}
            </div>
        </a>
    )
}

function MailAttachementListItem({uuid, name}: {uuid: string, name?: string}) {
    return <a href={downloadLink({uuid})} target="_blank" rel="noreferrer" className="block text-slate-900 hover:text-pcx-500 underline">{name ?? uuid.slice(0, 16)}</a>
}


export function FullPagePreviewRoute() {
    const params = useParams()
    const uuid = params.id

    return <FullPagePreview uuid={uuid} />
}

export function FullPagePreview({uuid}: {uuid: string, backlink?: string}) {
    const {t} = useTranslation()

    const {object} = useAgorumObject({uuid, properties: ['bestBodyPart.content', 'mailAttachments.pluck(uuid)', 'mailAttachments.pluck(name)']})

    const documentType: DocumentType = extractDocumentType(object)
    const {fullpageBackLink} = useBrowser()

    if (!object) return null

    //console.log({object, documentType})
    const name = htmlDecode(object.name)

    return (
        <div className="fixed inset-0 bg-black bg-opacity-70 z-50 flex items-center justify-center">
            <div>
                <div className="flex flex-row lg:grid lg:grid-cols-[1fr_auto_1fr] gap-2 items-center p-2 justify-between text-gray-100 bg-black bg-opacity-10 rounded-t-lg">
                    <div />
                    <div>{name}</div>
                    <div className="flex flex-row-reverse gap-2 items-center">
                        <Link className="text-gray-300 hover:text-pcx-100" to={fullpageBackLink} title={t('close')}><XMarkIcon className="h-5 w-5" /></Link>
                        <DownloadButton {...{uuid, name}} className="text-gray-300 hover:text-pcx-100" />
                    </div>
                </div>
                <div className={clsx(
                    "resize overflow-auto max-h-[95vh] max-w-[90vw] pb-2 bg-slate-200",
                    (documentType === 'video') && "h-[70vh] w-[80vw]",
                    (documentType === 'other' || documentType === 'pdf') && "min-w-[85vw] md:min-w-4xl h-[80vh]")}>
                    <ScalablePreview {...{object, documentType}} className="max-h-[80vh] max-w-[90vw] w-full h-full object-contain"/>
                </div>

            </div>
        </div>
    )
}

function fixImageLinks(content: string) {
    const parser = new DOMParser();
    const doc = parser.parseFromString(content, 'text/html');

    // Replace CID references
    doc.querySelectorAll('img').forEach(img => {
        const src = img.getAttribute('src');
        if (src && src.startsWith('cid:')) {
            const updatedSrc = adaptMailEmbeddedImageSrc(src);
            img.setAttribute('src', updatedSrc);
        }
    });

    return doc.documentElement.outerHTML;
}