import { Fragment, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Helmet } from 'react-helmet-async'
import { Menu, Transition, Combobox, ComboboxInput, ComboboxButton, ComboboxOptions, ComboboxOption, MenuButton, MenuItems, MenuItem } from '@headlessui/react'
import { Link, useNavigate, useSearchParams, Outlet } from 'react-router-dom'
import { Navigate, useParams } from 'react-router'
import { NavLink } from 'react-router-dom'
import { Bar, BarChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts'
import { CheckIcon, ChevronUpDownIcon, ExclamationTriangleIcon } from '@heroicons/react/24/outline'
import _ from 'lodash'

import { amountModel, scenarioModel, sumModels } from './forecasting'
import { Family } from '../patents/patents'
import { colors, countryCodes, epCountries, eaCountries, supportedCurrencies } from '../data'
import { familyUrl, memberUrl } from '../patents/utils'
import { IconCopy, IconChevronDown, IconEdit, IconPlus, IconX } from '../components/icons'
import { Scenario, ScenarioAmount, ScenarioFamily, ScenarioFamilyInput, ScenarioMember, useForecast } from './ForecastProvider'
import Modal from '../components/Modal'
import { useField, Formik, Form, Field, FieldHookConfig } from 'formik'
import { DeleteButton } from '../components/edit-table'
import { DatePicker } from '../components/input/DatePicker'
import { TagListField } from '../components/TagList'
import { useRoles } from '../user/Auth'
import clsx from 'clsx'
import { useFxContext } from './FxProvider'
import { useFilteredPatents } from '../filter/FilteredPatents'
import { usePatents } from '../patents/PatentsProvider'


export default function Forecast() {
    const {isLoading} = usePatents()
    const {families, members} = useFilteredPatents()

    if (isLoading)
        return <div />
    else
        return <ForecastData key={`${families.length}-${members.length}`} />
}

function CreateScenarioButton() {
    const {t} = useTranslation()
    const { hasScenarios } = useForecast()
    return (
        <Link className={clsx(
            hasScenarios ? "bg-white text-pcx-600" : "bg-pcx-600 text-pcx-100",
            "hover:bg-pcx-500 hover:text-pcx-100 rounded-md p-1 px-2 shadow-sm",
            "font-normal rounded-md self-end"
        )} to="scenario">
            <div className="h-6 w-6 mb-1 inline-block align-middle mr-2"> <IconPlus /></div>
            {t('forecast.create-new-scenario')}
        </Link>
    )
}

function ForecastData() {
    const {t} = useTranslation()
    const {isDev, isEditUser} = useRoles()
    const { scenarioFamilies, isPureFamily, hasScenario } = useForecast()
    const {families} = useFilteredPatents()

    const [searchTerm, setSearchTerm] = useState('')

    const pureScenarioFamilies = scenarioFamilies.filter(f => isPureFamily(f))

    return <>
        {/* @ts-ignore */}
        <Helmet>
            <title>{t('forecast.title')} | Patent Cockpit</title>
        </Helmet>
        <div className="header-row">
            <div className="flex flex-row gap-2 pr-2">
                <h2 className="modern-h2 grow">{t('forecast.title')}</h2>
                {isDev && <Link className="btn-secondary font-normal text-base py-px" to="model">Model</Link>}
                {isDev && <button className="btn-secondary font-normal text-base py-px">Excel Export</button>}
            </div>
        </div>
        <div className="main-content px-4 pt-0 dark:border-t-0 pb-1 bg-pcx-100 flex flex-row gap-4 sm:max-h-full sm:overflow-hidden xl:max-w-7xl max-w-full">
            <div className="max-w-full sm:max-w-xl hidden only:flex lg:flex flex-col gap-2">
                {isEditUser && <CreateScenarioButton /> }
                <PortfolioForecast />
                <h3 className='text-pcx-700 font-semibold pl-2 mt-1 -mb-1'>{t('families')}</h3>
                <div className="grow flex flex-col gap-2 sm:overflow-y-auto lg:pr-2">
                    <div className='flex flex-col sm:flex-row gap-2 sm:justify-between sm:items-center'>
                        <input 
                            type="text" className="form-input border-pcx-200 grow focus:border-pcx-300 py-1 px-2 bg-pcx-100 focus:bg-white rounded-md focus:ring-0" 
                            placeholder={`${t('search')}...`} value={searchTerm} onChange={e => setSearchTerm(e.target.value)} />
                        {hasScenario && isEditUser && <Link className="bg-white hover:bg-pcx-500 hover:text-pcx-100 rounded-md p-1 text-pcx-600 px-2" to="scenario-family/add">
                            <div className="h-6 w-6 mb-1 inline-block align-middle mr-2"> <IconPlus /></div>
                            {t('forecast.add-scenario-family')}
                        </Link>}
                    </div>
                    {_(pureScenarioFamilies)
                        .filter(f => searchTerm === '' || f.name.toLowerCase().includes(searchTerm.toLowerCase()))
                        .sortBy(f => f.name)
                        .map((family, i) => <ScenarioFamilyLink key={family.scenarioFamilyId + '-' + i} {...family} />)
                        .value()}
                    {_(families)
                        .filter(f => searchTerm === '' 
                            || f.internalReference.toLowerCase().includes(searchTerm.toLowerCase()) 
                            || f.familyName?.toLowerCase()?.includes(searchTerm.toLowerCase()))
                        .sortBy(f => f.internalReference)
                        .reverse()
                        .map(f => <FamilyLink key={f.internalReference} {...{ ...f }} />)
                        .value()}
                </div>
            </div>
            <Outlet />
        </div>
        <div className='text-slate-400 text-2xs px-4 py-1'>
            <a href="https://patent-cockpit.com/terms"  target="_blank" rel="noreferrer">
                {t('forecast.disclaimer')}
            </a>
        </div>
    </>
}

function FamilyLink({patentFamilyId, internalReference, familyName}: Family) {
    const {isEditUser} = useRoles()
    const {hasScenario, isSelectedFamily, setFamilySelected} = useForecast()
    const target = `family/${patentFamilyId}`
    return <div className="bg-white rounded-md shadow-sm pl-2 flex flex-row gap-2 items-center ">
        {hasScenario && isEditUser && <input className="form-checkbox w-4 h-4" type="checkbox"
            checked={isSelectedFamily(patentFamilyId)}
            onChange={e => setFamilySelected(patentFamilyId, e.target.checked)} />}
        <NavLink  to={target} className={({isActive}) => 
            `hover:underline grow text-pcx-600 whitespace-nowrap rounded-r-md overflow-hidden py-1 pr-2 truncate text-ellipsis ${isActive ? "border-r-4 border-pcx-500" : ""}`
        }>
                {internalReference}: {familyName}
        </NavLink>
    </div>
}

function ScenarioFamilyLink(scenarioFamily: ScenarioFamily) {
    const {t} = useTranslation()
    const {isEditUser} = useRoles()

    const {scenarioFamilyId, name} = scenarioFamily
    const {hasScenario, isSelectedPureScenarioFamily, setScenarioFamilySelected} = useForecast()
    const target = `scenario-family/${scenarioFamilyId}`

    return <div className="relative bg-white rounded-md shadow-sm pl-2 inline-flex gap-2 items-center">
        {hasScenario && isEditUser && <input className="form-checkbox w-4 h-4 text-pcx-300" type="checkbox"
            checked={isSelectedPureScenarioFamily(scenarioFamilyId)}
            onChange={e => setScenarioFamilySelected(scenarioFamilyId, e.target.checked)} />}
        <NavLink to={target} className={({isActive}) => 
            `hover:underline grow text-pcx-400 whitespace-nowrap rounded-r-md overflow-hidden py-1 pr-2 truncate text-ellipsis ${isActive ? "peer border-r-4 border-pcx-300" : ""}`
        }>
            {name}
        </NavLink>
        {isEditUser && <Link title={t('copy')} className='absolute right-3 group hover:bg-pcx-500 rounded-sm p-0.5' to={`scenario-family/${scenarioFamilyId}/copy`} >
            <IconCopy className="h-5 w-5 text-pcx-300 group-hover:text-pcx-100"/> 
        </Link>}
    </div>
}


function PortfolioForecast() {
    const {t} = useTranslation()
    const {isEditUser} = useRoles()
    const {hasScenario, currency} = useForecast()
    const {today, familyModels, scenarioAmountModels, scenarioFamilyModels, familyLookup, isSelectedFamily, isSelectedPureScenarioFamily} = useForecast()
    const models = _(familyModels).toPairs()
        .flatMap((pair) => {
            const [key, models] = pair
            return (isSelectedFamily(key))
                ? _.values(models)
                : [] as number[][]
        })
        .filter(m => m.length > 0)
        .value()
    //console.log(flatted)
    //console.log(models)
    const costs = sumModels({models, today})

    const isScenarioFamilySelected = (_scenarioFamilyId: string) => {
            const scenarioFamilyId = parseInt(_scenarioFamilyId)
            const isPureFamily = !(scenarioFamilyId in familyLookup)
            return isPureFamily 
                ? isSelectedPureScenarioFamily(scenarioFamilyId)
                : isSelectedFamily(familyLookup[scenarioFamilyId])
    }

    const scenarioModels =_(scenarioFamilyModels).toPairs()
        .flatMap(([scenarioFamilyId, model]: [scenarioFamilyId: string, model: number[][]]) => isScenarioFamilySelected(scenarioFamilyId) ? model : [])
        .value()
    const amountModels = _(scenarioAmountModels).toPairs()
        .flatMap(([scenarioFamilyId, model]: [scenarioFamilyId: string, model: number[][]]) => isScenarioFamilySelected(scenarioFamilyId) ? model : [])
        .value()
    //console.log({scenarioModels})
    const scenarioCosts = sumModels({models: [...scenarioModels, ...amountModels], today})

    return (
        <div className="p-4 bg-white rounded-md shadow-sm">
            <div className='flex flex-col sm:flex-row gap-4 sm:items-center pb-4 w-full max-w-full'>
                <ScenarioSelect className='form-select border-pcx-200 py-1 sm:min-w-2xs sm:max-w-xs shrink-1 font-semibold text-pcx-900 truncate text-ellipsis' />
                {hasScenario 
                    &&  <div className='flex flex-row gap-4 items-center justify-end grow'>
                        {/*<div className='text-slate-500 ml-auto whitespace-nowrap'>{selectedScenario.referenceDate.substring(0, 4)} / {selectedScenario.currency}</div>*/}
                        {isEditUser && <ScenarioMenu />}
                    </div>}
            </div>
            <CostChart {...{ costs, scenarioCosts }} />
            <div className="flex flex-col sm:flex-row gap-2 sm:gap-6 xl:gap-8 text-slate-500 text-sm w-full">
                <div className="inline-flex gap-2 items-center"><div className="h-4 w-4 bg-pcx-500" />{t('forecast.costs-from-existing')} ({currency})</div>
                <div className="inline-flex gap-2 items-center"><div className="h-4 w-4 bg-pcx-300" />{t('forecast.costs-from-scenario')} ({currency})</div>
            </div>
        </div>
    )
}

export function ScenarioSelect({className = 'form-select'}) {
    const {t} = useTranslation()

    const {selectedScenario, setSelectedScenario, setNoScenario, scenarios} = useForecast()
    const versionsById = _.keyBy(scenarios, 'scenarioId')
    
    const withoutScenario = t('forecast.without-scenario')

    return (
        <select
            className={className}
            value={selectedScenario?.scenarioId ?? -1}
            onChange={e => {
                const id = parseInt(e.target.value)
                if (id === -1)
                    setNoScenario()
                else
                    setSelectedScenario(versionsById[id])
            }}
        >
            {scenarios.map(({ scenarioId, name }) =>
                scenarioId === undefined 
                    ? null 
                    : <option key={scenarioId} value={scenarioId}>
                        {name}
                    </option>)}
            <option value={-1}>{withoutScenario}</option>
        </select>

    )
}

function ScenarioMenu() {
    const {t} = useTranslation()
    const {selectedScenario, hasScenario} = useForecast()
    const items = [
        //{to: 'scenario', label: t('forecast.create-new-scenario')},
        {to: `scenario?copy=${selectedScenario?.scenarioId}`, label: t('forecast.copy-scenario')},
        {to: `scenario/${selectedScenario?.scenarioId}`, label: t('forecast.edit-scenario'), disabled: !hasScenario},
        {to: `scenario/${selectedScenario?.scenarioId}/delete`, label: t('forecast.delete-scenario'), disabled: !hasScenario},
    ]
        
    return (
        <Menu as="div" className="relative inline-block text-left">
            <div>
                <MenuButton className="btn-secondary inline-flex justify-center font-normal focus:outline-0 focus:ring-0">
                    {t('forecast.actions')}
                    <IconChevronDown aria-hidden="true" />
                </MenuButton>
            </div>

            <Transition
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
            >
                <MenuItems className="absolute right-0 z-10 mt-2 w-56 origin-top-right rounded bg-white border border-pcx-500/50 shadow-lg ring-1 ring-pcx-600 ring-opacity-5 focus:outline-none overflow-hidden">
                    {items.map(({ to, label, disabled }) =>
                        disabled ? null :
                            <MenuItem key={to}>
                                {({ active }) => <Link to={to} className={`${active ? 'bg-pcx-200 text-pcx-800' : 'text-pcx-600'} block px-4 py-2 text-sm`}>{label}</Link>}
                            </MenuItem>
                    )}
                </MenuItems>
            </Transition>
        </Menu>
    )
}

export function AddEditScenarioVersion() {
    const {t} = useTranslation()
    const navigate = useNavigate()
    const [searchParams] = useSearchParams()

    const {setSelectedScenario, addScenario, updateScenario, scenarios, copyScenario} = useForecast()

    const {scenarioId: _scenarioId} = useParams()
    const scenarioId = parseInt(_scenarioId)
    const scenario = scenarios.find(v => v.scenarioId === scenarioId)
    const isAdding = scenario === undefined

    const copy_id = parseInt(searchParams.get('copy'))
    const isCopy = isFinite(copy_id) && isAdding
    const copy: Scenario | undefined = isCopy
        && {...scenarios.find(v => v.scenarioId === copy_id), scenarioId: undefined}

    const initialValues: Scenario = 
        isCopy 
        ? {...copy, name: t('forecast.copy-of', {name: copy.name, interpolation: {escapeValue: false}})}
        : isAdding 
        ? {name: '', referenceDate: new Date().toISOString().slice(0, 10)} 
        : scenario
    //console.log({copy, initialValues})

    return (
        <Modal escAction={() => navigate("..")} overflowHidden={false}>
            <Formik initialValues={initialValues}
                onSubmit={(values, { setSubmitting }) => {
                    //console.log({today: initialValues.referenceDate, values})
                    return isAdding 
                        ? addScenario(values)
                            .then((withId: Scenario) => {
                                setSelectedScenario(withId)
                                return isCopy ? copyScenario(withId.scenarioId) : {}
                            })
                            .finally(() => {
                                setSubmitting(false)
                                navigate("..")
                            })
                        : updateScenario(values).finally(() => {
                            setSubmitting(false);
                            navigate("..")
                        })
                }}>
                <Form >
                    <div className='p-4 flex flex-col gap-4'>
                        <h3>{isAdding ? t("forecast.create-new-scenario") : t("forecast.edit-scenario")}</h3>
                        <label className='w-full'>
                            <div className="label mb-1">{t('forecast.scenario-name')}</div>
                            <Field className="form-input w-full" type="text" name="name" placeholder="Scenario Name" autoFocus required/>
                        </label>
                        <label className='w-fit'>
                            <div className="label mb-0">{t('forecast.scenarioReferenceYear')}</div>
                            <div className="text-sm text-slate-500 mb-1">{t('forecast.reference_year_explanation')}</div>
                            <ReferenceYear name="referenceDate" required/>
                        </label>
                    </div>
                    <div className="p-4 bg-pcx-200 flex flex-row-reverse gap-2 rounded-b-lg">
                        <input type="submit" className="btn-primary" value={isAdding ? t("create") : t("update")} />
                        <Link to=".." className="btn-secondary">{t('cancel')}</Link>
                    </div>
                </Form>
            </Formik>
        </Modal>
    )
}

const thisYear = new Date().getFullYear()

const years = _.range(thisYear - 5, 2099).map(year => ({year: year.toString(), date: `${year}-01-01`}))

interface DisplayYear {
    year: string;
    date: string;
}

function ReferenceYear({...props}: FieldHookConfig<string>) {
    // eslint-disable-next-line
    const [field, meta, helpers] = useField(props);

    const [query, setQuery] = useState('')
    const filteredYears = years.filter(y => y.year.includes(query))

    const value = years.find(y => y.year === field.value.substring(0, 4))

    return (
        <div className="w-full">
            <Combobox value={value} onChange={(e) => {
                const y = years.find(({ year }) => e.year === year)
                helpers.setValue(y?.date)
            }}>
                <div className="relative mt-1">
                    <div className="relative w-full cursor-default overflow-hidden rounded bg-white border border-slate-400 text-left focus:outline-none focus-visible:ring-2">
                        <ComboboxInput
                            className="w-full border-none py-3 pl-3 pr-10 text-base leading-5 text-gray-900 focus:ring-0"
                            displayValue={({year}: DisplayYear) => year}
                            onChange={(event) => setQuery(event.target.value)}
                        />
                        <ComboboxButton className="absolute inset-y-0 right-0 flex items-center pr-2">
                            <ChevronUpDownIcon
                                className="h-6 w-6 text-pcx-500"
                                aria-hidden="true"
                            />
                        </ComboboxButton>
                    </div>
                    <Transition
                        as={Fragment}
                        leave="transition ease-in duration-100"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                        afterLeave={() => setQuery('')}
                    >
                        <ComboboxOptions className="absolute mt-1 max-h-48 w-full overflow-auto rounded-md bg-white border border-slate-300 py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none text-sm">
                            {filteredYears.length === 0 && query !== '' ? (
                                <div className="relative cursor-default select-none py-2 px-4 text-gray-700">
                                    Nothing found.
                                </div>
                            ) : (
                                filteredYears.map((year) => (
                                    <ComboboxOption
                                        key={year.year}
                                        className={({ active, selected }) => clsx(
                                            'relative list-none cursor-default select-none py-1 pl-10 pr-4',
                                            active ? 'bg-pcx-500 text-white' : 'text-pcx-900',
                                            selected ? 'font-semibold bg-pcx-200' : 'font-normal'
                                        )}
                                        value={year}
                                    >
                                        {({ selected, active }) => (
                                            <>
                                                <span className='block truncate' >
                                                    {year.year}
                                                </span>
                                                {selected ? (
                                                    <span
                                                        className={`absolute inset-y-0 left-0 flex items-center pl-3 ${active ? 'text-white' : 'text-pcx-600'
                                                            }`}
                                                    >
                                                        <CheckIcon className="h-5 w-5" aria-hidden="true" />
                                                    </span>
                                                ) : null}
                                            </>
                                        )}
                                    </ComboboxOption>
                                ))
                            )}
                        </ComboboxOptions>
                    </Transition>
                </div>
            </Combobox>
        </div>
    )
}


export function DeleteScenario() {
    const {t} = useTranslation()
    const navigate = useNavigate()
    const { deleteScenario, scenarios, setSelectedScenario} = useForecast()

    const {scenarioId: _scenarioId} = useParams()
    const scenarioId = parseInt(_scenarioId)
    const scenario = scenarios.find(v => v.scenarioId === scenarioId)

    if (scenario === undefined) {
        return <Navigate to=".." />
    } else
        return (
            <Modal>
                <div className="bg-white p-4">
                    <h4>{t('forecast.delete-scenario-name', {name: scenario?.name})}</h4>
                </div>
                <div className="bg-pcx-200 p-4 flex flex-row-reverse gap-4">
                    <button 
                        onClick={() => 
                            deleteScenario(scenario)
                                .then(() => setSelectedScenario(undefined))
                                .then(() => navigate(".."))
                            }
                        className='btn-warn'
                    >{t('delete')}</button>
                    <Link className='btn-secondary' to="..">{t('cancel')}</Link>
                </div>
            </Modal>
        )
}

export function FamilyForecast() {
    const {t} = useTranslation()
    const {isEditUser} = useRoles()
    const {families} = usePatents()
    const { membersByFamilyId } = useFilteredPatents()
    const { fxConverter } = useFxContext()

    const [hoveredMember, setHoveredMember] = useState(undefined)
    const [hoveredScenario, setHoveredScenario] = useState(undefined)
    const [hoveredAmount, setHoveredAmount] = useState(undefined)

    const {patentFamilyId: _patentFamilyId} = useParams()
    const patentFamilyId = parseInt(_patentFamilyId)

    const family = families.find(f => f.patentFamilyId === patentFamilyId)
    const {
        today, currency, 
        familyModels, scenarioFamilyModels, scenarioAmountModels,
        scenarioFamilies, membersByScenarioFamily, 
        hasScenario, 
        isSelectedFamily, setFamilySelected,
        isSelectedMember, setMemberSelected,
        scenarioAmounts,
    } = useForecast()
    const scenarioFamilyId = scenarioFamilies.find(f => f.patentFamilyId === patentFamilyId)?.scenarioFamilyId
    const scenarioMembers = membersByScenarioFamily[scenarioFamilyId] ?? []
    //console.log({membersByScenarioFamily, scenarioFamilyId, scenarioMembers})
    const members = membersByFamilyId[patentFamilyId] ?? []

    const amounts = scenarioAmounts.filter(a => a.scenarioFamilyId === scenarioFamilyId)
    //console.log({amounts })

    const models = _.values(familyModels[patentFamilyId] ?? {})
    //console.log({models})
    const scenarioModels = scenarioFamilyModels[scenarioFamilyId] ?? []
    const amountModels = scenarioAmountModels[scenarioFamilyId] ?? []
    //console.log({scenarioModels})

    const costs = sumModels({models, today})
    const scenarioCosts = sumModels({models: [...scenarioModels, ...amountModels], today})

    const priorityDate = family?.priorityDate ?? _(members.map(m => m.applicationDate)).min() ?? today

    let hoveredModel = undefined
    if (hoveredMember >= 0) {
        const monthly = familyModels[patentFamilyId]?.[hoveredMember]
        hoveredModel = monthly ? sumModels({models: [monthly], today}) : undefined
    } else if (hoveredScenario >= 0) {
        const hoveredScenarioMember = scenarioMembers.find(m => m.scenarioMemberId === hoveredScenario)
        const monthly =  scenarioModel({scenarioMember: hoveredScenarioMember, today, currency, fxConverter, priorityDate})
        hoveredModel = sumModels({models: [monthly], today})
    } else if (hoveredAmount >= 0) {
        const scenarioAmount = amounts.find(a => a.scenarioAmountId === hoveredAmount)
        const monthly = amountModel({scenarioAmount, today, currency, fxConverter})
        hoveredModel = sumModels({models: [monthly], today})
    }

    if (!family) return null

    let maxCosts = undefined
    if (hoveredMember >= 0 || hoveredScenario >= 0 || hoveredAmount >= 0) {
        maxCosts = _([...models, ...scenarioModels, ...amountModels])
            .map(model => _(sumModels({models: [model], today})).map(({cost}) => cost).max())
            .max()
    }
    //const maxCosts = _([...scenarioCosts, ...costs])
    //    .map(({year}) => year)
    //    .uniq()
    //    .map(year => (scenarioCosts.find(c => c.year === year)?.cost ?? 0.0) + (costs.find(c => c.year === year)?.cost ?? 0.0))
    //    .max()

    //console.log({scenarioCosts, costs})
    //const maxCosts = [...costs, ...scenarioCosts].reduce((max, {cost}) => Math.max(max, cost), 0)

    return (
        <div className="rounded-md shadow-sm bg-white grow self-start min-w-0 sm:max-h-full sm:overflow-y-auto pb-4">
            <div className="p-4 flex flex-row items-center gap-4 max-w-full">
                {hasScenario && isEditUser && <input className="form-checkbox w-4 h-4" type="checkbox"
                    checked={isSelectedFamily(patentFamilyId)}
                    onChange={e => setFamilySelected(patentFamilyId, e.target.checked)} />}

                <h2 className="text-pcx-600 font-normal min-w-0  focus:outline-none truncate text-ellipsis grow">
                    <Link className='hover:underline' to={familyUrl(family)}>{family.internalReference}: {family.familyName}</Link>
                </h2>
                <Link to=".."><IconX/></Link>
            </div>
            <div className="max-w-sm sm:min-w-xs p-4">
                {hoveredModel
                    ? <CostChart maxCosts={maxCosts} costs={hoveredModel} midShade />
                    : <CostChart maxCosts={maxCosts} {...{ costs, scenarioCosts }} />}
            </div>
            <div className="flex flex-col px-4">
                {members.map(m => {
                    const selected = isSelectedMember(m.familyMemberId)
                    return (
                        <div key={m.internalReference} className="inline-flex gap-2 items-center py-1" 
                            onMouseEnter={() => setHoveredMember(m.familyMemberId)}
                            onMouseLeave={() => setHoveredMember((hm?: number) => hm === m.familyMemberId ? undefined : hm)}
                        >
                            {hasScenario && isEditUser && <input
                                checked={selected} onChange={e => setMemberSelected(m.familyMemberId, e.target.checked)}
                                type="checkbox" className="form-checkbox" />}
                            <Link className="hover:underline pl-1 mr-auto" to={memberUrl(m)}>{m.internalReference}</Link>
                            {!selected && <span className='text-gray-400'>({t('forecast.will-be-dropped')})</span>}
                        </div>
                    )
                })}
            </div>
            <div className="flex flex-col">
                {scenarioMembers.map(m => 
                    <ScenarioMemberRow key={`sm-${m.scenarioMemberId}`} {...m} 
                        onMouseEnter={() => setHoveredScenario(m.scenarioMemberId)} 
                        onMouseLeave={() => setHoveredScenario((hs?: number) => hs === m.scenarioMemberId ? undefined : hs)} 
                    />)}
                {amounts.map(a => 
                    <ScenarioAmountRow key={`sa-${a.scenarioAmountId}`} {...a} 
                        onMouseEnter={() => setHoveredAmount(a.scenarioAmountId)} 
                        onMouseLeave={() => setHoveredAmount((hs?: number) => hs === a.scenarioAmountId ? undefined : hs)} 
                    />)}
            </div>
            {hasScenario && isEditUser && <div className='flex flex-row flex-wrap gap-2 p-4 pb-0'>
                <AddScenarioMemberLink />
                <AddScenarioAmountLink />
            </div>}
            <Outlet />
        </div>
    )
}

function AddScenarioMemberLink() {
    const {t} = useTranslation()
    return <Link
        className="btn-secondary w-fit whitespace-nowrap"
        to="member"
    >+ {t('forecast.add-filings')}</Link>
}

function AddScenarioAmountLink() {
    const {t} = useTranslation()
    return <Link
        className="btn-secondary w-fit whitespace-nowrap"
        to="amount"
    >+ {t('forecast.add-fixed-costs')}</Link>
}


function ScenarioMemberRow({onMouseEnter, onMouseLeave, ...scenarioMember}: {onMouseEnter?: () => void, onMouseLeave?: () => void} & ScenarioMember) {
    const {t} = useTranslation()
    const {isEditUser} = useRoles()
    const {deleteScenarioMember, isSelectedScenarioMember, setScenarioMemberSelected} = useForecast()
    const {type, date, countries, firstFiling, scenarioMemberId, unitaryPatent, fromPct, divisionalFiling} = scenarioMember
    // TODO move to common.json
    const asList = countries.join(", ")
    const title = 
        type === "national" && firstFiling ? t('forecast.first_filing_in', {count: countries.length, countries: asList}) : 
        type === "national" ? t('forecast.national_application_in', {count: countries.length, countries: asList}) : 
        type === "pct" ? 'PCT' : 
        type === "ea" ? t('forecast.ea_patent_in', {count: countries.length, countries: asList}) :
        t('forecast.ep_member', {countries: asList, count: countries.length})

        // TODO unitary patent
    const ribbons = [
        firstFiling && t('firstFiling'),
        unitaryPatent && t('unitaryPatent'),
        fromPct && (t(type === "ep" ? 'forecast.pct-ep-route' : 'forecast.fromPct')),
        divisionalFiling && t('forecast.divisional'),
    ].filter(Boolean)
    return (
        <div className="inline-flex items-center gap-2 flex-wrap justify-end group hover:bg-pcx-100/50 px-4 py-2" {...{onMouseEnter, onMouseLeave}}>
            <div className="grow pr-2 text-pcx-400 group-hover:text-pcx-500 leading-tight inline-flex gap-2 items-start">
                {isEditUser ? <>
                    <input type="checkbox" className="form-checkbox text-pcx-300 mt-0.5"
                        checked={isSelectedScenarioMember(scenarioMemberId)}
                        onChange={e => setScenarioMemberSelected(scenarioMemberId, e.target.checked)}
                    />
                    <Link to={`member/${scenarioMemberId}`}>{title}</Link>
                </> 
                : title}
            </div>
            <div className='inline-flex flex-wrap justify-end items-center gap-2'>
                {ribbons.map(r => 
                    <span
                        key={r} 
                        className='text-sm text-pcx-600 group-hover:text-pcx-700 bg-pcx-100 group-hover:bg-pcx-200 border border-pcx-300 group-hover:border-pcx-400 px-1 rounded-sm whitespace-nowrap'>
                        {r}
                    </span>)}
                <span className="text-pcx-300 group-hover:text-pcx-400 tabular-nums whitespace-nowrap">{date}</span>
                {isEditUser && <div className='inline-flex gap-2 items-center'>
                    <Link to={`member/${scenarioMemberId}`} className="h-6 w-6 btn-primary p-0.5"><IconEdit /></Link>
                    <DeleteButton small del={() => deleteScenarioMember(scenarioMember)} />
                </div>}
            </div>
        </div>
    )
}

function ScenarioAmountRow({onMouseEnter, onMouseLeave, ...scenarioAmount}: {onMouseEnter?: () => void; onMouseLeave?: () => void} & ScenarioAmount) {
    const {isEditUser} = useRoles()
    const {scenarioAmountId, name, amount, currency, date} = scenarioAmount
    const {deleteScenarioAmount, setScenarioAmountSelected, isScenarioAmountSelected, } = useForecast()
    const format = new Intl.NumberFormat(undefined, {style: 'currency', currency})
    const title = <span>{name}: <span className="tabular-nums">{format.format(amount)}</span></span>
    return (
        <div className="inline-flex items-center gap-2 flex-wrap justify-end group hover:bg-pcx-100/50 px-4 py-2" {...{onMouseEnter, onMouseLeave}}>
            <div className='grow text-pcx-400 group-hover:text-pcx-500 inline-flex gap-2 items-center pr-2'>
                {isEditUser ? <>
                    <input type="checkbox" className="form-checkbox text-pcx-300"
                        checked={isScenarioAmountSelected(scenarioAmountId)}
                        onChange={e => setScenarioAmountSelected(scenarioAmountId, e.target.checked)}
                    />
                    <Link to={`amount/${scenarioAmountId}`}>{title}</Link>
                </>
                : title}
            </div>
            <span className="text-pcx-300 group-hover:text-pcx-400 tabular-nums whitespace-nowrap">{date}</span>
            <Link to={`amount/${scenarioAmountId}`} className="h-6 w-6 btn-primary p-0.5"><IconEdit /></Link>
            <DeleteButton small del={() => deleteScenarioAmount(scenarioAmount)} />
        </div>
    )
}

function ForecastTooltip({active, label, payload}) {
    const {t} = useTranslation()
    //console.log({payload, label})
    const {currency} = useForecast()
    const format = new Intl.NumberFormat(undefined, {style: 'currency', currency})
    if (active) {
        const non_zero = payload.filter(({value}) => value !== 0.0)
        return (
            <div className="bg-white p-2 shadow-md border-2 border-pcx-300">
                <p className='font-medium'>{label}</p>
                <table className='font-medium'>
                    <tbody>
                        {non_zero.map(({name, value, fill}, i) => 
                            <tr style={{color: fill}} key={i}>
                                <td className='pr-2'>{name}</td>
                                <td className='text-right tabular-nums'>{format.format(value)}</td>
                            </tr>)}
                        {non_zero.length > 1 && <tr className="border-t-2 border-slate-400">
                            <td className='pr-2'>{t('total')}</td>
                            <td className='text-right tabular-nums text-slate-600'>{format.format(_.sum(non_zero.map(v => v.value)))}</td>
                        </tr>}
                    </tbody>
                </table>
            </div>
        )
    } else
        return null
}

function reasonableMaxY(maxCosts?: number) {
    if (maxCosts === undefined) return 'auto'

    const ciphers = ('' + parseInt('' + maxCosts)).length

    const unit = Math.pow(10, ciphers - 2)

    //console.log({maxCosts, ciphers, unit})

    return parseInt((maxCosts + unit) / unit + '') * unit
}

export function CostChart({costs = [], scenarioCosts = [], midShade = false, maxCosts = undefined}) {
    //console.log({costs})
    const aggregated = 
        costs.length === 0 
        ? scenarioCosts.map(c => ({...c, scenario: c.cost, cost: 0}))
        :  costs.map(c => ({...c, scenario: scenarioCosts.find(s => s.year === c.year)?.cost}))

    const format = new Intl.NumberFormat()

    return (
        <ResponsiveContainer className='text-sm' width="100%" height={200}>
            <BarChart width={500} height={300} data={aggregated}>
                <XAxis dataKey="year" />
                <YAxis name="Costs" tickFormatter={(v) => format.format(v)} domain={[0, reasonableMaxY(maxCosts)]}/>
                <Bar name="Portfolio Costs" dataKey="cost" stackId="a" fill={midShade ? colors['pcx-400'] : colors['pcx-500']} />
                <Bar name="Scenario Costs" dataKey="scenario" stackId="a" fill={colors['pcx-300']} />
                <Tooltip 
                    cursor={false} wrapperStyle={{ outline: "none", background: colors['pcx-100'] }} 
                    // @ts-ignore 
                    content={<ForecastTooltip />} 
                />
            </BarChart>
        </ResponsiveContainer>
    )
}

// "ep" | "pct" | "national" | "ea"
export function AddEditScenarioMember() {
    const {t} = useTranslation()
    const navigate = useNavigate()
    const {addScenarioFamily, addScenarioMember, updateScenarioMember, scenarioFamilies, scenarioFamilyById, scenarioMembers, today} = useForecast()
    const {members, familyById} = usePatents()

    const {
        patentFamilyId: _patentFamilyId, 
        scenarioFamilyId: _scenarioFamilyId,
        scenarioMemberId: _scenarioMemberId,
    } = useParams()
    const [searchParams] = useSearchParams()

    const patentFamilyId = parseInt(_patentFamilyId)
    const scenarioFamilyId = _scenarioFamilyId 
        ? parseInt(_scenarioFamilyId) 
        : scenarioFamilies.find(s => s.patentFamilyId === patentFamilyId)?.scenarioFamilyId

    const prepareScenarioFamily = () => isFinite(scenarioFamilyId)
        ? Promise.resolve({ scenarioFamilyId })
        : addScenarioFamily({ patentFamilyId, selected: true } as ScenarioFamily)

    const familyName = familyById[patentFamilyId]?.internalReference ?? scenarioFamilyById[scenarioFamilyId]?.name
    const scenarioMemberId = parseInt(_scenarioMemberId)
    const scenarioMember = scenarioMembers.find(m => m.scenarioMemberId === scenarioMemberId)
    const scenarioFamilyMembers = scenarioMembers.filter(m => m.scenarioFamilyId === scenarioFamilyId)
    const hasPct = scenarioFamilyMembers.find(m => m.type === "pct") || members.find(m => m.patentFamilyId === patentFamilyId && m.countryCode === "WO")
    const isAdding = scenarioMember === undefined

    const firstFiling = searchParams.get('firstFiling') === 'true'

    //console.log({scenarioFamilyMembers, d: scenarioFamilyMembers.find(m => m.firstFiling)?.date})
    //console.log({today})

    const initialValues = 
        isAdding ? {
            type: "national",
            date: scenarioFamilyMembers.find(m => m.firstFiling)?.date ?? today.toISOString().slice(0, 10),
            selected: true,
            firstFiling,
            onlyPrioYear: false,
            divisionalFiling: false,
            fromPct: false,
            countries: [],
            unitaryPatent: false,
            ipType: 'patent',
        }
        : scenarioMember

    const doAdd = async (values: ScenarioMember) => {
        //console.log(state.scenarioFamilyId)
        return prepareScenarioFamily()
            .then(({ scenarioFamilyId }: { scenarioFamilyId?: number}) => 
                addScenarioMember({ ...values, scenarioFamilyId }))
            .then(() => navigate(".."))
    }
    const doUpdate = values => {
        updateScenarioMember(values).then(() => navigate(".."))
    }
    const forPct = () => false
    const forEa = (countryCode: string) => eaCountries.indexOf(countryCode) >= 0
    const forEp = (countryCode: string) => epCountries.indexOf(countryCode) >= 0
    const forNational = (countryCode: string) => countryCode !== "WO" && countryCode !== "EP" && countryCode !== "EA"
    const forFromPct = (countryCode: string) => forNational(countryCode) && countryCode !== "TW"
    const makeFilterCountry = ({type, fromPct}) =>
        type === "pct" ? forPct : 
        type === "ep" ? forEp : 
        type === "ea" ? forEa : 
        fromPct ? forFromPct :
        forNational
    
    const makeUnitaryPatent = ({type, unitaryPatent}) => type === "ep" && unitaryPatent
    const makeFromPct = ({type, fromPct}) => type !== "ea" && fromPct
    const toBackend = (values: ScenarioMember) => {
        const filterCountry = makeFilterCountry(values)
        const countries = values.countries.filter(filterCountry)
        return { ...values, countries, unitaryPatent: makeUnitaryPatent(values), fromPct: makeFromPct(values) }
    }

    function validate(values: ScenarioMember) {
        const errors = {}
        if (values.type === "national" && values.countries.length === 0) {
            errors["countries"] = t("forecast.no-countries-defined")
        }
        if (values.fromPct && !hasPct) {
            errors["fromPct"] = t("forecast.no-pct")
        }
        return errors
    }

    const filingProcedureOptions = [
        {value: "national", label: t('forecast.national-applications')},
        {value: "pct", label: "PCT"},
        {value: "ep", label: "EP"},
        {value: "ea", label: "EA"},
    ]

    return (
        <Modal escAction={() => navigate("..")}>
            <Formik 
                initialValues={initialValues}
                onSubmit={(values: ScenarioMember) => {
                    const allowed = toBackend(values)
                    return isAdding ? doAdd(allowed) : doUpdate(allowed)
                }}
                validate={validate}
            >{({values, handleChange, handleBlur, touched, errors }) => {
                const filterCountry = makeFilterCountry(values)
                const countriesDisabled = values.type === "pct"
                return <Form>
                    <div className="flex flex-col gap-2 sm:gap-4 p-4">
                        <h3>{familyName && familyName + ': '}{firstFiling ? t("forecast.define-first-filing") : isAdding ? t("forecast.add-filing") : t("forecast.edit-filing")}</h3>
                        
                        <label className="w-full">
                            <div className="label pb-1">{t('forecast.filing-procedure')}</div>
                            {isAdding 
                                ? <Field name="type" className="form-select w-full" as="select" autoFocus>
                                    {filingProcedureOptions.map(({ value, label }) => <option key={value} value={value}>{label}</option>)}
                                </Field>
                                : <div className="py-2 px-3 font-normal border max-w-sm">{filingProcedureOptions.find(o => o.value === values.type)?.label}</div>}
                        </label>

                        <div className='grid grid-cols-1 sm:grid-cols-2 gap-x-8 sm:gap-y-4 gap-2'>
                            <label className='label w-fit'> <Field name="firstFiling" className="form-checkbox mb-px mr-2" type="checkbox" />{t('firstFiling')}</label>

                            <label className='w-fit'> 
                                <Field name="unitaryPatent" 
                                    disabled={values.type !== "ep"} 
                                    className="form-checkbox mb-px mr-2 peer disabled:border-slate-300" 
                                    type="checkbox" 
                                    checked={makeUnitaryPatent(values)}
                                />
                                <span className="label inline peer-disabled:text-slate-300">{t('unitaryPatent')}</span>
                            </label>

                            <label className='label w-fit'> <Field name="onlyPrioYear" className="form-checkbox mb-px mr-2" type="checkbox" />{t('forecast.onlyPrioYear')}</label>

                            {/* TODO: enable and implement model
                            <label className='label w-fit'> <Field name="divisionalFiling" className="form-checkbox mb-px mr-2" type="checkbox" />{t('forecast.divisional')}</label>
                            */}

                            <label className='label w-fit'> 
                                <Field name="fromPct" 
                                    checked={makeFromPct(values)} 
                                    disabled={!hasPct}
                                    className="form-checkbox mb-px mr-2 peer disabled:border-slate-300" 
                                    type="checkbox" 
                                />
                                <span className='peer-disabled:text-slate-300'>
                                    {t(values.type === "ep" ? 'forecast.pct-ep-route' : 'forecast.fromPct')}
                                </span>
                            </label>
                        </div>

                        <label className='max-w-md'>
                            {countriesDisabled 
                                ? <>
                                    <div className="label normal-case text-slate-300 pb-0.5">{t("countries")}<br/>-</div>
                                </> 
                                : <>
                                    <div className="label pb-1 normal-case">{
                                        values.type === "pct" ? t("forecast.national-phase-countries") :
                                            values.type === "ep" ? t("forecast.validation-countries") :
                                                t("countries")
                                    }</div>
                                    <TagListField {...{
                                        name: "countries",
                                        tags: values.countries?.filter(filterCountry),
                                        //value: (values.countries ?? []).map(filterCountry),
                                        availableTags: countryCodes.filter(filterCountry),
                                        placeholder: t("countries"),
                                    }} />
                                </>}
                        </label>

                        <div className='grid grid-cols-1 sm:grid-cols-2 gap-x-8 sm:gap-y-4 gap-2'>
                            <label className="w-fit">
                                <div className="label pb-1">{t('applicationDate')}</div>
                                <Field name="date" className="form-input py-1.5" required as={DatePicker} />
                            </label>

                            <label className="w-fit">
                                <div className={`label pb-1 ${values.type !== "national" ? "text-slate-300" : ""}`}>{t('ipType')}</div>
                                <select 
                                    name="ipType" className="form-select disabled:text-slate-300 disabled:border-slate-300" required 
                                    value={values.type === "national" ? values.ipType : "patent"}
                                    disabled={values.type !== "national"}
                                    onChange={handleChange} onBlur={handleBlur}
                                >
                                    <option value="patent">{t('patent')}</option>
                                    <option value="utility-model">{t('utility-model')}</option>
                                </select>
                            </label>
                        </div>
                        {['fromPct', 'countries'].map(k => touched[k] && errors[k] && 
                            <div key={k} className="text-red-800 bg-red-50 border-l-2 border-red-600 text-sm p-4">
                                <ExclamationTriangleIcon className='inline-block h-6 w-6 mr-2' />{errors[k]}
                            </div>)}
                    </div>
                    <div className="bg-pcx-200 p-4 flex flex-row-reverse gap-4">
                        <input type="submit" value={t("save")} className="btn-primary" />
                        <Link to=".." className="btn-secondary">{t('cancel')}</Link>
                    </div>
                </Form>
            }}</Formik>
        </Modal>
    )
}

export function AddEditScenarioFamily() {
    const {t} = useTranslation()
    const navigate = useNavigate()
    const {addScenarioFamily, updateScenarioFamily, scenarioFamilies} = useForecast()
    const {scenarioFamilyId: _scenarioFamilyId} = useParams()
    const scenarioFamilyId = parseInt(_scenarioFamilyId)

    const scenarioFamily = scenarioFamilies.find(f => f.scenarioFamilyId === scenarioFamilyId)
    //console.log({scenarioFamilyId, scenarioFamily})
    const isAdding = scenarioFamily === undefined
    const initialValues = isAdding ? { name: "", selected: true } : scenarioFamily
    return (
        <Modal escAction={() => navigate("..")}>
            <Formik 
                initialValues={initialValues}
                onSubmit={(family: ScenarioFamily) => 
                    isAdding ? addScenarioFamily(family)
                        .then(({scenarioFamilyId}) => 
                            // after adding move straight to adding new members
                            navigate(`../scenario-family/${scenarioFamilyId}/member?firstFiling=true`))
                    : updateScenarioFamily(family).then(() => navigate(".."))
                }
                validate={(values) => {
                    const errors = {}
                    // TODO: add validations (positive count and existing name)
                    return errors
                }}
            >
                <Form>
                    <div className="p-4 flex flex-col gap-2">
                        <h3>{isAdding ? t('forecast.add-scenario-family') : t('forecast.edit-scenario-family')}</h3>
                        <label>
                            <div className="label pb-1">{t('Name')}</div>
                            <Field name="name" className="form-input" type="text" autoFocus required />
                        </label>
                    </div>

                    <div className="p-4 flex flex-row-reverse gap-4 bg-pcx-200">
                        <input type="submit" value={t("save")} className="btn-primary" />
                        <Link to=".." className="btn-secondary">{t('cancel')}</Link>
                    </div>
                </Form>
            </Formik>
        </Modal>
    )
}

export function CopyScenarioFamily() {
    const {t} = useTranslation()
    const navigate = useNavigate()
    const {addScenarioFamily, scenarioFamilies, addScenarioMember, scenarioMembers, addScenarioAmount, scenarioAmounts} = useForecast()
    const {scenarioFamilyId: _scenarioFamilyId} = useParams()
    const copyScenarioFamilyId = parseInt(_scenarioFamilyId)

    const scenarioFamily = scenarioFamilies.find(f => f.scenarioFamilyId === copyScenarioFamilyId)
    const name = scenarioFamily?.name
    const members = scenarioMembers.filter(m => m.scenarioFamilyId === copyScenarioFamilyId)
    const amounts = scenarioAmounts.filter(a => a.scenarioFamilyId === copyScenarioFamilyId)
    return (
        <Modal>
            <Formik 
                initialValues={{name: name ? t('forecast.copy-of', {name}) : '', selected: true}} 
                enableReinitialize
                onSubmit={(family: ScenarioFamilyInput) => addScenarioFamily({...family, scenarioFamilyId: undefined})
                    .then(({ scenarioFamilyId }) =>
                        Promise.all([
                            ...members.map(m => addScenarioMember({ ...m, scenarioMemberId: undefined, scenarioFamilyId })),
                            ...amounts.map(a => addScenarioAmount({ ...a, scenarioAmountId: undefined, scenarioFamilyId }))
                        ]).then(() => navigate(`../../scenario-family/${scenarioFamilyId}`))
                    )}
            >
                <Form>
                    <div className="p-4 flex flex-col gap-2">
                        <h3>{t('forecast.copy-family', {name})}</h3>
                        <label>
                            <div className="label pb-1">{t('name')}</div>
                            <Field name="name" className="form-input" type="text" autoFocus required />
                        </label>
                    </div>
                    <div className="p-4 flex flex-row-reverse gap-4 bg-pcx-200">
                        <input type="submit" value={t("copy")} className="btn-primary" />
                        <Link to=".." className="btn-secondary">{t('cancel')}</Link>
                    </div>
                </Form>
            </Formik>
        </Modal>
    )
}

export function ScenarioFamilyView() {
    const {isEditUser} = useRoles()
    const navigate = useNavigate()
    const {fxConverter} = useFxContext()
    const {
        today, currency,
        scenarioFamilies, scenarioAmounts,
        scenarioAmountModels, scenarioFamilyModels, 
        deleteScenarioFamily, membersByScenarioFamily, 
        isSelectedPureScenarioFamily, setScenarioFamilySelected
    } = useForecast()

    const [hoveredScenario, setHoveredScenario] = useState(undefined)
    const [hoveredAmount, setHoveredAmount] = useState(undefined)

    const {scenarioFamilyId: _scenarioFamilyId} = useParams()
    const scenarioFamilyId = parseInt(_scenarioFamilyId)
    const family = scenarioFamilies.find(f => f.scenarioFamilyId === scenarioFamilyId)

    if (family === undefined) return null

    const {name} = family
    const amounts = scenarioAmounts.filter(a => a.scenarioFamilyId === scenarioFamilyId)

    const scenarioModels = scenarioFamilyModels[scenarioFamilyId] ?? []
    const amountModels = scenarioAmountModels[scenarioFamilyId] ?? []
    const scenarioMembers = _(membersByScenarioFamily[scenarioFamilyId] ?? [])
        .filter(m => m.scenarioMemberId !== undefined)
        .sortBy(m => `${m.date} ${m.scenarioMemberId}`)
        .value()
    const scenarioCosts = sumModels({models: [...scenarioModels, ...amountModels], today})

    //console.log(priority)
    //console.log({membersByScenarioFamily, scenarioModels, scenarioMembers, scenarioCosts})

    //console.log({hoveredScenario, hoveredAmount})
    let hoveredModel = undefined
    if (hoveredScenario >= 0) {
        const hoveredScenarioMember = scenarioMembers.find(m => m.scenarioMemberId === hoveredScenario)
        const priorityDate = scenarioMembers[0]?.date
        const monthly =  scenarioModel({scenarioMember: hoveredScenarioMember, today, currency, fxConverter, priorityDate})
        hoveredModel = sumModels({models: [monthly], today})
    } else if (hoveredAmount >= 0) {
        const scenarioAmount = amounts.find(a => a.scenarioAmountId === hoveredAmount)
        const monthly = amountModel({scenarioAmount, today, currency, fxConverter})
        hoveredModel = sumModels({models: [monthly], today})
    }

    //const maxCosts = scenarioCosts.reduce((max, {cost}) => Math.max(max, cost), 0)
    let maxCosts = undefined
    if (hoveredScenario >= 0 || hoveredAmount >= 0) {
        maxCosts = _([...scenarioModels, ...amountModels])
            .map(model => _(sumModels({models: [model], today})).map(({cost}) => cost).max())
            .max()
    }

    return (
        <div className='grow max-w-full rounded-md shadow-sm bg-white self-start sm:max-h-full sm:overflow-y-auto'>
            <div className="p-4 flex flex-row items-center gap-2 max-w-full">
                {isEditUser && 
                    <input
                        className="form-checkbox w-4 h-4 text-pcx-300" type="checkbox"
                        checked={isSelectedPureScenarioFamily(scenarioFamilyId)}
                        onChange={e => setScenarioFamilySelected(scenarioFamilyId, e.target.checked)} />}
                <h2 className="text-pcx-300 font-normal min-w-0  focus:outline-none truncate text-ellipsis grow">
                    {name}
                </h2>
                {isEditUser && <>
                    <Link to="edit" className="h-6 w-6 btn-primary p-0.5"><IconEdit /></Link>
                    <DeleteButton small del={() => deleteScenarioFamily(family).then(() => navigate('..'))} />
                </>}
                <Link to=".."><IconX/></Link>
            </div>
            <div className="px-4 max-w-sm sm:min-w-xs py-4">
                {hoveredModel 
                    ? <CostChart {...{ maxCosts, costs: hoveredModel }} midShade />
                    : <CostChart {...{ maxCosts, scenarioCosts }} /> }
            </div>
            <div key={`${scenarioMembers.length}-${scenarioAmounts.length}` /* Make sure we don't have double rows from the optimistic uploading */} className="flex flex-col pb-4">
                {scenarioMembers.map(m => 
                    <ScenarioMemberRow key={`sm-${m.scenarioMemberId ?? -1}`} {...m} 
                        onMouseEnter={() => setHoveredScenario(m.scenarioMemberId)} 
                        onMouseLeave={() => setHoveredScenario((hs?: number) => hs === m.scenarioMemberId ? undefined : hs)} 
                    />)}
                {amounts.map(a => 
                    <ScenarioAmountRow key={`sa-${a.scenarioAmountId ?? -1}`} {...a} 
                        onMouseEnter={() => setHoveredAmount(a.scenarioAmountId)} 
                        onMouseLeave={() => setHoveredAmount((hs?: number) => hs === a.scenarioAmountId ? undefined : hs)} 
                    />)}
            </div>
            {isEditUser && <div className='p-4 flex flex-row flex-wrap gap-2 '>
                <AddScenarioMemberLink />
                <AddScenarioAmountLink />
            </div>}
            <Outlet />
        </div>
    )
}


export function AddEditScenarioAmount() {
    const {t} = useTranslation()
    const navigate = useNavigate()
    const {today, currency, addScenarioAmount, updateScenarioAmount, scenarioAmounts, scenarioFamilies, addScenarioFamily} = useForecast()
    const {
        patentFamilyId: _patentFamilyId, 
        scenarioFamilyId: _scenarioFamilyId,
        scenarioAmountId: _scenarioAmountId
    } = useParams()
    const scenarioAmountId = parseInt(_scenarioAmountId)

    const patentFamilyId = parseInt(_patentFamilyId)
    const scenarioFamilyId = _scenarioFamilyId ? parseInt(_scenarioFamilyId) : scenarioFamilies.find(s => s.patentFamilyId === patentFamilyId)?.scenarioFamilyId
    const prepareScenarioFamily = () => isFinite(scenarioFamilyId)
        ? Promise.resolve({ scenarioFamilyId })
        : addScenarioFamily({ patentFamilyId, selected: true })

    const scenarioAmount = scenarioAmounts.find(f => f.scenarioAmountId === scenarioAmountId)
    //console.log({scenarioAmountId, scenarioAmount})
    const isAdding = scenarioAmount === undefined
    const initialValues = isAdding ? { 
        name: "", 
        amount: 1000, 
        currency,
        date: today.toISOString().substring(0, 10),
        selected: true,
    } : scenarioAmount

    // {scenarioId, scenarioFamilyId, scenarioAmountId, name, amount, date, selected}
    return (
        <Modal escAction={() => navigate("..")}>
            <Formik 
                initialValues={initialValues} 
                onSubmit={(values: ScenarioAmount) => 
                    isAdding
                        ? prepareScenarioFamily()
                            .then(({scenarioFamilyId}: {scenarioFamilyId?: number}) => addScenarioAmount({scenarioFamilyId, ...values})
                            .then(() => navigate("..")))
                        : updateScenarioAmount(values)
                            .then(() => navigate(".."))
                }>
                <Form>
                    <div className='p-4 flex flex-col gap-4'>
                        <h4>{isAdding ? t('forecast.add-amount') : t('forecast.edit-amount')}</h4>
                        <label>
                            <div className="label mb-1">{t('forecast.description')}</div>
                            <Field name="name" className="form-input" type="text" autoFocus required />
                        </label>
                        <div className="flex flex-row gap-2">
                            <label>
                                <div className="label mb-1">{t('forecast.amount')}</div>
                                <Field name="amount" className="form-input w-32 tabular-nums" type="number" required />
                            </label>
                            <label>
                                <div className="label mb-1">{t('currency')}</div>
                                <Field name="currency" className="form-select" as="select" required>
                                    {supportedCurrencies.map(c => <option key={c} value={c}>{c}</option>)}
                                </Field>
                            </label>
                        </div>
                        <label className='w-fit'>
                            <div className="label mb-1">{t('forecast.date')}</div>
                            <Field name="date" className="form-input" as={DatePicker} required />
                        </label>
                    </div>
                    <div className='p-4 bg-pcx-200 flex flex-row-reverse gap-4'>
                        <input type="submit" value={t("save")} className="btn-primary" />
                        <Link className='btn-secondary' to="..">{t('cancel')}</Link>
                    </div>
                </Form>
            </Formik>
        </Modal>
    )
}
