import _ from "lodash"
import React, { useMemo, useContext, createContext } from "react"
import { Link } from "react-router-dom"
import { Helmet } from 'react-helmet-async'
import { useTranslation, Trans } from "react-i18next"

import { useCosts } from "../../costs/CostsProvider"
import { IconArrowUpDown, IconChevronDown, IconChevronUp } from "../../components/icons"
import { useBackend } from "../../BackendProvider"
import { Invention } from "../../inventions/InventionsProvider"
import { inventionUrl } from "../../inventions/utils"
import { familyUrl, memberUrl } from "../utils"
import { downloadReport } from "../../backend"
import { family_member, patent_family } from "../../data"
import { calculatePriorityDate } from "../utils"
import { useMessages } from "../../Messages"
import { useRoles } from "../../user/Auth"
import { PlainImage } from "../../components/Image"
import { useComments } from "../../comments/CommentsProvider"
import { useValuations } from "../../valuations/ValuationsProvider"
import { calcScore } from "../../valuations/Valuations"
import TagList from "../../components/TagList"
import { Family, Member } from "../patents"
import { AgentLink, Agent } from "../../agents/utils"
import { Commodity, commodityReferenceUrl } from '../../products/products'
import { IpRight, PageResponse, PcCostCenter, PcCostCenterLink, PcInstruction, PcIpRight, useDennemeyer, useDmSettings, useFullIpRightsPortfolio, useMaintenances, useTimeline, ValidationMessage } from "../../renewal/DennemeyerProvider"
import { useFilteredPatents } from "../../filter/FilteredPatents"
import { AugmentedMaintenanceAction, calculateNextDay, eliminateDoubleMaintenanceActions, useAugmentMaintenanceAction } from "../../renewal/utils"
import { isImported, maintenanceActionStatusToString, maintenanceActionsStatus, renewalStatusExtraction, renewalStatusToString } from '../../renewal/modern_states'
import { useSingleTeamMangement } from "../../Management"
import { useFxContext } from "../../forecast/FxProvider"
import { accumulateCosts } from "../../costs/utils"
import { usePatents } from "../PatentsProvider"
import { useProducts } from "../../products/ProductsProvider"
import { DueDateStatus } from "../../renewal/settings/instruction_timeline"
import { RowsProvider, useGroupableFields, useRowsProvider } from "./Rows"
import { QueriesProvider, QueryDeleteModal, QueryEditorModal, QueryMenu, QueryOpenModal, useQueriesProvider } from "./Queries"
import { useAgents } from "../../agents/AgentsProvider"
import { useTags } from "../../tags/TagsProvider"
import { InfiniteData } from "@tanstack/react-query"
import { Popover, PopoverButton, PopoverPanel } from "@headlessui/react"
import { useFilter } from "../../filter/Filter"
import { useClaims } from "../../claims/ClaimsProvider"

// agents: {['(p|c)-$agentId]}
function getAgent(agentLinks: AgentLink[], agents: Record<string, Agent>, type: string) {

    const agentsLookup = agentLinks.reduce((acc, l) => {
        if (l.linkType === type) {
            const key = `${l.agentType === "person" ? "p" : "c"}-${l.agentId}`
            const agent = agents[key]
            return agent ? {...acc, [l.familyMemberId]: [...(acc[l.familyMemberId] ?? []), agent]} : acc
        } else {
            return acc
        }
    }, {})

    return (member) => {
        return agentsLookup[member.familyMemberId]
    }
}


export interface SortButtonProps {
    searchField: string; 
    sortField: string; 
    sortOrder: number; 
    setSortField: (field: string | ((field: string) => string)) => void; 
    setSortOrder: (order: number | ((order: number) => number)) => void
}

function SortButtonInContext({searchField}: {searchField: string}) {
    const {sortField, sortOrder, setSortField, setSortOrder} = useRowsProvider()
    return <SortButton {...{searchField, sortField, sortOrder, setSortField, setSortOrder}} />
}

export function SortButton({searchField, sortField, sortOrder, setSortField, setSortOrder}: SortButtonProps) {
    const isSorting = searchField === sortField
    const buttonElement =
        (searchField === sortField)
            ? sortOrder === 1 
                ? <IconChevronDown /> 
                : <IconChevronUp />
            : <IconArrowUpDown />
    return (
        <button className={isSorting ? "text-pcx-800" : "text-pcx-800/50"} onClick={() => {
            if (isSorting) {
                setSortOrder(s => s * -1)
            } else {
                setSortField(searchField)
                setSortOrder(+1)
            }
        }}>
            {buttonElement}
        </button>
    )
}

export default function DataWarehouse() {
    // TODO: Move pre-calculated data to a provider on the *TOP* level
    return (
        <DataWareHouseDataProvider>
            <RowsProvider>
                <QueriesProvider>
                    <DataWarehouseUI />
                </QueriesProvider>
            </RowsProvider>
        </DataWareHouseDataProvider>
    )
}

const maxFamilies = 150

const DataWareHouseData = createContext({
        maintenanceActionByMemberId: {} as Record<number, AugmentedMaintenanceAction>,
        augmentedIpRightByMemberId: {} as Record<number, IpRight & {member: Member, status?: DueDateStatus, instructionDueDate?: string}>,
        isLoadingAnnuity: false,
        progressCount: {current: 0, total: 0},
        scoresById: {} as Record<number, number>,
        agents: {} as Record<string, Agent>,
        agentLinks: [] as AgentLink[],
        tagsById: [] as Record<number, string[]>,
        claimScopesByMember: {} as Record<number, number[]>,
        membersPriorityDates: {} as Record<number, {date: string, internalReference: string, pctRouteFiling: boolean}>,
        get_comments: (entity: string, entityId: number) => ([] as string[]) ,
        get_last_comment: (entity: string, entityId: number) => ('' as string),
        dwFields: {} as Record<string, Record<string, (f: Member | Family | Invention | Commodity, context?: AnnuityDataCalculationContext) => any>>,
        searchableFields: {} as Record<string, (f: Member | Family | Invention | Commodity, context?: AnnuityDataCalculationContext) => any>,
        displayFields: {} as Record<string, string>,
})

interface AnnuityDataCalculationContext {
    maintenanceActionByMemberId: Record<number, AugmentedMaintenanceAction>,
    //augmentedIpRightByMemberId: Record<number, IpRight & { member: Member, status?: DueDateStatus, instructionDueDate?: string }>,
    isLoadingAnnuity: boolean,
    //progressCount: { current: number, total: number },
    loadingString: string,
    pcIpRightByMemberId: Record<number, PcIpRight>,
    ipRightByMemberId: Record<number, IpRight & {member: Member, status?: DueDateStatus, instructionDueDate?: string}>,
    validationsByIpRightId: Record<number, ValidationMessage[]>,
    calculateDueDates: (dueDate: string) => {status: DueDateStatus, instructionDueDate: string},
    instructionByDennemeyerId: Record<string, PcInstruction>
    costCentersByIpRightId: Record<number, (PcCostCenter & PcCostCenterLink)[]>,
}

function DataWareHouseDataProvider({children}) {
    //console.log('recalculating DP')
    const {t} = useTranslation()
    const {hasAnnuities, hasClaimScopes, hasCosts, hasInnovation, hasAbandonmentDate} = useRoles()
    const {team} = useSingleTeamMangement()

    const currency = team?.currency ?? 'EUR'
    const {fxConverter} = useFxContext()
    const groupableFields = useGroupableFields()

    const { claims } = useClaims()
    const { tagsLookup } = useTags()
    const { agentLinks: _agentLinks, agents: _agents } = useAgents()
    const { membersByFamilyId: allMembersByFamilyId } = usePatents()
    const { families: _families, members: _members, familyById } = useFilteredPatents()
    const { commentsLookUp } = useComments()
    const { costsByMemberId } = useCosts()

    const { scoresLookup } = useValuations()
    const scoresById = _.mapValues(scoresLookup, ss => {
        const score = calcScore(ss)
        return score
    })

    /// Limit the number of families to be processed
    // TODO: limiting the numbers of families in useRow
    const families = _.sortBy(_families.slice(0, maxFamilies), f => f.internalReference)
    const familyIds = new Set(families.map(f => f.patentFamilyId))
    const members = _members.filter(m => familyIds.has(m.patentFamilyId))

    const relevantMembers = new Set(members.map(m => m.familyMemberId))
    const agentLinks = _agentLinks.filter(l => relevantMembers.has(l.familyMemberId))

    const tagsById = tagsLookup[patent_family] ?? []

    const agents = Object.fromEntries(_.map(_agents, a => {
        if (a.agentType === 'person')
            return [`p-${a.agentId}`, `${a.lastName}, ${a.firstName}`]
        else if (a.agentType === 'company')
            return [`c-${a.agentId}`, `${a.name}`]
        else {
            console.warn("Cannot handle agent type " + a.agentType)
            return []
        }
    }))
    
    const claimScopesByMember = claims
        .filter(c => relevantMembers.has(c.familyMemberId) && c.claimScopeId)
        .reduce((acc, cur) => {
            if (cur.claimScopeId)
                return { ...acc, [cur.familyMemberId]: _.sortBy([...(acc[cur.familyMemberId] ?? []), cur.claimScopeId]) }
            else
                return acc
        }, {})

    const membersPriorityDates = useMemo(
      () => {
        //console.log("Calculating the priority dates")
        return _.mapValues(allMembersByFamilyId, members => calculatePriorityDate(members))
      },
      [allMembersByFamilyId],
    )

    function get_comments(entity: string, entityId: number) {
        return commentsLookUp[entity]?.[entityId]?.map(c => c.comment).filter(c => c && c.trim() !== '') ?? []
    }
    function get_last_comment(entity: string, entityId: number) {
        return commentsLookUp[entity]?.[entityId]?.[0]?.comment ?? ''
    }

    const yes = t('yes')
    const no = t('no')

    function onlyDefinedBoolean(b?: boolean) {
        return b === undefined ? [] : [b ? yes : no]
    }

    const dwFields = {
        family: {
            dw_image: (f: Family) => f.patentFamilyId,
            comments: (f: Family) => get_comments(patent_family, f.patentFamilyId),
            lastComment: (f: Family) => get_last_comment(patent_family, f.patentFamilyId),
            patentFamilyReference: (f: Family) => f.internalReference,
            patentFamilyExtReference: (f: Family) => f.externalReference ?? '',
            familyName: (f: Family) => [f.familyName],
            patentFamilySummary: (f: Family) => f.summary ?? '',
            priorityDate: (f: Family) => familyById[f.patentFamilyId]?.priorityDate ?? membersPriorityDates[f.patentFamilyId]?.date ?? '',
            tags: (f: Family) => tagsById[f.patentFamilyId],
        },
        'family-member': {
            applicant: getAgent(agentLinks, agents, "applicant"),
            applicationDate: (m: Member) => [m.applicationDate],
            applicationNumber: (m: Member) => [m.applicationNumber],
            dw_claimScope: hasClaimScopes ? (m: Member) => claimScopesByMember[m.familyMemberId] ?? [] : undefined,
            countryCode: (m: Member) => [m.countryCode],
            familyMemberStatus: (m: Member) => [t([m.familyMemberStatus])],
            firstFiling: (m: Member) => onlyDefinedBoolean(m.firstFiling),
            familyMemberReference: (m: Member) => [m.internalReference],
            familyMemberExtReference: (m: Member) => [m.externalReference],
            inventor: getAgent(agentLinks, agents, "inventor"),
            ipType: (m: Member) => [t([m.ipType])],
            numberClaims: (m: Member) => [m.numberClaims],
            costs: !hasCosts ? undefined : ((m: Member) => accumulateCosts(costsByMemberId[m.familyMemberId] ?? [], {fxConverter, inclVat: false, currency}).total),
            owner: getAgent(agentLinks, agents, "owner"),
            optOut: (m: Member) => optOutMeaningful(m) ? onlyDefinedBoolean(m.optOut) : [],
            patentDate: (m: Member) => [m.patentDate],
            patentNumber: (m: Member) => [m.patentNumber],
            patentOfficeLink: (m: Member) => [m.patentOfficeLink],
            pctRouteFiling: (m: Member) => onlyDefinedBoolean(m.pctRouteFiling),
            expiryDate: (m: Member) => [m.expiryDate],
            abandonmentDate: hasAbandonmentDate ? ((m: Member) => [m.abandonmentDate]) : undefined,
            publicationDate: (m: Member) => [m.publicationDate],
            publicationNumber: (m: Member) => [m.publicationNumber],
            patentComments: (m: Member) => get_comments(family_member, m.familyMemberId),
            lastPatentComment: (m: Member) => get_last_comment(family_member, m.familyMemberId),
            dw_score: (m: Member) => [scoresById[m.familyMemberId]],
            title: (m: Member) => [m.title],
            unitaryPatent: (m: Member) => m.countryCode === 'EP' ? onlyDefinedBoolean(m.unitaryPatent) : [],
            validated: (m: Member) => onlyDefinedBoolean(m.validated),
        },
        'annuities': hasAnnuities && { 
            'renewals-status': (member: Member, {isLoadingAnnuity, loadingString, pcIpRightByMemberId, ipRightByMemberId, validationsByIpRightId}: AnnuityDataCalculationContext) => {
                // TODO: do loading check in render cell
                if (isLoadingAnnuity) return loadingString
                const pcIpRight = pcIpRightByMemberId[member.familyMemberId]
                const ipRight = ipRightByMemberId[member.familyMemberId]
                const status = renewalStatusExtraction({member, pcIpRight, ipRight, validationErrors: validationsByIpRightId[pcIpRight?.ipRightId]})
                return renewalStatusToString(status, t)
            },
            dm_validation_errors: (m: Member, {pcIpRightByMemberId, validationsByIpRightId}: AnnuityDataCalculationContext) => {
                const pcIpRight = pcIpRightByMemberId[m.familyMemberId]
                const messages = validationsByIpRightId[pcIpRight?.ipRightId] ?? []
                return messages.map(m =>  m.message).join("\n")
            },
            'last-instruction': (
                member: Member,
                { isLoadingAnnuity, loadingString, pcIpRightByMemberId, ipRightByMemberId, validationsByIpRightId, calculateDueDates, instructionByDennemeyerId, maintenanceActionByMemberId }: AnnuityDataCalculationContext
            ) => {
                if (isLoadingAnnuity) return loadingString
                const pcIpRight = pcIpRightByMemberId[member.familyMemberId]
                const ipRight = ipRightByMemberId[member.familyMemberId]
                const renewalStatus = renewalStatusExtraction({member, pcIpRight, ipRight, validationErrors: validationsByIpRightId[pcIpRight?.ipRightId]})
                // console.log(member.internalReference, {pcIpRight, ipRight, renewalStatus})
                if (isImported(renewalStatus)) {
                    const maintenanceActions = [maintenanceActionByMemberId[member.familyMemberId]].filter(Boolean)
                    const maintenanceActionHistory = maintenanceActionsStatus({ renewalStatus: renewalStatus.status, maintenanceActions, calculateDueDates, instructionByDennemeyerId })
                    // console.log({maintenanceActionHistory, maintenanceActions})
                    // if (renewalStatus.status !== 'not-handled') {
                        return maintenanceActionStatusToString(maintenanceActionHistory, t)
                    // }
                }
                return ''
            },
            'fee-due': (m: Member, {maintenanceActionByMemberId}: AnnuityDataCalculationContext) => {
                return maintenanceActionByMemberId[m.familyMemberId]?.DueDate ?? ''
            },
            'instruction-due': (m: Member, { calculateDueDates, instructionByDennemeyerId, maintenanceActionByMemberId }: AnnuityDataCalculationContext) => {
                const action = calculateNextDay(
                    [maintenanceActionByMemberId[m.familyMemberId]].filter(Boolean), 
                    calculateDueDates,
                    instructionByDennemeyerId,
                )
                return action?.openAction?.instructionDueDate ?? ''
            },
            fees: (m: Member, {maintenanceActionByMemberId}: AnnuityDataCalculationContext) => {
                return maintenanceActionByMemberId[m.familyMemberId]?.fees ?? {}
            },
            'cost-centers': (m: Member, {costCentersByIpRightId, pcIpRightByMemberId}: AnnuityDataCalculationContext) => 
                (costCentersByIpRightId[pcIpRightByMemberId[m.familyMemberId]?.ipRightId] ?? [])
                    .map(cc => cc.percentage === 100 ? cc.name : `${cc.name} (${cc.percentage}%)`)
                    .join(', '),
        },
        'products': {
            commodityReference: (c: Commodity) => c.commodityReference,
            commodityClass: (c: Commodity) => c.commodityClass,
            commodityDescription: (c: Commodity) => c.commodityDescription,
            isThirdParty: (c: Commodity) => c.isThirdParty ? yes : no,
            productImage: (c: Commodity) => c.commodityId,
        },
        'inventions': hasInnovation && {
            inventionReference: (i: Invention) => i.reference,
            inventionReferenceDate: (i: Invention) => i.referenceDate,
            inventionTitle: (i: Invention) => i.title,
            inventionSummary: (i: Invention) => i.summary,
        }
    }

    const searchableFields = _(dwFields).toPairs()
        .filter(([group, fields]) => typeof fields === 'object')
        .flatMap(([group, fields]) => _(fields).toPairs().filter(([field, fct]) => fct !== undefined).value())
        .fromPairs()
        .value()

    const displayFields = Object.fromEntries([
        ...groupableFields,
        ..._(searchableFields).toPairs().map(([group, fields]) => [group, ..._.keys(fields)]).value()
    ].map(f => [f, t(f)]))

    //console.log({displayFields})

    const value = {
        maintenanceActionByMemberId: {},
        augmentedIpRightByMemberId: {},
        isLoadingAnnuity: false,
        progressCount: {current: 0, total: 0},
        scoresById,
        agents,
        agentLinks,
        tagsById,
        claimScopesByMember,
        membersPriorityDates,
        get_comments,
        get_last_comment,
        dwFields,
        searchableFields,
        displayFields,
    }

    if (hasAnnuities)
        return (
            <DataWareHouseData.Provider {...{ value }}>
                <AnnuityDataProvider>
                    {children}
                </AnnuityDataProvider>
            </DataWareHouseData.Provider>
        )
    else 
        return (
            <DataWareHouseData.Provider {...{ value }}>
                {children}
            </DataWareHouseData.Provider>
        )
}

function DataWarehouseSettings() {
    const {dwFields, displayFields} = useContext(DataWareHouseData)
    const {t} = useTranslation()
    const {groupBy, setGroupBy, searchFields, setSearchFields} = useRowsProvider()
    const availableTags = _(dwFields)
        .toPairs()
        .map(([k, vs]) => [t(k), _(vs).keys().filter(v => vs[v] !== undefined && displayFields[v] !== undefined).sortBy(v => displayFields[v] ?? v).value()])
        .fromPairs()
        .value()

    //console.log({availableTags, displayFields})

    return <RenderedDatawarehouseSettings {...{groupBy, setGroupBy, availableTags, searchFields, setSearchFields, displayFields}} />
}

const RenderedDatawarehouseSettings = React.memo((
    {groupBy, setGroupBy, availableTags, searchFields, setSearchFields, displayFields}: 
    {groupBy: string, setGroupBy: (s: string) => void, availableTags: Record<string, string[]>, searchFields: string[], setSearchFields: (ss: string[]) => void, displayFields: Record<string, string>}) => {

    const {t} = useTranslation()

    const groupableFields = useGroupableFields()

    const inputGroupStyle="flex flex-row items-center gap-2 max-w-3xl"
    const labelStyle="w-24 after:content-[':'] shrink-0"

    //console.log('rerender settings')

    return (
        <div className="flex flex-col gap-2">
            <div className={inputGroupStyle}>
                <label className={labelStyle}>{t('grouping')}</label>
                <select
                    className="form-select text-sm w-40 h-7 py-px px-2"
                    value={groupBy}
                    onChange={(e) => {
                        const gb = e.target.value
                        setGroupBy(gb)
                    }}>
                    {groupableFields.map(f => <option key={f} value={f}>{t([f])}</option>)}
                </select>
            </div>

            <div className={inputGroupStyle}>
                <label className={labelStyle}>{t('fields')}</label>
                <TagList {...{
                    name: "searchFields",
                    dragable: true,
                    // TODO: check if we have to sort out undefined functions
                    availableTags,
                    tags: searchFields,
                    setTags: setSearchFields,
                    tagDisplays: displayFields,
                    placeholder: t('field'),
                }} />
            </div>
        </div>
    )
}, _.isEqual)

function AnnuityDataProvider({children}) {
    //console.log('recalculating ADP')
    const context = useContext(DataWareHouseData)

    //const [maintenanceActionByMemberId, setMaintenanceActionByMemberId] = useState({})
    //const [augmentedIpRightByMemberId, setAugmentedIpRightByMemberId] = useState({})

    const { settings } = useDmSettings()
    const startDate = settings?.startDate
    const { ipRightByDennemeyerId, } = useDennemeyer()
    const {showActiveOnly} = useFilter()
    const { augmentMaintenanceAction, augmentIpRight } = useAugmentMaintenanceAction(!showActiveOnly)

    const {data: maintenanceActions, fetchNextPage, hasNextPage, isFetching: isFetchingMaintenances} = useMaintenances({onlyInstructable: false, onlyOpen: false})
    if (hasNextPage) {
        fetchNextPage()
    }

    const { data: ipRights, fetchNextPage: fetchNextIpRightsPage, hasNextPage: hasNextIpRightsPage, isFetching: isFetchingPortfolio } = useFullIpRightsPortfolio()
    if (hasNextIpRightsPage) {
        //console.log('fetching next page')
        fetchNextIpRightsPage()
    }

    //console.log({hasNextIpRightsPage, hasNextPage})
    //const isLoadingAnnuity = isLoadingMaintenances || isLoadingPortfolio
    const isLoadingAnnuity = isFetchingMaintenances || isFetchingPortfolio
    //console.log({isLoadingAnnuity, l: maintenanceActions?.pages?.flatMap(p => p?.Data?.Page ?? []).length, r: ipRights?.pages?.flatMap(p => p?.Data?.Page ?? []).length})

    const augmentedIpRightByMemberId = _(ipRights?.pages?.flatMap(p => p?.Data?.Page ?? []))
        .filter(ipr => ipr.DennemeyerId in ipRightByDennemeyerId)
        .map(augmentIpRight)
        .filter(ipr => ipr?.member?.familyMemberId !== undefined)
        .keyBy(ipr => ipr.member.familyMemberId)
        .value()
    // console.log({augmentedIpRightByMemberId})

    const maintenanceActionByMemberId = _(maintenanceActions?.pages?.flatMap(p => p?.Data?.Page ?? []))
        .map(augmentMaintenanceAction)
        .filter(m => m.member?.familyMemberId !== undefined && (startDate === undefined || m.instructionDueDate >= startDate))
        .groupBy(m => m.member?.familyMemberId)
        .mapValues(ms => _(eliminateDoubleMaintenanceActions(ms)).values().maxBy(m => m.instructionDueDate))
        .value()
    // console.log({maintenanceActionByMemberId})

    const ipRightsCount = progressCount(ipRights)
    const maintenanceActionsCount = progressCount(maintenanceActions)

    const finalProgressCount = {current: ipRightsCount.current + maintenanceActionsCount.current, total: ipRightsCount.total + maintenanceActionsCount.total}

    return (
        <DataWareHouseData.Provider {...{ value: { ...context, maintenanceActionByMemberId, augmentedIpRightByMemberId, isLoadingAnnuity, progressCount: finalProgressCount } }}>
            {children}
        </DataWareHouseData.Provider>
    )
}

function progressCount<T>(data: InfiniteData<PageResponse<T>>) {
    let count = {current: 0, total: 0}
    if (data?.pages?.length > 0) {
        const lastPage = data.pages[data.pages.length - 1].Data
        if (lastPage) {
            const pageSize = lastPage.PageSize
            const currentPage = lastPage.CurrentPageNumber
            const isLastPage = lastPage.IsLastPage
            const total = lastPage.TotalRows
            if (isLastPage) {
                count = {current: total, total}
            } else {
                count = {current: pageSize * currentPage, total}
            }
        }
    }
    return count
}

function DataWarehouseUI() {
    //console.log('recalculating X')
    const { t } = useTranslation()

    const { setErrorMessage } = useMessages()
    const { hasAnnuities } = useRoles()

    const {
        maintenanceActionByMemberId,
        augmentedIpRightByMemberId: ipRightByMemberId,
        isLoadingAnnuity,
        progressCount,
        dwFields,
        searchableFields,
        displayFields,
    } = useContext(DataWareHouseData)
    const progress = parseInt(progressCount.current / progressCount.total * 100 + '') - (isLoadingAnnuity ? 1 : 0)
    const loadingString = `Loading (${isNaN(progress) ? 0 : progress}%) ...`

    const { families: allFamilies, } = usePatents()
    const { families: _families } = useFilteredPatents()

    const { calculateDueDates } = useTimeline()
    const { instructionByDennemeyerId, ipRightByMemberId: pcIpRightByMemberId, costCentersByIpRightId, validationsByIpRightId } = useDennemeyer()

    /// Limit the number of families to be processed
    // TODO: limiting the numbers of families in useRow
    const families = _.sortBy(_families.slice(0, maxFamilies), f => f.internalReference)

    
    const noAggregagation = new Set([
        'comments',
        'patentComments',
        'lastPatentComment',
        'lastComment',
        'dw_claimScope',
        'applicationDate',
        'applicationNumber',
        'expiryDate',
        'publicationDate',
        'publicationNumber',
        'patentDate',
        'patentNumber',
        'patentFamilyReference', 
        'familyMemberReference',
        'patentFamilyExtReference',
        'patentFamilySummary',
        'patentOfficeLink',
        'familyMemberExtReference',
        'title',
        'familyName',
        'dw_image',
        'dw_score',
        'dm_validation_errors',
        'commodityReference',
        'productImage',
        'commodityDescription',
        'inventionReference',
        'inventionReferenceDate',
        'inventionTitle',
        'inventionSummary',
    ])

    const {rows, searchFields, sortOrder, sortField} = useRowsProvider()
    const {selectedQuery, showDeleteModal, showOpenModal, showSaveAsModal} = useQueriesProvider()

    const sortIndex = searchFields.indexOf(sortField)
    const collator = new Intl.Collator()

    const validValue = (v) => v !== undefined && ((typeof v === 'string' && v.trim() !== "") || typeof v === 'object' || typeof v === 'number')

    const annuityContext = { 
        isLoadingAnnuity, loadingString, 
        pcIpRightByMemberId, ipRightByMemberId,
        maintenanceActionByMemberId, 
        calculateDueDates, instructionByDennemeyerId,
        costCentersByIpRightId, validationsByIpRightId 
    }

    //console.log({dwFields})
    // TODO: add product filter to bar (own/third party and filter word)
    const rowData = rows
        .map(row => ({
            ...row,
            columns: searchFields.map(f => {
                let values = []
                if (f in dwFields.family) {
                    values = row.families.map(fam => dwFields.family[f](fam)).filter(validValue)
                } else if (f === 'dw_score') {
                    const scores = _(row.members).flatMap(m => searchableFields[f](m)).filter(validValue).value()
                    const score = _.mean(scores)
                    if (isNaN(score))
                        values = []
                    else 
                        values = [score.toFixed(1)]
                } else if (f === 'fees' && dwFields['annuities']) {
                    return _(row.members ?? [])
                        .flatMap(m => _.toPairs(dwFields['annuities'][f](m, annuityContext))) // ERROR Not a function
                        .reduce((acc, [ccy, amount]) => ({...acc, [ccy]: parseFloat((acc[ccy] ?? 0)) + parseFloat(amount as string)}), {})
                } else if (row.claimScopeIds && f === 'dw_claimScope') {
                    return Object.fromEntries(row.claimScopeIds.map(c => [c, 1]))
                } else if (typeof dwFields['family-member'][f] === 'function')
                    values = row.members.flatMap(m => dwFields['family-member'][f](m)).filter(validValue)
                else if (f in (dwFields['annuities'] || {}))
                    values = row.members.flatMap(m => dwFields['annuities'][f](m, annuityContext)).filter(validValue)
                else if (f in dwFields['products'])
                    values = row.products.filter(Boolean).map(p => dwFields['products'][f](p)).filter(validValue)
                else if (f in (dwFields['inventions'] || {}))
                    values = row.inventions.filter(Boolean).map(i => dwFields['inventions'][f](i)).filter(validValue)
                return _.countBy(values)
            })
        }))
        .sort((rowA, rowB) => {
            if (sortIndex < 0) return 0
            const a = Object.keys(rowA.columns[sortIndex]).sort()?.[0]
            const b = Object.keys(rowB.columns[sortIndex]).sort()?.[0]
            return a === undefined ? 1 : b === undefined ? -1 : (collator.compare(a, b) * sortOrder)
        })
        .filter(({columns}) => columns.find(c => _.size(c) > 0))

    // Loops over all rows to get the total counts
    const totalCounts = searchFields.map((f, fi) =>
        noAggregagation.has(f)
            ? {Total: _.sum(rowData.map(r => _.size(r.columns[fi])))}
            : f === "numberClaims"
            ? {Total: _(rowData).map(r => dotProduct(r.columns[fi])).sum()} 
            : f === "costs"
            ? {Total: currencyFormat.format(_(rowData).map(r => dotProduct(r.columns[fi])).sum())}
            : _(rowData).flatMap(r => _.toPairs(r.columns[fi])).filter(([key]) => validValue(key)).groupBy(([key]) => key).mapValues(v => _.sum(v.map(([k,v]) => v))).value()
    )
    const tableData = {rows: rowData, columnKeys: searchFields, columns: searchFields.map(f => t([f])), totalCounts}
                        
    const maxRows = 500
    const doDownload = () => downloadReport({ url: "/api/excel", report: "data-report", opts: { tableData } }).catch((err) => setErrorMessage(err.message))
    const hasAnnuityField = searchFields.some(f => dwFields['annuities'] && f in (dwFields['annuities'] ?? {}))
    const exportButton = (!hasAnnuityField || !isLoadingAnnuity || !hasAnnuities)
        ? <button className="btn-secondary font-normal text-base py-px" onClick={doDownload} >
            {t('excel-export')}
        </button>
        : <Popover className="relative">
            <PopoverButton className="btn-secondary font-normal text-base py-px">
                {t('excel-export')}
            </PopoverButton>
            <PopoverPanel className="bg-white rounded-md shadow-md border border-pcx-200 z-40" anchor={{ to: 'bottom end', gap: 2 }}>
                <p className="p-4">
                    {t("renewals-still-loading")}
                </p>
                <div className="p-4 bg-pcx-200 flex flex-row-reverse gap-4">
                    <button className="btn-primary font-normal text-base py-px" onClick={doDownload} >
                        {t('export-anyway')}
                    </button>
                    <PopoverButton className="btn-secondary font-normal text-base py-px">
                        {t('cancel')}
                    </PopoverButton>
                </div>
            </PopoverPanel>
        </Popover>
    return (
        <>
            {/* @ts-ignore */}
            <Helmet>
                <title>{t('data-wizard')} | Patent Cockpit</title>
            </Helmet>
            <div className="header-row">
                <div className="flex flex-row gap-2">
                    <h2 className="modern-h2 grow">{t('data-wizard')}</h2>
                    {exportButton}
                </div>
            </div>
            <div className="main-content">
                <div className="flex flex-col lg:flex-row-reverse justify-between py-2 gap-4 w-visible">
                    <div className="flex lg:flex-col sm:flex-row flex-col lg:items-end sm:items-center gap-2">
                        <h3 className="text-slate-600 whitespace-nowrap">{selectedQuery?.queryName ?? t('unnamed-query')}</h3>
                        <QueryMenu />
                    </div>

                    {showSaveAsModal && <QueryEditorModal />}
                    {showDeleteModal && <QueryDeleteModal />}
                    {showOpenModal && <QueryOpenModal />}

                    <DataWarehouseSettings key={123} />
                </div>
                {tableData.rows.length >= maxRows &&
                    <div className="py-2">
                        <div className="warning">
                            <Trans i18nKey="only-maxRows-shown" values={{ maxRows }} />
                        </div>
                    </div>
                }
                {families.length === 0
                    ? <NoFamilies {...{ families, allFamilies }} />
                    : searchFields.length === 0
                        ? <div className="text-xl text-gray-700 py-10">{t('data-wizard-empty')}</div>
                        : <div className="py-4">
                            <table className="border-spacing-0 border-collapse">
                                <thead className="">
                                    <tr className="text-left">
                                        {searchFields.map(searchField =>
                                            <th key={searchField} className="sticky -top-4 bg-white dark:bg-pcx-100 px-0">
                                                <h3 className="whitespace-nowrap px-2 border-b-2 border-pcx-500">
                                                    {displayFields[searchField]} <SortButtonInContext {...{ searchField }} />
                                                </h3>
                                            </th>
                                        )}
                                    </tr>
                                </thead>
                                <tbody>
                                    {tableData.rows.slice(0, maxRows).map(row =>
                                        <tr key={row.key} className="last:border-b-2 last:border-pcx-500 even:bg-pcx-100 dark:even:bg-pcx-200">
                                            {row.columns.map((values, colIdx) =>
                                                <td key={`${row.key}-${colIdx}`} className="align-top py-0.5 px-2">
                                                    <RenderCell {...{ values, field: searchFields[colIdx] }} />
                                                </td>
                                            )}
                                        </tr>
                                    )}
                                    {tableData.rows.length >= maxRows &&
                                        <tr className="warning">
                                            <td className="text-slate-500 px-2 py-1" colSpan={searchFields.length}>
                                                <Trans i18nKey="only-maxRows-shown" values={{ maxRows }} />
                                            </td>
                                        </tr>
                                    }
                                </tbody>
                                <tfoot>
                                    <tr className="border-t-2 border-gray-800 align-top">
                                        {_.zip(searchFields, totalCounts).map(([field, counts]) =>
                                            <td key={field}>
                                                <div className="px-2 flex flex-col">{
                                                    Object.entries(counts)
                                                        .sort(([va, ca], [vb, cb]) => cb - ca)
                                                        .map(([value, count]) => <div key={`count-${value}`}>{value}: {count}</div>)
                                                }</div>
                                            </td>
                                        )}
                                    </tr>
                                </tfoot>
                            </table>
                        </div>
                }
            </div>
        </>
    )
}

function dotProduct(values: Record<number, number>) {
    return _(values).entries().map(([k, v]) => (+k) * v).sum()
}

const currencyFormat = new Intl.NumberFormat(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })


function optOutMeaningful(member: Member) {
    return (member.countryCode === 'EP' && member.unitaryPatent !== true) || member.validated
}

// values: key of value to count
function RenderCell({ values, field }) {
    const { imagesLookup } = useBackend()
    const { claimScopeById } = useClaims()
    const { commodityById } = useProducts()
    const { familyById } = useFilteredPatents()

    const possibleRef = Object.keys(values)[0]
    const linkStyle = "underline-link py-0 whitespace-nowrap after:content-[';'] mr-1 last:mr-0 last:after:content-['']"

    function onlyKeys() {
        return _(values).keys().sortBy().value()
    }

    if (field === "patentFamilyReference" && possibleRef) {
        return <>{
            _(values)
                .keys().sortBy()
                .map(internalReference =>
                    <Link key={internalReference} className={linkStyle} to={familyUrl({ internalReference })}>
                        {internalReference.trim()}
                    </Link>)
                .value()
        }</>
    } else if (field === "familyMemberReference") {
        return (
            <div className="flex flex-wrap"> {onlyKeys().map((ref) =>
                <Link key={ref} className={linkStyle + " pr-1"} to={memberUrl({ internalReference: ref })}>
                    {ref.trim()}
                </Link>
            )}</div>
        )
    } else if (field === "inventionReference") {
        return (
            <div className="flex flex-wrap"> {onlyKeys().map((ref) =>
                <Link key={ref} className={linkStyle + " pr-1"} to={inventionUrl({ reference: ref })}>
                    {ref.trim()}
                </Link>
            )}</div>
        )
    } else if (field === "dw_image" && possibleRef) {
        // possibleRef is the patent_family_id 
        const url = imagesLookup[patent_family]?.[possibleRef]?.url // NOTE: This only takes the first image instead of all if there is a group
        if (url)
            return (
                <div className="w-36 max-h-[4rem]">
                    <img className="mx-auto h-[4rem]" src={url} alt={`Family ${familyById[possibleRef]?.internalReference ?? ''}`} />
                </div>
            )
        else return null
    } else if (field === "commodityReference") {
        return (
            <div className="flex flex-wrap"> {onlyKeys().map((reference) =>
                <Link key={reference} className={linkStyle + " pr-1"} to={commodityReferenceUrl({ reference })}>
                    {reference.trim()}
                </Link>
            )}</div>
        )
    } else if (field === "productImage" && possibleRef) {
        // possibleRef is the patent_family_id 
        const url = imagesLookup['commodity']?.[possibleRef]?.url // NOTE: This only takes the first image instead of all if there is a group
        return url ? (
            <div className="w-36 max-h-[4rem]">
                <img className="mx-auto h-[4rem]" src={url} alt={`Product ${commodityById[possibleRef]?.internalReference ?? ''}`} />
            </div>
        ) : null
    } else if (field === "dw_claimScope") {
        const scopes = _.flatten(Object.keys(values))
        //console.log(values)
        //console.log(scopes)
        // ?.map(c => claimScopesById[c])
        return (
            <div className="flex flex-row gap-2">
                {scopes.map((c, ci) =>
                    <div key={ci} className="w-64 flex flex-col gap-1">
                        <div className="h-36">
                            <PlainImage {...{
                                entity: 'claim-scope',
                                entityId: c,
                                clickable: false,
                            }} />
                        </div>
                        <div
                            key={ci}
                            className="bg-pcx-100 dark:bg-pcx-200 p-1 rounded-md max-w-prose border border-pcx-200"
                            dangerouslySetInnerHTML={{ __html: claimScopeById[c]?.claimScopeSummary }} />
                    </div>
                )}
            </div>
        )
    } else if (field === "numberClaims") {
        const total = dotProduct(values)
        return <div>{total === 0 ? "" : total}</div>
    } else if (field === "costs") {
        const total = dotProduct(values)
        return <div className="w-full text-right tabular-nums">{currencyFormat.format(total)}</div>
    } else if (field === "comments" || field === 'patentComments') {
        return <div>{_.keys(values).map((v, vi) => <p className="last:mb-0 whitespace-pre-line" key={vi}>{v}</p>)}</div>
    } else if (field === 'patentOfficeLink') {
        return <div className="flex flex-row flex-wrap gap-x-1">{
            _(values)
                .keys()
                .filter(v => typeof v === 'string' && v.trim() !== '')
                .map((v, vi) =>
                    <a
                        href={v} target="_blank" rel="noreferrer" title={v} key={vi}
                        className="whitespace-nowrap max-w-xs text-ellipsis overflow-hidden text-pcx-600 underline"
                    >{v}</a>)

                .value()
        }</div>
    } else if (field === "fees") {
        return <div>{_(values).toPairs().map(([ccy, v], vi) =>
            <span className="pr-1 last:pr-0 after:content-[';'] last:after:content-['']" key={vi}>{currencyFormat.format(v)} {ccy}</span>
        ).value()}</div>
    } else {
        return (
            <div className="flex flex-wrap"> {onlyKeys().map((v) =>
                <span key={v} className="last:pr-0 pr-1 after:content-[';'] last:after:content-['']">{v}</span>
            )}</div>
        )
    }
}

function NoFamilies({ families, allFamilies }) {
    const { t } = useTranslation()

    return <div className="text-center border-t-2 border-pcx-500 pt-4 mt-2 mr-4">
        {families.length === allFamilies.length
            ? <>
                {t('extract-data-here')}
                <br />
                <Link className="text-pcx-500 underline-link" to="/patents/portfolio">{t('add-patents')}</Link>
            </>
            :
            <Trans i18nKey="no-patent-results" />
        }
    </div>
}