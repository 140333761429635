import _ from 'lodash'

import { Member } from "../patents/patents"
import { useValuations } from "./ValuationsProvider";
import { useFilteredPatents } from '../filter/FilteredPatents';
import { usePatents } from '../patents/PatentsProvider';

export function useScores() {
    const {families, membersByFamilyId} = useFilteredPatents()
    const {scoresLookup} = useValuations()

    return _(families).map(family => {
        const patentFamilyId = family.patentFamilyId
        return [patentFamilyId, calcFamilyScores(membersByFamilyId[patentFamilyId] ?? [], scoresLookup)]
    }).fromPairs().value()
}

export function useFamilyScores(patentFamilyId: number) {
    const {membersByFamilyId} = usePatents()
    const {scoresLookup} = useValuations()
    
    const members = membersByFamilyId[patentFamilyId] ?? []

    return calcFamilyScores(members, scoresLookup)
}

export function useMemberScores(familyMemberId: number) {
    const {scoresLookup} = useValuations()

    const scores = scoresLookup[familyMemberId] ?? []
    const score = calcScore(scores)

    return score?.toFixed(1)
}

function calcFamilyScores(members: Member[], scoresLookup: {[key: number]: Score[]}) {

    let totalScore = 0.0
    let count = 0

    const memberScores = {}

    _.forEach(members, ({familyMemberId}) => {
        const scores = scoresLookup[familyMemberId] || []
        const score = calcScore(scores)
        if (score) {
            totalScore += score
            count++
            memberScores[familyMemberId] = score.toFixed(1)
        }
    })

    const familyScore = (count === 0) ? undefined : (totalScore / count).toFixed(1)

    return {familyScore, memberScores}
}

export type Score = {
    type: string;
    score: number;
    familyMemberId: number;
}

function calcScore(scores: Score[]) {
    const topLevel = scores.filter(s => !s.type.includes("."))
    if (topLevel.length === 0) {
        return undefined
    } else {
        return Math.pow(topLevel.map(s => s.score).reduce((a, b) => a * b, 1), 1.0 / topLevel.length)
    }
}

export function scoreBackground(score: number | string) {

    if (score === undefined)
        return "bg-red-200"

    const score_number = typeof score === 'number' ? score : parseFloat(score)
    return score_number === undefined || score_number < 1.0
        ? "bg-red-200"
        : score_number < 2.0
        ? "bg-yellow-200/80"
        : score_number < 3.0
        ? "bg-lime-200"
        : "bg-green-600/50"
}