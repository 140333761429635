import { useState } from "react";
import { useTranslation } from "react-i18next";
import {
  CheckCircleIcon,
  ChevronUpDownIcon,
  XMarkIcon,
} from "@heroicons/react/20/solid";
import { Combobox, ComboboxButton, ComboboxInput, ComboboxOption, ComboboxOptions, Listbox, ListboxButton, ListboxOption, ListboxOptions, Radio, RadioGroup } from "@headlessui/react";
import clsx from "clsx";
import _, { capitalize } from "lodash";

import {
  active_only_filter,
  availableFilters,
  countries_filter,
  empty_value,
  free_text_filter,
  inventor_filter,
  owner_filter,
  product_class_filter,
  product_filter,
  product_ownership_filter,
  reference_filter,
  tag_filter,
} from "./utils";
import { FilterConfig, useFilter } from "../filter/Filter";
import { patent_family } from "../data";
import { IconCheckSmall } from "../components/icons";
import { nameOf } from "../agents/utils";
import { useTrademarks } from "../trademarks/TrademarksProvider";
import { usePatents } from "../patents/PatentsProvider";
import { useProducts } from "../products/ProductsProvider";
import { useAgents } from "../agents/AgentsProvider";
import { useTags } from "../tags/TagsProvider";


export function OnlyActiveRibbon() {
  const {t} = useTranslation()

  const { showActiveOnly, setShowActiveOnly } = useFilter();

  return (
    <label
      className={clsx(
        "flex items-center gap-1 pl-2 pr-1 text-sm rounded-sm ", 
        showActiveOnly ? "bg-pcx-500 hover:bg-pcx-400 text-white" : "bg-pcx-400 text-pcx-200 hover:text-white"
      )}
    >
      <span className="text-sm max-w-[10rem] whitespace-nowrap overflow-hidden text-ellipsis" >
        {t('active-only')}
      </span>
      <input 
        type="checkbox" className="ml-1 form-checkbox bg-pcx-200 focus:ring-0 focus:outline-none focus:outline-0 focus-visible:ring-0 focus-visible:outline-none focus-visible:outline-0" 
        checked={showActiveOnly} onChange={(e) => setShowActiveOnly(e.target.checked)}
      />
    </label>
  )
}

export function FilterRibbon({display, index}) {
  const { removeActiveFilter } = useFilter();
  return (
    <div
      key={index}
      className="flex items-center gap-1 pl-2 pr-1 bg-pcx-500 hover:bg-pcx-400 text-white text-sm rounded-sm"
    >
      <span
        className="text-sm max-w-[10rem] whitespace-nowrap overflow-hidden text-ellipsis"
        title={display}
      >
        {display}
      </span>
      <button
        className="w-5 h-5 p-px"
        onClick={() => removeActiveFilter(index)}
      >
        <XMarkIcon className="w-5 h-5" />
      </button>
    </div>
  )
}

export function FilterDisplay({
  filter,
  index,
}: {
  filter: FilterConfig;
  index: number;
}) {
  switch (filter.name) {
    case tag_filter:
      return <TagFilterInput {...{ filter, index }} />;
    case countries_filter:
      return <CountryFilterInput {...{ filter, index }} />;
    case product_ownership_filter:
      return <ProductOwnershipInput {...{ filter, index }} />;
    case owner_filter:
      return <AgentFilter {...{ filter, index, linkType: 'owner' }} />;
    case inventor_filter:
      return <AgentFilter {...{ filter, index, linkType: 'inventor' }} />;
    case product_filter:
      return <ProductAttributeInput {...{ filter, index, attribute: 'commodityReference' }} />;
    case product_class_filter:
      return <ProductAttributeInput {...{ filter, index, attribute: 'commodityClass' }} />;
    case reference_filter:
      return <ReferenceListFilter {...{ filter, index }} />;
    case free_text_filter:
      return <FreeTextFilter {...{ filter, index }} />;
    default:
      return <></>;
  }
}

export function AddFilter() {
  const { t } = useTranslation();
  const { activeFilters, setActiveFilters } = useFilter();
  const handleClick = (name: string) => {
    const filter =
      name === active_only_filter
        ? { name, display: t(name), config: {}, isActive: true }
        : { name, display: "", config: [], isActive: false };

    if (name === "active-only" || name === "product-ownership") {
      if (activeFilters.find((fs) => fs.name === name) === undefined) {
        setActiveFilters((fs) => [...fs, filter]);
      }
    } else {
      setActiveFilters((fs) => [...fs, filter]);
    }
  };

  return (
    <Listbox as="div" className="relative font-medium text-pcx-700">
      <ListboxButton className="form-select py-1 border-pcx-300 whitespace-nowrap text-sm bg-transparent">
        {t('add-filter')} 
      </ListboxButton>
      <ListboxOptions className="absolute mt-1 list-none overflow-hidden border border-pcx-300 rounded shadow w-fit bg-white z-30">
        {_(availableFilters)
          .filter(f => f !== active_only_filter)
          .map(f => ({value: f, label: t(f)}))
          .sortBy(({label}) => label.toLowerCase())
          .map(({value, label}) =>
            <ListboxOption
              key={value} value={value} onClick={() => handleClick(value)} 
              className="px-4 py-1.5 first:pt-3 last:pb-3 whitespace-nowrap hover:bg-pcx-200"
            >
              {label}
            </ListboxOption>
          ).value()}
      </ListboxOptions>
    </Listbox>
  )
}

function ProductAttributeInput({ filter, index, attribute }) {
  const { commodities } = useProducts();

  const availableProductAttributes = _(commodities ?? [])
    .map((c) => c[attribute])
    .uniq()
    .sortBy(r => r?.toLowerCase())
    .value();

  return (
    <fieldset className=" w-fit">
      <InputHeader {...{ filter, index }} />
      <ValueChecker
        {...{
          name: filter.name,
          index: index,
          availableValues: availableProductAttributes,
          checkedValues: filter.config ?? [],
          allowEmpty: false,
        }}
      />
    </fieldset>
  )
}

function TagFilterInput({ filter, index, }: {
  filter: FilterConfig;
  index: number;
}) {
  const { tags } = useTags();

  const availableTags = _(tags ?? [])
    .filter((t) => t.entity === patent_family)
    .map((t) => t.tag)
    .uniq()
    .sortBy()
    .value();

  const checkedTags = (filter.config ?? []) as string[];

  return (
    <fieldset className=" w-fit">
      <InputHeader {...{ filter, index }} />
      <ValueChecker
        {...{
          name: filter.name,
          index: index,
          availableValues: availableTags,
          checkedValues: checkedTags,
          allowEmpty: true,
        }}
      />
    </fieldset>
  );
}

function CountryFilterInput({ filter, index, }: { filter: FilterConfig; index: number; }) {

  const { members } = usePatents();
  const { trademarks } = useTrademarks()

  const availableCountries = _(members.map(m => m.countryCode))
    .concat(trademarks.map((m) => m.countryCode))
    .uniq()
    .sortBy()
    .value();

  const checkedValues = (filter.config ?? []) as string[];

  return (
    <fieldset className=" w-fit">
      <InputHeader {...{ filter, index }} />
      <ValueChecker
        {...{
          name: filter.name,
          index: index,
          availableValues: availableCountries,
          checkedValues,
          allowEmpty: false,
        }}
      />
    </fieldset>
  );
}

function AgentFilter({filter, index, linkType}: {filter: FilterConfig, index: number, linkType: string}) {

  const { agentById, agentsLookup } = useAgents();
  const { agentsLookup: tmAgentsLookup } = useTrademarks()
  const tmAvailableAgents = _.keys(tmAgentsLookup[linkType] ?? {})

  const availableAgents = _.keys(agentsLookup[linkType] ?? {})

  const checkedValues = (filter.config ?? []) as number[];
  function renderValue(id: number | string) {
    const agent = agentById[id]
    return agent ? nameOf(agent) : 'not found'
  }
  const availableValues = _(availableAgents).concat(tmAvailableAgents)
    .sortBy(id => renderValue(id).toLowerCase())
    .value()

  return (
    <fieldset className=" w-fit">
      <InputHeader {...{ filter, index }} />
      <ValueChecker
        {...{
          name: filter.name,
          index: index,
          availableValues,
          checkedValues,
          renderValue,
          allowEmpty: true,
        }}
      />
    </fieldset>
  )

}

function InputHeader({ filter, index, }: {
  filter: FilterConfig;
  index: number;
}) {
  const { t } = useTranslation();
  const { removeActiveFilter } = useFilter();

  return (
    <div className="flex justify-between items-center gap-4">
      <legend className="block font-medium">{t(filter.name)}</legend>
      <div className="cursor-pointer" onClick={() => removeActiveFilter(index)}>
        <XMarkIcon
          className={clsx(
            "w-5 h-5",
            true ? "hover:text-pcx-900" : "hover:text-pcx-700"
          )}
        />
      </div>
    </div>
  );
}

function ValueChecker({
  name,
  index,
  availableValues,
  checkedValues: _checkedValues,
  renderValue = v => v,
  allowEmpty = false,
}) {
  const { t } = useTranslation();
  const { updateActiveFilter, activeFilters } = useFilter();

  const emptyString = `(${capitalize(t("empty"))})`
  const checkedValues = _checkedValues === 'empty' ? [empty_value] : _checkedValues

  const _renderValue = allowEmpty 
    ? (v) => v === empty_value ? emptyString : renderValue(v) 
    : (v) => v ? renderValue(v) : emptyString // render undefined as empty

  const [query, setQuery] = useState("");

  function handleClick(event: string[]) {
    const filter = activeFilters[index];
    const newFilter = {
      ...filter,
      display: event.map(_renderValue).join(", "),
      config: event,
      isActive: event.length > 0,
    };
    updateActiveFilter(newFilter, index);
  }

  const handleSelectAll = (areAllChecked: boolean) => {
    const newFilter = areAllChecked ? {
      name,
      display: "",
      config: [],
      isActive: false,
    } : {
      name,
      display: availableValues.map(_renderValue).join(", "),
      config: availableValues,
      isActive: true,
    };

    updateActiveFilter(newFilter, index);
  };

  const areAllChecked = _.difference(availableValues, checkedValues).length === 0

  //console.log({query})
  const filteredValues = query === '' 
    ? availableValues 
    : availableValues.filter((value: string) => renderValue(value).toLowerCase().includes(query.toLowerCase()))

  return (
    <Combobox
      value={/*checkedValues === 'empty' ? [] :*/ checkedValues}
      onChange={handleClick}
      multiple
    >
      <div className="relative mt-2">
        <div className="relative cursor-default overflow-hidden rounded-lg bg-white text-left sm:text-sm">
          <div className="group">
            <div className="absolute py-2 pl-3 text-slate-800 bg-white group-focus-within:hidden pointer-events-none overflow-hidden truncate pr-6 w-full">
              {checkedValues.map(_renderValue).join(", ")}
            </div>
            <ComboboxInput
              className={clsx("w-fit border-none py-2 pl-3 pr-10 text-sm leading-5 truncate", query === '' ? "text-gray-900" : "text-gray-500")}
              //displayValue={(values) => query === '' ? values.map((value) => value).join(", ") : query}
              //displayValue={(values) => values.map((value) => value).join(", ")}
              //placeholder={t("search")}
              value={query}
              onChange={(e) => { setQuery(e.target.value) }}
            />
          </div>
          <ComboboxButton className="absolute inset-y-0 right-0 flex items-center pr-2">
            <ChevronUpDownIcon
              className="h-5 w-5 text-gray-400"
              aria-hidden="true"
            />
          </ComboboxButton>
        </div>
        <ComboboxOptions className="absolute z-30 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 sm:text-sm">
          <button onClick={() => handleSelectAll(areAllChecked)} className="w-full text-left px-2 pt-2 pb-1 text-sm hover:bg-pcx-400 hover:text-white text-gray-900">
            <CheckedRow {...{ selected: areAllChecked, value: `(${t("select-all")})` }} />
          </button>
          {allowEmpty &&
            <ComboboxOption
              key={index}
              className={comboboxClass}
              value={empty_value}
            >{({ selected }) => <CheckedRow {...{ selected, value: emptyString }} />
            }</ComboboxOption>
          }
          {_(filteredValues)
            .sortBy(v => v ? renderValue(v).toLocaleLowerCase() : '')
            .map((value: string, index: number) => (
              <ComboboxOption
                key={index}
                className={comboboxClass}
                value={value}
              >{({ selected }) => <CheckedRow {...{ selected, value: value ? renderValue(value) : emptyString }} />
                }</ComboboxOption>
            ))
            .value()}
        </ComboboxOptions>
      </div>
    </Combobox>
  );
}

const comboboxClass = ({active}: {active: boolean}) => clsx(
  'relative cursor-default select-none list-none py-1 px-2',
  active ? "bg-pcx-400 text-white" : "text-gray-900"
)

function CheckedRow({selected, value}) {
  return <span className="inline-flex gap-2">{selected ? <IconCheckSmall /> : <span className="w-5" />} {value}</span>
}

function ProductOwnershipInput({
  filter,
  index,
}: {
  filter: FilterConfig;
  index: number;
}) {
  const { t } = useTranslation();
  const { updateActiveFilter } = useFilter();

  const availableValues = [
    { name: "only-own", value: false },
    { name: "only-third-party", value: true },
  ].map((v) => ({ ...v, display: t(v.name) }));

  const selectedValue = filter.config;

  function setValue(value: {name: string, value: boolean, display: string}) {
    updateActiveFilter(
      { ...filter, display: value.display, config: value, isActive: true },
      index
    );
  }

  return (
    <fieldset className=" w-fit">
      <InputHeader {...{ filter, index }} />
      <RadioGroup
        className="pt-6 sm:pt-4 space-y-2 text-gray-600"
        value={selectedValue}
        onChange={setValue}
        by="value"
      >
        {availableValues.map((value) => (
          <Radio value={value} key={value.name}>
            {({ checked }) => {
              //console.log({ checked, value, selectedValue, _checked: selectedValue === value })
              return (
                <div className="inline-flex items-center gap-2 hover:text-pcx-600 cursor-pointer h-6">
                  {checked ? <CheckCircleIcon className="w-5 h-5" /> : <div className="w-5 h-5"><div className="mx-0.5 my-0.5 w-4 h-4 border border-current rounded-full" /></div>}
                  {value.display}
                </div>
              )
            }}
          </Radio>
        ))}
      </RadioGroup>
    </fieldset>
  );
}

function ReferenceListFilter({ filter, index }: { filter: FilterConfig, index: number }) {
  const { updateActiveFilter } = useFilter();

  function setValue(value: string) {
    updateActiveFilter({...filter, config: value, display: value, isActive: true }, index);
  }

  return (
    <fieldset className="w-fit">
      <InputHeader {...{ filter, index }} />
      <textarea className="mt-2 form-textarea text-xs max-h-[70vh]" value={filter.config ?? ''} onChange={e => setValue(e.target.value)} />
    </fieldset>
  )
}

function FreeTextFilter({ filter, index }: { filter: FilterConfig, index: number }) {
  const { updateActiveFilter } = useFilter();

  function setValue(value: string) {
    updateActiveFilter({...filter, config: value, display: value, isActive: true }, index);
  }

  return (
    <fieldset className="w-fit">
      <InputHeader {...{ filter, index }} />
      <input type="text" className="mt-2 form-input text-xs" value={filter.config ?? ''} onChange={e => setValue(e.target.value)} />
    </fieldset>
  )
}
