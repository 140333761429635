import { getCaseInfo, parseXmlResponse } from ".";

export async function downloadTrademark(applicationNumber: string) {
    const response = await getCaseInfo(applicationNumber)
    if (response.status !== 200) {
        throw new Error(`HTTP Error: ${response.status}; ${response.statusText}`)
    }
    const body = await response.text()
    const parser = new DOMParser();
    const xml_doc = parser.parseFromString(body, "text/xml");

    const result = parseXmlResponse(xml_doc)
    return result
}