import { useTranslation } from "react-i18next";
import { Link, useNavigate, useParams } from "react-router-dom";
import _ from "lodash";

import { DatePicker } from "../components/input/DatePicker";
import { epoLoad } from "../backend";
import { useMessages } from "../Messages";
import clsx from "clsx";
import { useQuery } from "@tanstack/react-query";
import { Formik, Form, Field } from "formik";
import { memberUrl } from "../patents/utils";
import { emptyStringAsUndefined } from "../utils/strings";
import { Claim } from "./MemberClaimsProvider";
import { usePatents } from "../patents/PatentsProvider";
import { useClaims } from "./ClaimsProvider";

export default function ClaimsImport() {
    const { t } = useTranslation();
    const { setErrorMessage } = useMessages()
    const { memberByReference } = usePatents()
    const { postClaim, claims: _claims, reload } = useClaims()
    const navigate = useNavigate()

    const { internalReference } = useParams()
    const member = memberByReference[internalReference]
    const familyMemberId = member?.familyMemberId
    const existingClaimVersions = _claims.filter(c => c.familyMemberId === familyMemberId).map(c => c.version)
    const publicationNumber = emptyStringAsUndefined(member?.patentNumber) ?? emptyStringAsUndefined(member?.publicationNumber)
    //console.log({publicationNumber, member, internalReference})

    // claims: the language is given by the version: EN, FR, DE, etc.
    const {data: claims, isLoading, error} = useQuery<Claim[], {message: string}>({
        queryKey: ['epo-load-claims', member?.familyMemberId],
        queryFn: () => epoLoad({ publicationNumber, familyMemberId: member?.familyMemberId, type: 'claim' }),
        enabled: !!publicationNumber,
    })
    //console.log({isLoading, status})
    const hasClaims = claims && claims.length > 0

    if (error) {
        console.error(error.message)
    }

    const groupedClaims = _.groupBy(claims ?? [], 'version')
    const languages = _(groupedClaims).keys().sortBy().value()

    const importClaims = (toImport) => {
        //console.log({toImport})
        Promise.all(toImport.map(claim => postClaim(claim)))
            .then(() => {
                reload()
                navigate(memberUrl(member))
            })
            .catch(error => setErrorMessage(error.message))
    }

    function filterClaims(claims, {onlyIndependent}) {
        return claims.filter(({ claimType }) => !onlyIndependent || claimType === 'independent-claim')
    }

    return <>
        <div className="header-row">
            <h2>
                {internalReference && <Link to={memberUrl({internalReference})} className="mr-2 after:content-[':']">{internalReference}</Link>}
                {t('load-claims-from-epo')}
            </h2></div>
        <div className="main-content space-y-4 overflow-y-scroll">
            {error && <div className="text-warn-800 text-lg">{t('claims-download-error')}</div> }

            {hasClaims &&
                <Formik 
                    initialValues={{
                        version: publicationNumber,
                        versionDate: new Date().toISOString().substring(0, 10),
                        language: claims[0]?.version,
                        onlyIndependent: false,
                    }}
                    validate={(values) => {
                        const errors = {}
                        if ((values.version ?? "").trim() === "")
                            errors['version'] = "Version must be set"
                        else if (existingClaimVersions.includes(values.version))
                            errors['version'] = "Version already exists"
                        if ((values.versionDate ?? "").trim() === "")
                            errors['versionDate'] = "Version Date must be set"
                        return errors
                    }}
                    onSubmit={values => {
                        const { version, versionDate } = values
                        const claims = filterClaims(groupedClaims[values.language] ?? [], values)
                            .map(claim => ({...claim, version, versionDate}))
                        importClaims(claims)
                    }}
                >{({ values, touched, errors }) => {
                    const claims = filterClaims(groupedClaims[values.language] ?? [], values)
                    return <>
                        <Form className="max-w-xl space-y-2">
                            <div className="flex flex-col md:flex-row gap-2">
                                <label className="w-full">
                                    <div className="label">{t('version')} <span className="text-red-700">{touched.version && (errors.version as string)}</span></div>
                                    <Field className="form-input py-1 w-full" name="version" />
                                </label>
                                <label className="w-full">
                                    <div className="label">{t('versionDate')} <span className="text-red-700">{touched.versionDate && errors.versionDate}</span></div>
                                    <Field className="form-input py-1 w-full" name="versionDate" as={DatePicker} />
                                </label>
                            </div>

                            <div className="flex flex-col md:flex-row gap-2">
                                <label className="w-full">
                                    <div className="label">{t('language')} <span className="text-red-700">{touched.language && errors.language}</span></div>
                                    <Field className="form-select py-1 w-full" name="language" as="select" >
                                        {languages.map(version => <option key={version} value={version}>{version}</option>)}
                                    </Field>
                                </label>

                                <label className="w-full inline-flex gap-2 items-center md:mt-2">
                                    <Field className="form-checkbox" name="onlyIndependent" type="checkbox" />
                                    <div className="label">{t('only-independent')}</div>
                                </label>
                            </div>

                            <div className="py-2 flex flex-row-reverse gap-2">
                                <input type="submit" className="btn-primary" value={t('import')} />
                                <Link to={member ? memberUrl(member) : ('' + -1)} className="btn-secondary text-center">{t('cancel')}</Link>
                            </div>
                        </Form>
                        <div className="max-w-xl">
                            <ClaimsTable {...{ claims }} />
                        </div>
                    </>
                }}</Formik>}

            {/* If we have no claims.... */}
            {isLoading && publicationNumber && <div className="text-lg py-4">{t('loading')}...</div>}
            {publicationNumber && claims?.length === 0 && !isLoading && <div className="text-lg py-4">{t('no-claims-found')}</div>}
            {!publicationNumber && <div className="text-lg py-4">{t('no-publication-number')}</div>}

            {!hasClaims && 
                <Link to={member ? `${memberUrl(member)}/claims` : ('' + -1)} className="block w-fit btn-secondary text-center">{error ? t('back') : t('cancel')}</Link>
            }
        </div>
    </>
}


function ClaimsTable({claims}) {
    const { t } = useTranslation()
    return (
        <table className="text-slate-700">
            <thead className="text-left">
                <tr>
                    <th>{t('claims-to-import')}</th>
                </tr>
            </thead>
            <tbody className="text-sm">
                {claims.map((claim, index) => <tr key={index}>
                    <td className={clsx(
                        claim.claimType === 'independent-claim' ? 'pl-8 pt-1' : 'pl-12 border-l-2 border-pcx-200',
                        '-indent-8 hover:bg-pcx-100'
                    )}>
                        {claim.claimNumber}. {claim.claimText}
                    </td>
                </tr>)}
            </tbody>
        </table>
    )
}