import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query"
import { Field, Form, Formik } from "formik"
import { useTranslation } from "react-i18next"
import { ErrorMessage, useMessages } from "../Messages"
import { useAuth, useRoles } from "../user/Auth"
import { parseBackend } from "../backend"
import { Link } from "react-router-dom"

export default function DocumentsSettings() {
    const {t} = useTranslation()

    const {settings, postSettings} = useDocumentSettings()

    return (
        <div className="main-content bg-pcx-100">
            <div className="w-fit bg-white p-4 rounded-xl shadow">
                <Link to="/documents" className="text-slate-400 hover:underline text-sm block mb-2">&lt; Back</Link>
                <h2 className="mb-4">{t('settings')}</h2>
                <Formik initialValues={settings} onSubmit={values => postSettings(values)}>{({dirty, resetForm}) =>
                    <Form className="flex flex-col gap-2 w-fit">
                        <label className="label" htmlFor="username">{t('username')}</label>
                        <Field className="form-input" id="username" name="username" type="text" />

                        <div />

                        <label className="label" htmlFor="password">{t('password')}</label>
                        <Field className="form-input" id="password" name="password" type="password" />

                        <div />

                        <div className="flex flex-row-reverse gap-2 pt-2">
                            <input type="submit" className="btn-primary disabled:btn-disabled" disabled={!dirty} value={t('save')} />
                            <button type="button" className="btn-secondary" onClick={() => resetForm()}>{t('reset')}</button>
                        </div>
                    </Form>
                }</Formik>
            </div>
        </div>
    )
}

interface DocumentsSettingsProps {
    agorumClientId?: number;
    username: string;
    password: string;
    baseurl: string;
    settingsId?: number;
}

export function useDocumentSettings() {
    const {hasDocuments} = useRoles()
    // TODO: remove post
    const {data, postMutation: postSettings} = useCrud<DocumentsSettingsProps>('settings', s => s.settingsId, hasDocuments)
    
    const settings = data?.[0] || { username: '', password: '', baseurl: '' }
    //console.log({settings})

    return {
        settings,
        number: settings.agorumClientId?.toString(),
        postSettings,
    }
}

// TODO: move below to BackendProvider or so
// TODO: Make prefix configurable
function rpc(request: { entity: string, operation: string, data?: any, id?: any, realm: string }, prefix = '/dms') {
    return parseBackend(fetch(`/api${prefix}/${request.entity}`, {
        method: 'POST',
        body: JSON.stringify(request),
        credentials: 'same-origin',
        headers: { 'Content-Type': 'application/json'},
    }))
}


function useCrud<T>(entity: string, get_id: (e: T) => any, enabled = true) {
    const queryClient = useQueryClient()
    const {setErrorMessage} = useMessages()
    const {team, setStrike} = useAuth()

    const queryKey = ['dms', entity]
    const queryFn = async () => rpc({ entity, operation: 'get', realm: team })
        .then(res => { setStrike(0); return res })
        .catch((err: ErrorMessage) => {
            setErrorMessage(err.message);
            if (err.status === 'unauthorized') {
                console.warn("unauthorized")
                setStrike(s => s + 1)
            }
            return []
        })
    const { data, error, isLoading } = useQuery<T[], ErrorMessage, T[]>({
        queryKey,
        queryFn,
        placeholderData: [], initialData: [], enabled 
    })

    if (error)
        setErrorMessage(error.message)

    const mutationConfig = {
        onError: (err: ErrorMessage, payload: T, context) => {
            console.error(err)
            if (err.status === "unauthorized") {
                setStrike(s => s + 1)
            }
            queryClient.setQueryData(queryKey, context.previousEntities)
        },
        onSettled: () => {
            queryClient.invalidateQueries({
                queryKey,
                // @ts-ignore TODO MIGRATION CHECK
                queryFn,
                refetchType: 'all',
            })
            setStrike(0)
        },
        enabled,
    }

    function postOperation(payload: T) {
        if (get_id(payload))
            return "update"
        else
            return "add"
    }

    const postMutation = useMutation<T, ErrorMessage, T>({
        mutationFn: (payload) =>
            rpc({ entity, operation: postOperation(payload), data: { ...payload }, realm: team })
                .catch(err => setErrorMessage(err.message)), 
        ...mutationConfig,
        onMutate: async (payload) => {
            await queryClient.cancelQueries({queryKey})
            const previousEntities = queryClient.getQueryData(queryKey)
            queryClient.setQueryData(queryKey, old => {
                // check if old is of type array
                if (Array.isArray(old)) {
                    //console.log({old})
                    const p_id = get_id(payload)
                    return p_id
                        ? old.map(o => get_id(o) === p_id ? payload : o)
                        : [...old, payload]
                } else {
                    return [payload]
                }
            })
            return { previousEntities }
        },
    })

    const deleteMutation = useMutation<T, ErrorMessage, T>({
        mutationFn: (payload) =>
            rpc({ entity, operation: 'delete', id: get_id(payload), realm: team })
                .catch(err => setErrorMessage(err.message)), 
        ...mutationConfig,
        onMutate: async (payload) => {
            //console.log({payload})
            await queryClient.cancelQueries({queryKey})
            const previousEntities = queryClient.getQueryData(queryKey)
            queryClient.setQueryData(queryKey, (old) => {
                return Array.isArray(old) ? old.filter(o => get_id(o) !== get_id(payload)) : []
            })
            return { previousEntities }
        }
    })

    return {
        data, 
        isLoading, 
        postMutation: postMutation.mutateAsync, 
        deleteMutation: deleteMutation.mutateAsync
    }
}