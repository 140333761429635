
export function CopyButton({id}) {
    return <button className="btn-secondary" onClick={() => copyTableToClipboard(id)}>Copy</button>
}

async function copyTableToClipboard(id: string) {
    // Get the table element
    const table = document.getElementById(id);
    // @ts-ignore
    const rows = table.rows;
    let tableText = '';

    // Iterate through each row and get the text content
    for (let i = 0; i < rows.length; i++) {
        const cells = rows[i].cells;
        for (let j = 0; j < cells.length; j++) {
            tableText += cells[j].innerText + (j === cells.length - 1 ? '' : '\t');
        }
        tableText += '\n';
    }

    // Copy to clipboard using the Clipboard API
    return navigator.clipboard.writeText(tableText).then(() => {
        //alert('Table content copied to clipboard!');
    }).catch(err => {
        console.error('Error copying to clipboard: ', err);
        //alert('Failed to copy table content.');
    });
}