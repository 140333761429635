import { Outlet} from 'react-router-dom'

import { LoadingModal } from '../components/Modal'
import { useProducts } from './ProductsProvider'

// TODO: make is loading check somewhere global
export default function ProductsMenu() {
    const { isLoading } = useProducts()

    return isLoading ? <LoadingModal /> : <Outlet />
}