import { useState } from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import _ from 'lodash'
import { useTranslation } from "react-i18next"

import Trinary from './input/Trinary'
import { IconEdit, IconPlus, IconTrash } from './icons';
import { DatePicker } from './input/DatePicker';
import Modal from './Modal';
import { TagListField } from './TagList';


export function isoDateString(date) {
    return date.toISOString().slice(0, 10)
}

export function asUrl(urlString) {
    if (urlString.startsWith('http'))
        return urlString
    else if (urlString.length > 0)
        return '//' + urlString
    else
        return urlString
}

export function AddFormikButton({startingPoint = {}, fields, onSubmit, small = false, validations = {}, children, title }) {
    const [isEditing, setIsEditing] = useState(false)

    const addMask =
        <Modal id="add-modal" escAction={() => setIsEditing(false)}>
            <EditFormikMask {...{ 
                original: startingPoint, 
                fields, 
                onSubmit: (x) => { onSubmit(x); setIsEditing(false) }, 
                validations, 
                cancelAction: () => setIsEditing(false),
                title,
            }} />
        </Modal>
    const styling = (children === undefined) 
        ? (small ? "w-6 h-6" : "w-8 h-8") + " rounded-full p-1 flex-shrink btn-primary" 
        : (small ? "text-sm text-left " : "") + "btn-primary"

    return (<>
        <div className="flex flex-row">
            <button
                onClick={() => setIsEditing(true)}
                className={styling}>
                {children ?? <IconPlus />}
            </button>
        </div>
        {isEditing && addMask}
    </>)
}

export function DeleteButton({ small = false, del, inactivate = undefined, children, question }) {
    const {t} = useTranslation()

    const [showPopup, setShowPopup] = useState(false);

    function DeletePopup() {
        return (
            <Modal blurClick={() => setShowPopup(false)} id="delete-modal">
                <div className="p-4">

                    <div className="pb-2 text-lg">
                        {question ?? t('really-delete')}
                    </div>
                    {inactivate !== undefined &&
                        <div className="pb-2 text-lg">
                            {t('or-just-inactivate')}
                        </div>
                    }
                </div>
                <div className="flex flex-row-reverse gap-4 text-base font-medium bg-pcx-200 p-4">
                    <button
                        className="btn-warn text-red-900"
                        onClick={() => { del(); setShowPopup(false) }} >
                        {t('delete')}
                    </button>
                    {inactivate !== undefined &&
                        <button
                            className="btn-primary"
                            onClick={() => { inactivate(); setShowPopup(false) }}>
                            {t('inactivate')}
                        </button>
                    }
                    <button className="btn-secondary" onClick={() => setShowPopup(false)}>{t('cancel')}</button>
                </div>
            </Modal>
        )
    }
    const size = children
        ? (small ? "w-fit h-6" : "w-fit h-8 px-2")
        : (small ? "w-6 h-6" : "w-8 h-8")

    return (
        <>
            <button
                title={children === undefined ? t("delete") : undefined}
                className={`shrink-0 btn-warn p-1 ${size}`}
                onClick={() => setShowPopup(true)}
            >
                {children ?? <IconTrash />}
            </button>
            {showPopup && <DeletePopup />}
        </>
    )
}


// other members, close modal;  fieldUpdates: [fieldName] -> (update(value) -> newValue)
export function EditFormik({ title, fields, onSubmit, validations = {}, original = {}, fieldUpdates = () => ({}), small = false, blockEsc = false }) {
    const { t } = useTranslation()
    const [showModal, setShowModal] = useState(false)
    return <>
        <button
            title={t("edit")}
            className={`btn-primary p-1 ${small ? "w-6 h-6" : "w-8 h-8"}`}
            onClick={() => setShowModal(true)}
        >
            <IconEdit />
        </button>
        {showModal &&
            <Modal id={title ?? 'edit-modal'} escAction={() => !blockEsc && setShowModal(false)}>
                <EditFormikMask {...{
                    title,
                    fields,
                    onSubmit: (x) => Promise.resolve(onSubmit(x)).finally(setShowModal(false)),
                    validations,
                    original,
                    cancelAction: () => setShowModal(false),
                    fieldUpdates,
                }} /></Modal>
        }
    </>
}

// fieldUpdates: [fieldName] -> (update(value) -> newValue)
export function EditFormikMask({
    title, 
    fields, 
    onSubmit, 
    validations = {}, 
    original = {}, 
    cancelAction = () => {}, 
    fieldUpdates = () => ({}),
    mainActionName = "save"
}) {

  const {t} = useTranslation()
  const initialValues = fields.reduce((acc, field) =>
    ({ ...acc, [field.name]: makeInitial(field, original) })
    , {})

  return (
    <Formik
      initialValues={initialValues}
      validate={values => {
        // TODO validation with yup?
        const errors = {}
        fields.forEach(field => {
          if (field.required && (values[field.name] === undefined || values[field.name] === ''))
            errors[field.name] = t('required')
          else if (field.name in validations) {
            const err = validations[field.name](values[field.name], values)
            if (err)
              errors[field.name] = err
          }
        })
        return errors
      }}
      onSubmit={(vs) => onSubmit(_.pickBy(vs, v => v !== ''))}
    >{({ submitForm, isSubmitting, errors, values, setFieldValue }) => {
        Object.entries(fieldUpdates()).forEach(([fieldName, update]) => setFieldValue(fieldName, update(values[fieldName])))
        const formFields = fields.map(field => [field, ...makeField(field, field.name in errors, t)])
        const unhiddenFields = formFields.filter(([f, ff, isHidden]) => !isHidden).length
        return (
            <Form className="flex flex-col gap-2" >
                <h3 className="text-center p-2 pt-6">{title}</h3>
                <div className={`${unhiddenFields > 8 
                        ? "md:columns-3 sm:columns-2 gap-6" 
                        : unhiddenFields >= 6
                        ? "sm:columns-2 gap-6"
                        : ""} sm:px-6 px-4`}>
                    {formFields.map(([field, formField, isHidden]) => {
                        const isBoolean = field.type === "boolean" || field.type === "trinary"
                        return isHidden
                            ? formField
                            : (
                                <div key={field.name} className="flex flex-col gap-1 pb-2 break-inside-avoid-column">
                                    <div className={`flex ${isBoolean ? "flex-row-reverse gap-2 items-center justify-end" : "flex-col gap-1"}`}>
                                        <label
                                            className={`${(!isBoolean) && "pt-1"} shrink-0 whitespace-nowrap capitalize font-medium text-left`}
                                            htmlFor={field.name}
                                        >
                                            {t(field.name) ?? field.name}
                                        </label>
                                        {formField}
                                    </div>
                                    <ErrorMessage name={field.name} component="div" className="text-red-600" />
                                </div>
                            )
                    })}
                </div>

                <div className="flex flex-row justify-end gap-4 p-4 sm:px-6 px-4 bg-pc-200">
                    {/* This is a bit annoying: not as button/input to prevent submission via Enter */}
                    <div 
                        className="btn-secondary cursor-pointer" 
                        onClick={cancelAction} 
                    >
                        {t('cancel')}
                    </div>
                    <div 
                        className="btn-primary cursor-pointer" 
                        onClick={() => !isSubmitting && submitForm()}
                    >
                        {t(mainActionName)}
                    </div>
                </div>
            </Form>
        )
        }}
    </Formik>
  )
}

function makeInitial(field, original) {
  if (original[field.name] !== undefined)
    return original[field.name]

  if (field.defaultValue)
    return field.defaultValue

  switch(field.type) {
    case "multi-select":
    case "tags":
      return []
    case "enum":
      return field.values[0]
    case "const": 
      return field.value
    case "boolean": 
      return false
    case "trinary": 
      return undefined
    case "int": 
      return 0
    case "date":
      return undefined // will be a '' in the DatePicker
    default:
      return ''
  }
}

function makeField(field, inError, t) {
    const commonStyle = `py-1 grow ${inError && "border-red-500"}`
    // missing from original: (str[]), text-area, rich-text, tags, int, 
    let isHidden = false
    // a bit ugly: this fct mutates `isHidden` where necessary
    function mkField() {
        switch (field.type) {
            case "multi-select":
                return <TagListField {...{
                    name: field.name,
                    availableTags: field.available,
                    tagDisplays: field.display,
                    addButton: field.addButton,
                    title: field.name,
                }} />
            case "tags":
                return <TagListField {...{
                    name: field.name,
                    availableTags: field.available,
                    tagDisplays: field.display,
                    addButton: field.addButton,
                    title: field.name,
                    allowNew: true,
                }} />
            case "date":
                return <Field id={field.name} className={`form-input ${commonStyle}`} name={field.name} as={DatePicker} />
            case "enum":
                return (
                    <Field
                        id={field.name}
                        className={`form-select ${commonStyle}`}
                        name={field.name}
                        as="select"
                    >
                        {field.values.map(value => <option key={value} value={value}>{t(value) ?? value}</option>)}
                    </Field>
                )
            case "boolean":
                return <Field id={field.name} className="form-checkbox" name={field.name} type="checkbox" />
            case "trinary":
                return <Trinary id={field.name} name={field.name} />
            case "const":
                isHidden = true
                return <Field key={field.name} id={field.name} name={field.name} type="hidden" value={field.value} />
            case "textarea": // use the type to deduce field
                return <Field id={field.name} as="textarea" className={`form-textarea h-48 ${commonStyle}`} type={field.type} name={field.name} />
            default: // use the type to deduce field
                if (field.proposals) {
                    return <>
                        <Field id={field.name} list={`${field.name}s`} className={`form-input ${commonStyle}`} type={field.type} name={field.name} />
                        <datalist id={`${field.name}s`}>
                            {field.proposals.map(p => <option key={p} value={p} />)}
                        </datalist>
                    </>
                } else
                    return <Field id={field.name} className={`form-input ${commonStyle}`} type={field.type} name={field.name} />
        }
    }
    const f = mkField()
    return [f, isHidden]
}