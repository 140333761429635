import React from "react";
import _ from "lodash";
import { useCrud, useLinkedCrud } from "../BackendProvider";
import { useRoles } from "../user/Auth";
import { trade_mark, trademark_agent_link, trademark_class, trademark_family, trademark_product_link } from "../data";
import { Agent } from "../agents/utils";
import { MutateOptions, useQueryClient } from "@tanstack/react-query";
import { ErrorMessage } from "../Messages";
import { useAgents } from "../agents/AgentsProvider";

export interface Trademark {
    trademarkId?: number;
    familyId: number;
    reference: string;
    externalReference?: string;
    priorityId?: number;
    countryCode: string;
    brandType: BrandType;
    words?: string;

    applicationNumber?: string;
    applicationDate?: string;
    registrationNumber?: string;
    registrationDate?: string;

    renewalDate?: string;
    usageProofDate?: string;
    status: BrandStatus;
    fromWipo?: boolean;
    url?: string;
}

export type BrandType = '3D' | 'Combined' | 'Image' | 'Position' | 'Sound' | 'Smell' | 'Word' |
    'Colour' | 'Hologram' | 'Motion' | 'Pattern' | 'Multimedia' | 'Motion' | 'Other'

export type BrandStatus = 'planned' | 'applied' | 'registered' | 'stopped'
export type BrandProtectionStatus = 'unknown' | 'granted' | 'refused'

export interface TrademarkClass {
    classId?: number;
    trademarkId: number;
    number: number;
    description: string;
}

export interface TrademarkFamily {
    familyId?: number;
    name: string;
    reference: string;
    externalReference?: string;
    description: string;
}

export interface TrademarkProductMapping {
    familyId: number;
    commodityId: number;
    protected: boolean;
}

export interface TrademarkAgentLink {
    trademarkId: number;
    agentId: number;
    type: 'owner' | 'contact';
}

const TrademarksContext = React.createContext({
    trademarks: [] as Trademark[],
    postTrademark: (trademark: Trademark, options?: MutateOptions<Trademark, ErrorMessage, Trademark>) => Promise.resolve(trademark),
    deleteTrademark: (trademark: Trademark) => Promise.resolve({}),

    trademarkFamilies: [] as TrademarkFamily[],
    postTrademarkFamily: (trademarkFamily: TrademarkFamily, options?: MutateOptions<TrademarkFamily, ErrorMessage, TrademarkFamily>) => Promise.resolve(trademarkFamily),
    deleteTrademarkFamily: (trademarkFamily: TrademarkFamily) => Promise.resolve({}),

    trademarkClasses: [] as TrademarkClass[],
    postTrademarkClass: (trademarkClass: TrademarkClass, options?: MutateOptions<TrademarkClass, ErrorMessage, TrademarkClass>) => Promise.resolve(trademarkClass),
    deleteTrademarkClass: (trademarkClass: TrademarkClass) => Promise.resolve({}),

    trademarkProductMappings: [] as TrademarkProductMapping[],
    postTrademarkProductMapping: (arg: (TrademarkProductMapping | TrademarkProductMapping[])) => Promise.resolve(arg),
    deleteTrademarkProductMapping: (arg: (TrademarkProductMapping | TrademarkProductMapping[])) => Promise.resolve({}),

    trademarkAgentLinks: [] as TrademarkAgentLink[],
    postTrademarkAgentLink: (arg: (TrademarkAgentLink | TrademarkAgentLink[])) => Promise.resolve(arg),
    deleteTrademarkAgentLink: (arg: (TrademarkAgentLink | TrademarkAgentLink[])) => Promise.resolve({}),

    trademarkById: {} as Record<number, Trademark>,
    trademarkByReference: {} as Record<string, Trademark>,
    trademarksByFamilyId: {} as Record<number, Trademark[]>,

    trademarkFamilyById: {} as Record<number, TrademarkFamily>,
    trademarkFamilyByReference: {} as Record<string, TrademarkFamily>,

    trademarkClassesByTrademarkId: {} as Record<number, TrademarkClass[]>,

    trademarkProductMappingsByFamilyId: {} as Record<number, TrademarkProductMapping[]>,
    trademarkProductMappingsByCommodityId: {} as Record<number, TrademarkProductMapping[]>,

    ownersByTrademarkId: {} as Record<number, Agent[]>,
    contactsByTrademarkId: {} as Record<number, Agent[]>,
    // type -> agentId -> trademarkIds
    agentsLookup: {} as Record<string, Record<number, number[]>>,
    // type -> trademarkId -> agentIds
    agentsByTrademarkId: {} as Record<string, Record<number, number[]>>,

    isLoading: false as boolean,
    reload: () => {},
})

function linksById(links: TrademarkAgentLink[], agentById: Record<number, Agent>) {
    return _(links)
        .groupBy("trademarkId")
        .mapValues(ls => ls.map(l => agentById[l.agentId]).filter(Boolean))
        .value()
}


export function TrademarkProvider({children}) {
    const {hasBrands} = useRoles()

    const {agentById} = useAgents()
    const queryClient = useQueryClient()

    const {data: trademarkFamilies, postMutation: postTrademarkFamily, deleteMutation: deleteTrademarkFamily, isLoading: isLoadingFamilies} = 
        useCrud<TrademarkFamily>(trademark_family, t => t.familyId, hasBrands, [trade_mark, trademark_class, trademark_product_link, trademark_agent_link])
    const {data: trademarks, postMutation: postTrademark, deleteMutation: deleteTrademark, isLoading: isLoadingTrademark} =
        useCrud<Trademark>(trade_mark, t => t.trademarkId, hasBrands, [trademark_class, trademark_product_link, trademark_agent_link])
    const {data: trademarkClasses, postMutation: postTrademarkClass, deleteMutation: deleteTrademarkClass, isLoading: isLoadingClasses} =
        useCrud<TrademarkClass>(trademark_class, t => t.classId, hasBrands)

    const {data: trademarkProductMappings, postMutation: postTrademarkProductMapping, deleteMutation: deleteTrademarkProductMapping, isLoading: isLoadingMapping} =
        useLinkedCrud<TrademarkProductMapping>(trademark_product_link, (a, b) => a.familyId === b.familyId && a.commodityId === b.commodityId)

    const { data: trademarkAgentLinks, postMutation: postTrademarkAgentLink, deleteMutation: deleteTrademarkAgentLink, isLoading: isLoadingAgents } =
        useLinkedCrud<TrademarkAgentLink>(
            trademark_agent_link,
            (a, b) => a.trademarkId === b.trademarkId && a.agentId === b.agentId && a.type === b.type,
            hasBrands)

    const isLoading = isLoadingFamilies || isLoadingTrademark || isLoadingClasses || isLoadingMapping || isLoadingAgents

    function reload() {
        [trademarkFamilies, trademarks, trademarkClasses, trademarkProductMappings, trademarkAgentLinks].forEach(q => queryClient.invalidateQueries({queryKey: [q]}))
    }

    const trademarkClassesByTrademarkId = _(trademarkClasses).groupBy("trademarkId").mapValues(cs => _.sortBy(cs, "number")).value()

    const trademarkProductMappingsByFamilyId = _.groupBy(trademarkProductMappings, "familyId")
    const trademarkProductMappingsByCommodityId = _.groupBy(trademarkProductMappings, "commodityId")

    const trademarkById = _.keyBy(trademarks, "trademarkId")
    //console.log({trademarkById})
    const trademarksByFamilyId = _.groupBy(trademarks, "familyId")
    const trademarkFamilyById = _.keyBy(trademarkFamilies, "familyId")

    const trademarkByReference = _.keyBy(trademarks, "reference")
    const trademarkFamilyByReference = _.keyBy(trademarkFamilies, "reference")

    const [owners, contacts] = _.partition(trademarkAgentLinks, l => l.type === 'owner')

    const ownersByTrademarkId = linksById(owners, agentById)
    const contactsByTrademarkId = linksById(contacts, agentById)

    // type -> agentId -> trademarkIds
    const agentsLookup: Record<string, Record<number, number[]>> = _(trademarkAgentLinks)
        .groupBy(t => t.type)
        .mapValues(ls => _(ls)
            .groupBy(t => t.agentId)
            .mapValues(vs => vs.map(v => v.trademarkId))
            .value())
        .value()

    // type -> trademarkId -> agentIds
    const agentsByTrademarkId: Record<string, Record<number, number[]>> = _(trademarkAgentLinks)
        .groupBy(t => t.type)
        .mapValues(ls => _(ls)
            .groupBy(t => t.trademarkId)
            .mapValues(vs => vs.map(v => v.agentId))
            .value())
        .value()

    const value = {
        trademarkFamilies, postTrademarkFamily, deleteTrademarkFamily,
        trademarks, postTrademark, deleteTrademark,
        trademarkById, trademarkFamilyById, trademarksByFamilyId,
        trademarkByReference, trademarkFamilyByReference,
        trademarkClasses, postTrademarkClass, deleteTrademarkClass,
        trademarkClassesByTrademarkId,
        trademarkProductMappings, postTrademarkProductMapping, deleteTrademarkProductMapping,
        trademarkProductMappingsByFamilyId, trademarkProductMappingsByCommodityId,
        trademarkAgentLinks, postTrademarkAgentLink, deleteTrademarkAgentLink,
        ownersByTrademarkId, contactsByTrademarkId, agentsLookup, agentsByTrademarkId,
        isLoading, reload,
    }

    return (
        <TrademarksContext.Provider value={value}>
            {children}
        </TrademarksContext.Provider>
    )
}

export function useTrademarks() {
    return React.useContext(TrademarksContext)
}   