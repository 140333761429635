import React, { useContext, useState } from "react";
import { Helmet } from "react-helmet-async"
import { useNavigate, Outlet } from "react-router";
import { createSearchParams, Link, useSearchParams } from "react-router-dom";
import { useTranslation, Trans } from "react-i18next";
import { Popover, PopoverButton, PopoverPanel } from '@headlessui/react'
import { ArrowPathRoundedSquareIcon, CheckCircleIcon, ChevronDownIcon, ExclamationTriangleIcon } from "@heroicons/react/24/outline";
import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/20/solid";
import clsx from "clsx";
import _ from 'lodash'

import { patentIpSubTypes, utilityModelIpSubTypes, origins, statuses } from ".";
import { useRoles } from "../user/Auth";
import { linkDifference } from "../BackendProvider";
import { MemberDetails } from '../patents/FamilyMember'
import { familyUrl } from "../patents/utils";
import { capitalize, nonEmptyString, nonEmptyDate } from "../utils/strings";
import Modal from "../components/Modal";
import { IconChevronDown, IconChevronUp } from "../components/icons";
import { PcCostCenter, PcCostCenterLink, PcIpRight, useDennemeyer, useSynchronize } from "./DennemeyerProvider";
import { FamilyDetails, FamilyRibbons } from "./components";
import { memberUrl } from "../patents/utils";
import { useLocalState } from "../settings/localStorage";
import { Member, Family } from "../patents/patents";
import { deriveInitialIpRight } from "./utils";
import { useFilteredPatents } from "../filter/FilteredPatents";
import { usePatents } from "../patents/PatentsProvider";
import { NoOwnerModal } from "./import/ImportModal";
import { addCostCenterUrl } from "./settings";

{/* TODO: Delete? Or incorporate with standard import? */}

/*
  Overview: 
    Not fully imported / work in progress: isReady(member) || ipRight.dirty || (hasSyncedIpRight(member) && hasValidationErrors(member))

    will have validation errors,     (!isReady(member))
    has been imported & good,     (hasSyncedIpRight(member) && !hasValidationErrors(member))
    stopped/no need to import (Inactive)    (isStopped(member) || isStopped(ipRight))
    all
*/

export default function ImportView() {
    const {t} = useTranslation()
    const { isEditUser } = useRoles()
    const {triggerSynchronize, synchronizing} = useSynchronize()

    const {members} = useFilteredPatents()
    const {ipRightByMemberId, validationsByIpRightId} = useDennemeyer()

    const [searchParams] = useSearchParams()
    const statusParam = searchParams.get('status')

    function hasValidationErrors(ipRight: PcIpRight) {
        const validations = validationsByIpRightId[ipRight.ipRightId]
        return validations && validations.length > 0
    }

    // first filter for if there is an ip right
    const [imported, notImported] = _.partition(members, (m: Member) => ipRightByMemberId[m.familyMemberId])
    const [ready, willHaveErrors] = _.partition(notImported.filter(m => m.familyMemberStatus !== 'stopped' && m.familyMemberStatus !== 'in-preparation'), isReady)

    const [synchronized, notSynchronized] = _.partition(imported, (m: Member) => !ipRightByMemberId[m.familyMemberId].dirty)

    const importedAndGood = synchronized.filter((m: Member) => {
        const ipRight = ipRightByMemberId[m.familyMemberId]
        return !hasValidationErrors(ipRight)
    })

    const importedAndErrors = synchronized.filter((m: Member) => {
        const ipRight = ipRightByMemberId[m.familyMemberId]
        return hasValidationErrors(ipRight)
    })

    const stopped = members.filter((m: Member) => {
        return m.familyMemberStatus === 'stopped' || 
            m.familyMemberStatus === 'in-preparation' || 
            ipRightByMemberId[m.familyMemberId]?.status === 'Inactive'
    })

    const wip = [...ready, ...notSynchronized, ...importedAndErrors]

    const data = [
        {count: wip.length,                 key: 'work-in-progress',              label: t('renewals-desc.wip'),         members: wip, type: 'active'},
        {count: willHaveErrors.length,      key: 'will-have-errors',              label: t('renewals-desc.not-yet-ready'), members: willHaveErrors, type: 'bad'},
        {count: importedAndGood.length,     key: 'has-been-imported-and-good',    label: t('renewals-desc.sent'),          members: importedAndGood, type: 'good'},
        {count: stopped.length,             key: 'stopped-no-import',             label: t('renewals-desc.ignored'),       members: stopped, type: 'neutral'},
        {count: members.length,             key: 'all',                           label: t('all-ip-rights'), members: members, type: 'active'},
    ]//.map(d => ({...d, label: d.key === 'all' ? t('all-ip-rights') : t(d.key, {count: d.count})})) //.filter(d => d.count > 0)

    //console.log({statusParam})
    const specificMembers = data.find(d => d.key === statusParam)?.members

    // <h3 className="text-2xl text-pcx-500 font-semibold tracking-wide mb-4">{t('overview-portfolio')}</h3>
    const title = statusParam ? t(statusParam, {count: specificMembers.length}) : t('overview-portfolio')

    const synchButtonMakesSense = statusParam !== 'will-have-errors' && statusParam !== 'stopped-no-import'

    return <>
        {/* @ts-ignore */}
        <Helmet>
            <title>{t('import')} | {t('renewal-fees')} | Patent Cockpit</title>
        </Helmet>
        <div className="header-row">
            <div className="flex flex-row max-w-4xl gap-4">
                <h2 className="mr-auto modern-h2">{title.substring(0, 1).toLocaleUpperCase()}{title.substring(1)}</h2>
                {/* Reload validations after synchronize (with a delay of a couple of seconds) */}
                {isEditUser && synchButtonMakesSense && <button 
                    className="btn-secondary disabled:btn-disabled py-px" 
                    onClick={() => triggerSynchronize()}
                    disabled={notSynchronized.length === 0}
                >{synchronizing ? t("renewals-desc.sending") : t("renewals-desc.send")}</button>}
            </div>
        </div>
        <div className="main-content bg-pcx-100 pb-20 pt-0">
            {specificMembers 
                ? <SpecificMembersTable {...{specificMembers, statusParam, noImport: statusParam === 'will-have-errors'}} /> 
                : <OverviewTable {...{data}} />}
        </div>
        <Outlet />
    </>
}

// TODO: delete constancts
//const activeType = 'active'
//const neutralType = 'neutral'
//const goodType = 'good'
//const badType = 'bad'
type CountType = 'active' | 'neutral' | 'good' | 'bad'

function Count({count, type}: {count: number, type: CountType}) {
    return <span className={clsx(
        type === 'active' && count > 0 ?  "text-pcx-400 group-hover:text-pcx-500" :
        type === 'good' ?    "text-teal-400 group-hover:text-teal-500" :
        type === 'bad' ?     "text-warn-400 group-hover:text-warn-500" : 
        "text-slate-500 group-hover:text-slate-400", // neutral
        "text-3xl font-bold block w-12 text-right"
    )}>{count}</span>
}

function LinkCard({status, label, count, type}: {status: string, label: string, count: number, type: CountType}) {
    const {t} = useTranslation()

    const cardStyle = "rounded-lg bg-white shadow-sm px-6 py-4 space-y-2 last:lg:col-span-2"

    if (count === 0) {
        return <div className={cardStyle}>
            <Count {...{count, type}} />
            <div className="text-base text-slate-600">{label}</div>
        </div>
    } else {
        return <Link to={{search: `?status=${status}`}} className={clsx(cardStyle, "flex-wrap  group hover:shadow")}>
            <div className="flex flex-row justify-between items-baseline">
                <Count {...{ count, type }} />
                <div className="text-base text-pcx-500 group-hover:underline ml-auto whitespace-nowrap" > {t('view')} <ChevronRightIcon className="inline w-6 h-6 mb-1" /> </div>
            </div>
            <div className="text-base text-slate-600 group-hover:text-slate-500">{label}</div>
        </Link>
    }
}


function OverviewTable({data}) {
    const {t} = useTranslation()

    return (
        <div className="space-y-10 max-w-4xl pt-4">
            <div className="grid grid-cols-1 lg:grid-cols-2 gap-6">
                {data.map(({ count, key, label, type }) => <LinkCard key={key} {...{status: key, count, label, type}} />)}
            </div>
            <div className="px-1 space-y-2 max-w-prose">
                <h4 className="">{t('how-to-synchronize')}</h4>
                <ul className="text-slate-500 list-outside pl-4 space-y-3">
                    <li><Trans i18nKey='first-import' /> </li>
                    <li>{t('next-synchronize')}</li>
                    <li>{t('fix-validation-errors')}</li>
                </ul>
            </div>
        </div>
    )
}

function isReady(member: Member) {
    return (
        nonEmptyDate(member.applicationDate) && nonEmptyString(member.applicationNumber) &&
        nonEmptyDate(member.publicationDate) && nonEmptyString(member.publicationNumber) &&
        ((nonEmptyDate(member.patentDate) && nonEmptyString(member.patentNumber) && member.familyMemberStatus === 'granted') || member.familyMemberStatus === 'pending')
    )
}


const SelectionContext = React.createContext({
    selected: {} as Record<number, boolean>,
    setSelected: (familyMemberId: number, isSet: boolean) => {}}
)


function SpecificMembersTable({specificMembers, statusParam, noImport = false}: {specificMembers: Member[], statusParam: string, noImport: boolean}) {
    const {t} = useTranslation()
    const { isEditUser } = useRoles()

    function setAll(b: boolean): Record<number, boolean> {
        return _(specificMembers).map(member => [member.familyMemberId, b]).fromPairs().value()
    }

    const [selected, _setSelected] = useState(setAll(false))

    const allSelected = specificMembers.length > 0 && _(specificMembers).map(m => selected[m.familyMemberId]).every(Boolean)
    const setSelected = (familyMemberId: number, isSet: boolean) => _setSelected(ss => ({...ss, [familyMemberId]: isSet}))
    //const allSelected = specificMembers.length > 0 && specificMembers.reduce((prev, member) => prev && (selected[member.familyMemberId] ?? false), true)

    const selected_members = specificMembers.filter(member => selected[member.familyMemberId])
    const params = createSearchParams({ familyMemberId: selected_members.map(m => '' + m.familyMemberId) })

    const noSelected = _(selected).values().reduce((prev, value) => prev && !value, true)
    const importButton = (noImport || !isEditUser) ? null :
        <Link 
            aria-disabled={noSelected} to={`edit?${params}&status=${statusParam}`} 
            className={clsx(
                noSelected ? 'btn-disabled pointer-events-none' : 'btn-primary',
                "block ml-auto w-fit px-3  mb-4",
            )}
        >{t('renewals-desc.mark')}</Link>

    //const title = t(statusParam, {count: specificMembers.length})

    return <>
        <Link to={{ search: '' }} className="text-slate-500 hover:text-slate-600 font-base text-lg group block pb-2">
            <ChevronLeftIcon className="w-6 h-6 inline mb-1" /><span className="group-hover:underline">{t('back')}</span>
        </Link>

        <div className="bg-white rounded-xl p-4 shadow-sm max-w-4xl ">
            {/*<h3 className="pl-1 mt-6 mb-6 text-4xl text-pcx-600">{title.substring(0, 1).toLocaleUpperCase()}{title.substring(1)}</h3>*/}

            {importButton}

            <div className="mt-2 pt-5 pb-3 pl-2 flex flex-row justify-between">
                <div className="text-pcx-600 text-sm font-semibold uppercase tracking-wider">{t('internalReference')}</div>
                {noImport
                    ? <div className="text-pcx-600 text-sm font-semibold uppercase tracking-wider">
                        {t('errors-missing')} {isEditUser && <span className="font-medium normal-case text-base tracking-normal ml-5 text-transparent sr:hidden hidden sm:inline select-none">{t('edit')}</span>}
                    </div>
                    : isEditUser && <label className="text-pcx-600 text-sm font-semibold uppercase tracking-wider block">
                        {t('all')} <input className="form-checkbox ml-2 mb-1" type="checkbox" checked={allSelected} onChange={e => _setSelected(setAll(e.target.checked))} />
                    </label>}
            </div>
            <SelectionContext.Provider value={{ selected, setSelected }}>
                <div className="mb-4 border-b border-pcx-200">
                    {_(specificMembers)
                        .sortBy('internalReference')
                        .map(member => noImport
                            ? <PossibleErrorsRow key={member.familyMemberId} member={member} />
                            : <RenewalMemberRow key={member.familyMemberId} {...{
                                member,
                            }} />
                        ).value()}
                </div>
            </SelectionContext.Provider>
            {importButton}
        </div>
    </>
}


function RightEdit({member, ipRight, ownerId, onChange = (newState) => {}}) {
    const {t} = useTranslation()

    const {owners, ownerById, validationsByIpRightId, costCentersByIpRightId} = useDennemeyer()
    const ownerIds = _(owners).sortBy('name').map('ownerId').value()

    const [workingCopy, setWorkingCopy] = useState({...ipRight, ownerId, costCenters: costCentersByIpRightId[ipRight.ipRightId] ?? []})

    //console.log({workingCopy})

    function propageteChange(field: string, value: any) {
        const newState = {...workingCopy, [field]: value}
        setWorkingCopy(newState)
        onChange(newState)
    }

    const validations = validationsByIpRightId[ipRight.ipRightId]

    const ipSubTypes = ipRight.ipType === 'patent' ? patentIpSubTypes : utilityModelIpSubTypes

    return (
        <div className="flex flex-col sm:flex-row gap-2 sm:items-center text-slate-600 mb-2">
            <div className="grow font-medium">
                <MemberInfoHover member={member} >
                    {member.internalReference}
                </MemberInfoHover>
            </div>
            <div className="w-7 my-auto mx-1">
                {validations && <ValidationErrors validations={validations} member={member} right /> }
            </div>
            <select className="form-select py-1 sm:w-48" value={workingCopy.ipSubType} onChange={e => propageteChange('ipSubType', e.target.value)}>
                {ipSubTypes.map(type => <option key={type} value={type}>{capitalize(type)}</option>)}
            </select>
            <select className="form-select py-1 sm:w-32" value={workingCopy.origin} onChange={e => propageteChange('origin', e.target.value)}>
                {origins.map(orig => <option key={orig.name} value={orig.name}>{t(orig.description)}</option>)}
            </select>
            <select className="form-select py-1 sm:w-32" value={workingCopy.status} onChange={e => propageteChange('status', e.target.value)}>
                {statuses.map(stat => <option key={stat} value={stat}>{stat}</option>)}
            </select>
            <select className="form-select py-1 sm:w-48" value={workingCopy.ownerId} onChange={e => propageteChange('ownerId', parseInt(e.target.value))}>
                {ownerIds.map(id => <option key={id} value={id}>{ownerById[+id]?.name ?? ''}</option>)}
            </select>
            <CostCenterSelection 
                className="sm:w-40 " value={workingCopy.costCenters} 
                onChange={e => propageteChange('costCenters', e.target.value)} ipRightId={ipRight.ipRightId} 
            />
        </div>
    )
}

type PcLinkedCostCenter = PcCostCenter & PcCostCenterLink

type CostCenterSelectionProps = {
    ipRightId: number
    value: PcLinkedCostCenter[]
    onChange: (e: {target: {value: PcLinkedCostCenter[]}}) => void
    className?: string
    disabled?: boolean
}

export function CostCenterSelection(
    {ipRightId, value, onChange, className, disabled = false}: CostCenterSelectionProps
    ) {
    const {t} = useTranslation()
    const {costCenters} = useDennemeyer()

    const total = _(value).map(({percentage}) => percentage).sum()
    // eslint-disable-next-line eqeqeq
    const isNot100 = total != 100 && total != 0
    const byId = _.keyBy(value, 'costCenterId')

    // eslint-disable-next-line eqeqeq
    const notSetYet = total == 0

    function handleChange(costCenterId: number, name: string, percentage: number) {
        const newCcs = {...byId, [costCenterId]: {costCenterId, ipRightId, percentage, name}}
        const newValue = _(newCcs).values().filter(({percentage}) => percentage > 0).value()
        //console.log({newValue, newCcs, costCenterId, percentage})
        onChange({target: {value: newValue}})
    }

    return (
    <Popover as="div" className={clsx("relative w-full h-full", className)}>
        <PopoverButton disabled={disabled} className={clsx(
            "flex flex-row items-center w-full gap-1 px-3 py-2 text-left rounded",
            disabled ? "border-0" : "border",
            isNot100 ? "border-warn-500" : " border-slate-400 "
        )}>
            <div className={clsx("grow overflow-hidden text-ellipsis whitespace-nowrap", isNot100 ? "text-warn-700" : " text-slate-600")}>{
                value.length > 0 ? value.map(({ name }) => name).join(', ') : <span className="text-slate-400">{t('no-cost-center')}</span>
            }</div>
            {disabled !== true && <span className="text-pcx-600 h-5 w-5 shrink-0"><ChevronDownIcon /></span>}
        </PopoverButton>
        <PopoverPanel as="div" className="absolute z-10 right-0 top-10 p-4 bg-white rounded-sm border border-slate-400 shadow-md">
            <table>
                <thead className="text-left text-pcx-600 text-sm font-semibold uppercase tracking-wider">
                    <tr>
                        <th className="pr-4 pb-3">{t('name')}</th>
                        <th className="pr-4 pb-3">{t('percentage')}</th>
                    </tr>
                </thead>
                <tbody>
                    {_(costCenters)
                        .sortBy(c => c.name.toLocaleLowerCase())
                        .map(({ costCenterId, name }) =>
                            <tr key={costCenterId}>
                                <td className="font-semibold text-pcx-900 pb-2 pr-2 whitespace-nowrap">
                                    <button type="button" disabled={!notSetYet} className="w-full text-left" onClick={() => handleChange(costCenterId, name, 100)} >
                                        {name}
                                    </button>
                                </td>
                                <td className="pb-2">
                                    <div className="border border-slate-400 focus-within:border-pcx-500 flex justify-end items-center">
                                        <input
                                            type="number" className="text-right w-20 py-1 focus:ring-0 focus:outline-none"
                                            value={byId[costCenterId]?.percentage ?? 0}
                                            onChange={e => handleChange(costCenterId, name, parseInt(e.target.value))}
                                        />
                                        <span className="px-2 text-slate-500 pointer-events-none">%</span>
                                    </div>
                                </td>
                            </tr>)
                        .value()}
                </tbody>
                <tfoot>
                    <tr className="text-left text-pcx-600 border-t-2 border-pcx-200">
                        <td className="pr-4 py-2  text-sm font-semibold uppercase tracking-wider">{t('total')}</td>
                        <td className={clsx("py-2 text-right pr-px", isNot100 && "text-warn-700")}>{total}<span className="px-2">%</span></td>
                    </tr>
                    {isNot100 && <tr className="">
                        <td colSpan={2} className="text-warn-700 text-right pr-2.5">{t('not-100')}</td>
                    </tr>}
                </tfoot>
            </table>
            <PopoverButton className="btn-secondary text-sm w-full block text-center whitespace-nowrap">{t('save')}</PopoverButton>
            <Link className="btn-tertiary text-sm block text-center whitespace-nowrap mt-2" to={addCostCenterUrl}>{t('add-cost-center')}</Link>
        </PopoverPanel>
    </Popover>
    )
}



export function RenewalsEdit() {
    const {t} = useTranslation()
    const navigate = useNavigate()
    const {members} = usePatents()

    const {
        ipRightByMemberId, postIpRight, 
        owners, ownershipsByIpRight, 
        postOwnershipLink, deleteOwnershipLink, 
        postCostCenterLink, deleteCostCenterLink, costCentersByIpRightId,
     } = useDennemeyer()
    const fallbackOwner = _.sortBy(owners, 'name')[0]
    //console.log({ownershipsByIpRight})
        
    const [searchParams] = useSearchParams()
    const editMembers = searchParams.getAll('familyMemberId')
        .map(id => members.find(m => m.familyMemberId === parseInt(id)))
        .filter(m => m)
    const statusParam = searchParams.get('status')

    const [updatedIpRights, setUpdatedIpRights] = useState({})

    if (fallbackOwner === undefined) return null

    const initialMembers = _(editMembers)
        .sortBy('internalReference')
        .map(member => {
            const ipRight = ipRightByMemberId[member.familyMemberId] ?? deriveInitialIpRight(member)
            const ownerId = ownershipsByIpRight[ipRight.ipRightId]?.[0]?.ownerId ?? fallbackOwner.ownerId
            const costCenters = costCentersByIpRightId[ipRight.ipRightId] ?? []
            return { member, ipRight, ownerId, costCenters }
        })
        .value()

    // TODO validate beforehand
    async function submit() {
        const newIpRights = initialMembers.map(({ member, ipRight, ownerId, costCenters }) =>
            updatedIpRights[member.familyMemberId] ?? { ...ipRight, ownerId, costCenters })
        // TODO: get all the linked cost centers, form the diff, and post/delete the diff
        return Promise.all(newIpRights.map(async (ipRight) => {
            const { ipRightId } = (await postIpRight(ipRight)) as PcIpRight
            const existing = ownershipsByIpRight[ipRightId]?.[0]
            if (existing) {
                await deleteOwnershipLink(existing)
            }
            await postOwnershipLink({ ipRightId, ownerId: ipRight.ownerId, ownerType: 'registered' })

            const [toAdd, toDelete] = linkDifference(
                costCentersByIpRightId[ipRightId] ?? [],
                ipRight.costCenters.map(cc => ({...cc, ipRightId})),
                (a: PcCostCenterLink, b: PcCostCenterLink) => a.ipRightId === b.ipRightId && a.costCenterId === b.costCenterId && a.percentage === b.percentage)

            if (toDelete.length > 0) 
                await deleteCostCenterLink(toDelete)
                //await Promise.all(toDelete.map(d => deleteCostCenterLink(d)))
            if (toAdd.length > 0)
                await postCostCenterLink(toAdd)
                //await Promise.all(toAdd.map(a => postCostCenterLink(a)))

            return ipRight

        })).then(() => navigate(`/renewals/import?status=${statusParam}`))
    }

    if (initialMembers?.length === 0)
        return null
    else if (fallbackOwner === undefined)
        return <NoOwnerModal />
    else
        return (
            <Modal>
                <div className="flex flex-col gap-2 p-4">
                    <h3 className="">{t('renewals-desc.mark')}</h3>
                    <div className="hidden sm:flex flex-row gap-2 justify-end font-medium text-slate-600">
                        {/* These have to match the widths defined in Right Edit
                            TODO: we could use grids and sub-grids
                        */}
                        <div className="w-48 px-px">{t('ipSubType')}</div>
                        <div className="w-32 px-px">{t('origin')}</div>
                        <div className="w-32 px-px">{t('status')}</div>
                        <div className="w-48 px-px">{t('owner')}</div>
                        <div className="w-40 px-px">{t('cost-centers')}</div>
                    </div>
                    {initialMembers.map(({member, ipRight, ownerId}) => {
                        return <RightEdit key={member.familyMemberId}
                            {...{
                                member,
                                ipRight,
                                ownerId,
                                onChange: (ipRight) => setUpdatedIpRights(old => ({ ...old, [ipRight.familyMemberId]: ipRight }))
                            }} />
                    })}
                </div>
                <div className="bg-pcx-200 flex flex-row-reverse gap-2 p-4">
                    <button className="btn-primary" onClick={submit}>{t('save')}</button>
                    {/* @ts-ignore */}
                    <Link className="btn-secondary" to={-1}>{t('cancel')}</Link>
                </div>
            </Modal>
        )
}

export function RenewalsDelete() {
    const {t} = useTranslation()
    const navigate = useNavigate()
    const {members} = usePatents()

    const {deleteIpRight, ipRightByMemberId } = useDennemeyer()
    //console.log({ownershipsByIpRight})
        
    const [searchParams] = useSearchParams()
    const deleteMembers = _(searchParams.getAll('familyMemberId'))
        .map(id => members.find(m => m.familyMemberId === parseInt(id)))
        .filter(m => m !== undefined)
        .sortBy(m => m.internalReference)
        .value()

    async function remove() {
        const rights_to_remove = deleteMembers.map(member => ipRightByMemberId[member.familyMemberId]).filter(r => r)
        return Promise.all(rights_to_remove.map(right => deleteIpRight(right)))
            .then(() => navigate('..'))
    }

    // TODO: only make members removable if they are inactivated
    return (
        <Modal>
            <div className="p-4 max-w-lg">
                <h3 className="mb-2">{t('remove')}</h3>
                <p className="text-slate-600">{t("remove-patents-from-payment")}</p>
                <p className="text-slate-600">{t("remove-hint-pc")}</p>
    
                <ul>
                    {deleteMembers.map(member => 
                        <li key={member.familyMemberId}>
                            <Link to={memberUrl(member)}>{member.internalReference}</Link>
                        </li>)}
                </ul>
            </div>
            <div className="p-4 flex flex-row-reverse gap-4 bg-pcx-200">
                <button className="btn-warn" onClick={remove}>{t('remove')}</button>
                <Link className="btn-secondary" to="..">{t('cancel')}</Link>
            </div>
        </Modal>
    )
}


function RenewalsGroup({ family, members }: { family: Family, members: Member[] }) {
    const navigate = useNavigate()

    function setAll(b: boolean): Record<number, boolean> {
        return _.fromPairs(members.map(member => [member.familyMemberId, b]))
    }

    const [selected, setSelected] = useState(setAll(false))
    const _setSelected = (familyMemberId: number, isSet: boolean) => setSelected(s => ({ ...s, [familyMemberId]: isSet }))
    const allSelected = members.length > 0 && members.reduce((prev, member) => prev && (selected[member.familyMemberId] ?? false), true)

    function handleAction(e) {
        const action = e.target.value
        const selected_members = members.filter(member => selected[member.familyMemberId])
        //if (action === 'remove') {
        //    const params = createSearchParams({familyMemberId: selected_members.map(m => m.familyMemberId)})
        //    navigate(`remove?${params}`)
        //    setSelected(setAll(false))
        //} else 
        if (action === 'edit') {
            const params = createSearchParams({familyMemberId: selected_members.map(m => '' + m.familyMemberId)})
            navigate(`edit?${params}`)
            setSelected(setAll(false))
        }
        e.target.value = 'Action...'
    }

    //console.log({selected})
    return (
        <div className="p-2 border-t-2 border-pcx-300">
            <div className="flex flex-col pb-1">
                <h3 className="pb-2 whitespace-nowrap text-ellipsis overflow-hidden">
                    <Link to={familyUrl(family)}>
                        {family.internalReference}: {family.familyName}
                    </Link>
                </h3>
                <FamilyDetails {...{ family }} />
                <FamilyRibbons {...{ family }} />
                <div className="self-end">
                    <select className="form-select py-1 text-sm mr-2" onChange={handleAction}>
                        <option>Action...</option>
                        <option value="edit">Edit</option>
                        {/*<option value="remove">Remove</option>
                        <option value="manage">Manage</option>*/}
                    </select>
                    <input
                        type="checkbox" className="form-checkbox"
                        checked={allSelected}
                        onChange={e => setSelected(setAll(e.target.checked))}
                    />
                </div>
            </div>
            <SelectionContext.Provider value={{ selected, setSelected: _setSelected }}>
                {_(members)
                    .sortBy('internalReference')
                    .map(member =>
                        <RenewalMemberRow
                            key={member.internalReference} {...{
                                member,
                                selected: selected[member.familyMemberId] ?? false,
                                setSelected: c => setSelected(s => ({ ...s, [member.familyMemberId]: c }))
                            }} />
                    ).value()}
            </SelectionContext.Provider>
        </div>
    )
}

function PossibleErrorsRow({member}) {
    const {t} = useTranslation()
    const { isEditUser } = useRoles()

    const isGranted = member.familyMemberStatus === 'granted'
    const isPending = member.familyMemberStatus === 'pending'
    const hasGrantDate = nonEmptyDate(member.patentDate)
    const hasGrantNumber = nonEmptyString(member.patentNumber)

    const errors = [
        (!isGranted && hasGrantDate && hasGrantNumber) && 'status-not-granted',
        (!isGranted && !isPending) && 'status-not-pending',
        !nonEmptyDate(member.applicationDate) && 'applicationDate',
        !nonEmptyString(member.applicationNumber) && 'applicationNumber',
        !nonEmptyDate(member.publicationDate) && 'publicationDate',
        !nonEmptyString(member.publicationNumber) && 'publicationNumber',
        (isGranted && !hasGrantDate) && 'patentDate',
        (isGranted && !hasGrantNumber) && 'patentNumber',
    ].filter(Boolean)

    return (
        <div className='flex flex-row w-full gap-2 items-center pl-2 py-1 border-t border-pcx-200' >
            <div className="grow flex flex-row justify-end flex-wrap sm:xflex-nowrap gap-2 gap-y-1 ">
                <Link className="whitespace-nowrap mr-auto" to={memberUrl(member)}>{member.internalReference}</Link>

                {errors.map(error => 
                    <div key={error} 
                        className="bg-red-100 border border-red-300 rounded-md px-1 pt-1 text-xs text-red-500 whitespace-nowrap"
                    >{t(error)}</div>
                )}
            </div>
            {isEditUser &&
                <Link to={`${memberUrl(member)}/edit`} className="btn-tertiary ml-4 px-0 py-0 self-start justify-self-end whitespace-nowrap">{t('edit')}</Link>}
        </div>
    )
}

            //key={member.internalReference}
function RenewalMemberRow({member}: {member: Member}) {
    const { isEditUser } = useRoles()
    const {selected: _selected, setSelected} = useContext(SelectionContext)
    const selected = _selected[member.familyMemberId] ?? false

    const { ipRightByMemberId, ownershipsByIpRight, ownerById, validationsByIpRightId, costCentersByIpRightId } = useDennemeyer()
    
    const right = ipRightByMemberId[member.familyMemberId]
    const has_right = right !== undefined
    const owner = ownerById[ownershipsByIpRight[right?.ipRightId]?.[0]?.ownerId]
    const has_owner = owner !== undefined

    const costCenters = (costCentersByIpRightId[right?.ipRightId] ?? []).map(({name}) => name)

    const validations = validationsByIpRightId[right?.ipRightId]
    const dirty = right?.dirty

    const ribbons = [
        has_owner && owner.name,
        has_right && right.origin,
        has_right && right.status,
        costCenters.length > 0 && costCenters.join(', '),
    ].filter(Boolean)

    return (
        <div className='flex flex-row items-center border-t border-pcx-200' >
            <div className="grow flex flex-row flex-wrap sm:flex-nowrap gap-2 gap-y-1 py-1 pl-2">
                <MemberInfoHover member={member}>
                    <Link className="whitespace-nowrap" to={memberUrl(member)}>{member.internalReference}</Link>
                </MemberInfoHover>
                <div className="grow" />
                <div className="flex flex-row gap-2">
                    {ribbons.map(r =>
                        <div key={r} className="ribbon pt-1 pb-0.5 text-sm text-pcx-600">{r}</div>
                    )}
                    {dirty
                        ? <NotSynchronized  {...{validations, ipRightId: right?.ipRightId}} />
                        : validations 
                        ? <ValidationErrors validations={validations} member={member} /> 
                        : has_right 
                        ? <NoValidationErrors />
                        : null}
                </div>
            </div>
            {isEditUser && <label className="py-1 pl-2">
            <input
                type="checkbox" className="form-checkbox"
                checked={selected ?? false}
                onChange={e => setSelected(member.familyMemberId, e.target.checked)}
            /></label> }
        </div>
    )
}

function NotSynchronized({validations, ipRightId, right = false}) {
    const {t} = useTranslation()
    const {triggerSynchronize, synchronizing} = useSynchronize()
    const buttonStyle = "font-medium text-sm px-2 py-1 rounded-md" // text-red-800 hover:bg-red-100 "
    return (
        <div className="relative group">
            <div className="bg-amber-100 text-amber-600 p-1 rounded-sm cursor-pointer relative" title="Not synchronized yet.">
                <ArrowPathRoundedSquareIcon className="h-5 w-5" />
                {validations && <span className="absolute bottom-0 right-0 block h-2 w-2 rounded-full bg-red-400 ring-2 ring-white" />}
            </div>
            <div className={clsx(
                "hidden group-hover:block absolute -top-4 z-10",
                right ? "left-5" : " right-5",
                "p-4", // invisible band around popover to make it easier to hover
            )}>
                <div className="rounded-lg shadow-lg min-w-[64ch] max-w-3xl text-sm">
                    <div className="p-4 pl-7 bg-amber-50 ">
                        <h4 className="font-medium text-amber-800 mb-2">Not yet synchronized</h4>
                        <div className="flex flex-row gap-4 mt-4 -ml-2">
                            <button className={clsx(buttonStyle, "text-amber-800 hover:bg-amber-100") } onClick={() => triggerSynchronize([ipRightId])}>{synchronizing ? "Synchronizing..." : "Synchronize now"}</button>
                        </div>
                    </div>
                    {validations && 
                        <div className="p-4 pl-7 bg-red-50 ">
                            <h4 className="font-medium text-red-800 mb-2">{t('messages-payment-provider', {count: validations.length})}</h4>
                            <ul className="list-disc space-y-2 pl-4">
                                {validations.map(v => 
                                    <li key={v.validationId} className="text-red-700 list-outside" title={v.message}>
                                        {t("dm_messages." + v.errorCode)}
                                    </li>
                                )}
                            </ul>
                        </div>
                    }
                </div>
            </div>
        </div>
    )
}

function NoValidationErrors() {
    return <div className="bg-pcx-100 text-pcx-600 p-1 rounded-sm" title="All good - nothing to do."><CheckCircleIcon className="h-5 w-5" /></div>
}


function ValidationErrors({ validations, member, right = false }) {
    const {t} = useTranslation()
    const {ipRightByMemberId, postIpRight} = useDennemeyer()

    const buttonStyle = "font-medium text-sm text-red-800 px-2 py-1 rounded-md hover:bg-red-100 "

    async function touch() {
        return postIpRight({...ipRightByMemberId[member.familyMemberId], dirty: true})
    }

    return (
        <div className="relative group">
            <div className="bg-red-100 text-red-600 p-1 rounded-sm cursor-pointer"><ExclamationTriangleIcon className="h-5 w-5" /></div>
            <div className={clsx(
                "hidden group-hover:block absolute -top-4 z-10",
                right ? "left-5" : " right-5",
                "p-4", // invisible band around popover to make it easier to hover
            )}>
                <div className="bg-red-50 rounded-lg shadow-lg  p-4 pl-7 min-w-[64ch] max-w-4xl text-sm">
                    <h4 className="font-medium text-red-800 mb-2">{t('messages-payment-provider', {count: validations.length})}</h4>
                    <ul className="list-disc space-y-2 pl-4">
                        {validations.map(v => 
                            <li key={v.validationId} className="text-red-700 list-outside" title={v.message}>
                                {t("dm_messages." + v.errorCode)}
                            </li>
                        )}
                    </ul>
                    <div className="flex flex-row gap-4 mt-4 -ml-2 whitespace-nowrap">
                        <Link onClick={() => touch()} to={`${memberUrl(member)}/edit`} className={buttonStyle}>{t('edit-member', {name: member.internalReference})}</Link>
                        <Link to={`edit?familyMemberId=${member.familyMemberId}`} className={buttonStyle}>{t('edit-import-information')}</Link>
                        <button 
                            className={buttonStyle}
                            onClick={touch}>
                            Ready to synchronize
                        </button>
                    </div>
                </div>
            </div>
        </div>
    )
}

const showAll = 'show-all'

const showDirty = 'show-dirty'
const showError = 'show-error'
const showGood = 'show-good'
const filterValidationValues = [
    showAll,
    showDirty,
    showError,
    showGood,
]

const ascending = 'ascending'
const descending = 'descending'
//const orderFamilyByValues = [
//    ascending,
//    descending,
//]

const showGranted = 'show-granted'
const showInactive = 'show-inactive'
const showPending = 'show-pending'
const showNotSaved = 'show-not-saved'

const filterStateValues = [
    showAll,
    showGranted,
    showInactive,
    showPending,
    showNotSaved,
]

export function RenewalsImport() {
    const {t} = useTranslation()
    const {triggerSynchronize, synchronizing} = useSynchronize()

    const {validationsByIpRightId, ipRightByMemberId} = useDennemeyer()
    const {members, families} = useFilteredPatents()

    const [filterStateValue, setFilterStateValue] = useLocalState('renewals-filter-by-state', filterStateValues[0])
    const [orderFamilyBy, setOrderFamilyBy] = useLocalState('renewals-order-family-by', ascending)
    const [filterValidationValue, setFilterValidationValue] = useLocalState('renewals-filter-by-validation', filterValidationValues[0])

    let filterMemberByState = (member: Member) => true 
    switch(filterStateValue) {
        case showGranted:
            filterMemberByState = ({familyMemberId}) => ipRightByMemberId[familyMemberId]?.status === 'Granted'
            break
        case showInactive:
            filterMemberByState = ({familyMemberId}) => ipRightByMemberId[familyMemberId]?.status === 'Inactive'
            break
        case showPending:
            filterMemberByState = ({familyMemberId}) => ipRightByMemberId[familyMemberId]?.status === 'Pending'
            break
        case showNotSaved:
            filterMemberByState = ({familyMemberId}) => ipRightByMemberId[familyMemberId] === undefined
            break
        default:
            filterMemberByState = () => true
    }

    let filterMemberByValidation = (member: Member) => true
    switch(filterValidationValue) {
        case showDirty:
            filterMemberByValidation = ({familyMemberId}) => ipRightByMemberId[familyMemberId]?.dirty
            break
        case showError:
            filterMemberByValidation = ({familyMemberId}) => 
               validationsByIpRightId[ipRightByMemberId[familyMemberId]?.ipRightId] !== undefined
            break
        case showGood:
            filterMemberByValidation = ({ familyMemberId }) => {
                if (!(familyMemberId in ipRightByMemberId)) return false
                const {ipRightId, dirty} = ipRightByMemberId[familyMemberId]
                return !(ipRightId in validationsByIpRightId) && dirty === false
            }
            break
        default:
            filterMemberByValidation = () => true
    }


    const membersByFamilyId = _(members)
        .filter(filterMemberByState)
        .filter(filterMemberByValidation)
        .groupBy('patentFamilyId')
        .value()

    return <>
        {/* @ts-ignore */}
        <Helmet>
            <title>{t('import')} | {t('renewal-fees')} | Patent Cockpit</title>
        </Helmet>

        <div className="header-row">
            <div className="flex flex-row max-w-4xl gap-4">
                <h2 className="mr-auto">{t('patents')}</h2>
                {/* Reload validations after synchronize (with a delay of a couple of seconds) */}
                <button className="btn-secondary py-px" onClick={() => triggerSynchronize()}>{synchronizing ? "Synchronizing..." : "Synchronize"}</button>
            </div>
        </div>
        <div className="main-content pb-20">
            <div className="p-2 text-gray-500 text-sm flex flex-row gap-8">
                <label>Filter by Status
                    <select 
                        value={filterStateValue} onChange={e => setFilterStateValue(e.target.value)} 
                        className="ml-2 form-select text-sm py-1 pl-2 border-pcx-500 text-pcx-500"
                    >
                        {filterStateValues.map(v => <option key={v} value={v}>{t(v)}</option>)}
                    </select>
                </label>
                <label>Filter by Validation
                    <select 
                        value={filterValidationValue} onChange={e => setFilterValidationValue(e.target.value)} 
                        className="ml-2 form-select text-sm py-1 pl-2 border-pcx-500 text-pcx-500"
                    >
                        {filterValidationValues.map(v => <option key={v} value={v}>{t(v)}</option>)}
                    </select>
                </label>
            </div>
            <div className="pb-2 px-2 flex flex-row gap-2 text-slate-600 text-sm">
                <div>{t('internalReference')}</div>
                <button 
                    className="text-pcx-800" 
                    onClick={() => setOrderFamilyBy(orderFamilyBy === ascending ? descending : ascending)}
                >
                    {orderFamilyBy === ascending 
                        ? <IconChevronDown className="h-5 w-5" /> 
                        : <IconChevronUp className="h-5 w-5" />}
                </button>
            </div>
            <div className=" max-w-4xl">
                {_(families)
                    .sortBy('internalReference')
                    .tap(fs => orderFamilyBy === descending ? fs.reverse() : fs)
                    .filter(({patentFamilyId}) => membersByFamilyId[patentFamilyId] && membersByFamilyId[patentFamilyId].length > 0)
                    .map(family =>
                        <RenewalsGroup key={family.patentFamilyId} {...{
                            family,
                            members: membersByFamilyId[family.patentFamilyId] ?? [],
                            ipRightByMemberId,
                        }} />
                    )
                    .value()}
            </div>
            <Outlet />
        </div>
    </>
}

function MemberInfoHover({className = '', member, children}) {
    const {familyById} = usePatents()
    // not done with pure CSS because performance is otherwise much worse (looks like all the images are being mounted)
    const [showHover, setShowHover] = useState(false)
    return (
        <div className={clsx("relative group/info", className)} onMouseEnter={() => setShowHover(true)} onMouseLeave={() => setShowHover(false)}>
            {children}
            {showHover && <div className="w-40 hidden group-hover/info:block absolute z-10 top-0 left-10 pt-8 bg-transparent">
                <div className="md:min-w-3xl xl:min-w-4xl hidden group-hover/info:block z-10 p-4 border border-pcx-300 bg-white rounded-md shadow-md">
                    <MemberDetails {...{ member, family: familyById[member?.patentFamilyId], isEditUser: false }} />
                </div>
            </div>}
        </div>
    )
}