import { DmStatus, IpRight, PcIpRight, useDennemeyer, useIpRight, useIpRightsPortfolio } from "../DennemeyerProvider";
import { usePatents } from "../../patents/PatentsProvider";
import { deriveInitialIpRight } from "../utils";
import { post_ip_right_to_dennemeyer } from "../dennemeyer_backend";
import { Member } from "../../patents/patents";


export interface PostIpRight {
    familyMemberId: number,
    ipRightId?: number,
    ipType: string,
    ipSubType: string,
    origin: string,
    parent?: number,
    status: DmStatus,
    dennemeyerId?: string,
    ownerId: number,
    costCenters: { costCenterId: number, percentage: number }[], // percentage is 0-100
    firstDueDate?: string,
}

function _deriveInitialIpRight(member: Member, parent?: number, ipRight?: IpRight, pcIpRight?: PcIpRight, ownerId?: number, costCenters?: { costCenterId: number, percentage: number }[]) {
    let initial = {
        ...deriveInitialIpRight(member),
        parent, ownerId, costCenters,
    }

    if (ipRight) {
        initial = {
            ...initial,
            ipType: ipRight.IpType?.toLocaleLowerCase() ?? pcIpRight?.ipType ?? initial.ipType,
            ipSubType: ipRight.IpSubType?.toLocaleLowerCase() ?? pcIpRight?.ipSubType ?? initial.ipSubType,
            origin: ipRight.Origin === 'P.C.T.' ? 'PCT' : ipRight.Origin,
            // Pending, Granted/Registered, Inactive
            status: ipRight.Status.includes('Pending') ? 'Pending' : ipRight.Status.includes('Granted') ? 'Granted' : 'Inactive',
            firstDueDate: pcIpRight?.firstDueDate,
        }
    } else if (pcIpRight) {
        initial = {
            ...initial, ...pcIpRight,
        }
    }
    return initial
}

export function useIpRightsImport(fallbackOwnerId: number) {
    const {parents, memberById} = usePatents()
    const {ipRightByMemberId, ownershipsByIpRight, costCentersByIpRightId, invalidateIpRights, reload: reloadPc} = useDennemeyer()

    const {ipRightByDennemeyerId, isLoading} = useIpRightsPortfolio()

    function deriveInitial({familyMemberId}: {familyMemberId: number}) {
        const member = memberById[familyMemberId]
        const parent = parents[familyMemberId]?.[0]?.familyMemberId

        const pcIpRight = ipRightByMemberId[familyMemberId]
        const ipRight = ipRightByDennemeyerId[pcIpRight?.dennemeyerId]

        const ownerId = ownershipsByIpRight[pcIpRight?.ipRightId]?.[0]?.ownerId ?? fallbackOwnerId
        const costCenters = (costCentersByIpRightId[pcIpRight?.ipRightId] ?? []).map(({costCenterId, percentage, name}) => ({costCenterId, percentage, name}))

        return _deriveInitialIpRight(member, parent, ipRight, pcIpRight, ownerId, costCenters)
    }

    async function postIpRights(ipRight: PostIpRight | PostIpRight[]) {
        await post_ip_right_to_dennemeyer(Array.isArray(ipRight) ? ipRight : [ipRight])
        //reload()
        reloadPc()
        invalidateIpRights()
    }

    return {
        deriveInitial, postIpRights, isLoading,
    }
}

export function useIpRightImport(familyMemberId: number, fallbackOwnerId: number) {
    const {parents, memberById} = usePatents()
    const {ipRightByMemberId, ownershipsByIpRight, costCentersByIpRightId, invalidateIpRights, reload: reloadPc} = useDennemeyer()

    const member = memberById[familyMemberId]
    const pcIpRight = ipRightByMemberId[familyMemberId]

    const {ipRight: ipRightResp, isLoading, reload} = useIpRight(pcIpRight?.dennemeyerId)
    const ipRight = ipRightResp?.Data

    const parent = parents[familyMemberId]?.[0]?.familyMemberId
    // console.log({parent, parents})
    const ownerId = ownershipsByIpRight[pcIpRight?.ipRightId]?.[0]?.ownerId ?? fallbackOwnerId

    const costCenters = (costCentersByIpRightId[pcIpRight?.ipRightId] ?? []).map(({costCenterId, percentage, name}) => ({costCenterId, percentage, name}))
    const initial =  _deriveInitialIpRight(member, parent, ipRight, pcIpRight, ownerId, costCenters)

    /**
    On the backend this
     - saves a placeholder / link in the DB (PcIpRight)
     - saves the linked owner & cost centers
     - saves the parent / origin
     - POSTs the IP right to Dennemeyer
     - if good, updates the placeholder with the Dennemeyer ID
     - otherwise, saves the validation errors
    */
    async function postIpRight(ipRight: PostIpRight | PostIpRight[]) {
        await post_ip_right_to_dennemeyer(Array.isArray(ipRight) ? ipRight : [ipRight])
        reload()
        reloadPc()
        invalidateIpRights()
    }

    return {
        ipRight, initial, postIpRight, isLoading,
    }
}