import { useState } from "react";
import _ from 'lodash'

import { useRoles } from "../user/Auth";
import Modal from "../components/Modal";
import { extractContent } from "../data"
import Image, {PlainImage} from "../components/Image";
import { useMemberClaims } from "./MemberClaimsProvider";
import { useTranslation } from "react-i18next";
import { PureEditor } from "../components/Editor";
import { usePatents } from "../patents/PatentsProvider";
import { useClaims } from "./ClaimsProvider";

export interface ClaimScope {
    claimScopeId?: number,
    claimScopeSummary: string,
}

export function ClaimScopeManager({claim}) {
    const {t} = useTranslation()
    const {claimScopeById} = useClaims()
    const {isEditUser} = useRoles()
                            
    // If claimScopeSummary === undefinded, edit mode is off
    //const [claimScopeSummary, setClaimScopeSummary] = useState(undefined);
    const claimScope = claimScopeById[claim.claimScopeId]
    const claimScopeSummary = claimScope?.claimScopeSummary

    //console.log({claimScopeSummary, workingClaimScopeSummary, lowerSummary, similarScopes})

    const [showMenu, setShowMenu] = useState(false);

    const isDefined = claimScopeSummary !== undefined

    return (
        <div 
            className="w-full"
        >
            {showMenu && <ClaimScopeModal {...{claim, claimScope, claimScopeSummary, setShowModal: setShowMenu}} />}
            <div className={"flex flex-col gap-1 w-full px-1 py-1"}>
                <div className="">
                    <Image {...{
                        entity: "claim-scope",
                        entityId: claimScope?.claimScopeId,
                        title: '',
                        text: claimScopeSummary,
                        isEditable: claimScope !== undefined && isEditUser,
                    }} />
                </div>
                <button
                    onClick={() => setShowMenu(true)}
                    className={`col-span-3 p-1 ${isDefined ? "text-black" : "text-gray-400 hover:text-pcx-600"} text-left border border-white ${isEditUser ? "hover:border-pcx-300" : "cursor-default"} w-full`}
                >
                    <div className="h-full">{isDefined 
                        ?  <div dangerouslySetInnerHTML={{__html: claimScopeSummary}}></div>
                        : isEditUser 
                        ? t('click-edit-subfamily')
                        : ""
                    }</div>
                </button>
            </div>
        </div>
    )
}

function ClaimScopeModal({claim, claimScope, setShowModal}) {
    const {t} = useTranslation()
    const {familyClaims, patentFamilyId} = useMemberClaims()
    const {memberById} = usePatents()
    const {postClaimScope, postClaim, aggregatedClaims, claimScopeById} = useClaims()
    const claimScopeSummary = claimScope?.claimScopeSummary
    const familyMembersByScopeId = _(familyClaims)
        .filter(c => c.claimScopeId !== undefined)
        .map(c => ({
            claimScopeId: c.claimScopeId,
            familyMemberId: c.familyMemberId,
            internalReference: memberById[c.familyMemberId].internalReference,
            claimNumber: c.claimNumber,
        }))
        .groupBy(c => c.claimScopeId)
        .value()
    const familyClaimScopes = _(aggregatedClaims[patentFamilyId]?.allClaimScopes ?? []).map(id => claimScopeById[id]).filter(Boolean).value()

    const [workingClaimScopeSummary, setWorkingClaimScopeSummary] = useState(claimScopeSummary ?? "")

    const lowerSummary = stripTags(workingClaimScopeSummary ?? '').toLowerCase()
    const similarScopes = familyClaimScopes.filter(s => typeof(s.claimScopeId) === 'number' && stripTags(s.claimScopeSummary).toLowerCase().includes(lowerSummary)) as Required<ClaimScope>[]
    const summaryIsEmpty = extractContent(workingClaimScopeSummary ?? "").trim() === ""

    const internalReference = memberById[claim.familyMemberId]?.internalReference

    function cancelAction() {
        setShowModal(false)
    }

    function updateAction() {
        if (claimScope !== undefined) {
            // update an existing claim scope
            postClaimScope({ ...claimScope, claimScopeSummary: workingClaimScopeSummary })
            setShowModal(false)
        }
    }

    function saveAction() {
        postClaimScope({ claimScopeSummary: workingClaimScopeSummary })
            .then((claimScope: ClaimScope) => postClaim({ ...claim, claimScopeId: claimScope.claimScopeId }))
        setShowModal(false)
    }

    function linkAction(claimScopeId) {
        postClaim({ ...claim, claimScopeId })
            .then(() => setWorkingClaimScopeSummary(undefined))
            .then(() => setShowModal(false))
    }

    function unlinkAction() {
        postClaim({ ...claim, claimScopeId: undefined })
            .then(() => cancelAction())
    }
    return (
        <Modal escAction={() => cancelAction()}>
            <div className="max-h-[95vh] w-[95vw] lg:max-w-4xl xl:max-w-6xl ">
                <div className="flex flex-col lg:grid lg:grid-cols-2 gap-4 p-4">
                    <div>
                        <h3 className="mb-4">{t("select-existing-subfamily-feature")}</h3>
                        <div className="grow w-full grid grid-cols-1 gap-2 overflow-y-auto max-h-[30vh] lg:max-h-[70vh]">
                            {similarScopes.map(s => {
                                //const siblings = _.uniqBy((familyMembersByScopeId[s.claimScopeId] ?? [], m => m.internalReference))
                                const siblings = _.sortBy(familyMembersByScopeId[s.claimScopeId] ?? [], 'internalReference')
                                return <ClaimScopeButton key={s.claimScopeId} onClick={() => linkAction(s.claimScopeId)} {...{ ...s, siblings }} />
                            })}
                            <div className="hidden only:block text-slate-600">
                                {t("no-similar-feature-found")}
                            </div>
                        </div>
                    </div>

                    <div className="lg:order-first">
                        <h3 className="mb-4">{t('edit-claim-scope', {name: internalReference})}</h3>
                        <div className="h-[16rem] sm:h-[20rem] resize-y">
                            <PureEditor content={workingClaimScopeSummary ?? ""} onUpdate={({editor}) => setWorkingClaimScopeSummary(editor.getHTML())} />
                        </div>
                    </div>
                </div>

                <div className="flex flex-col sm:flex-row-reverse gap-4 bg-pcx-200 p-4">
                    {claimScope !== undefined
                        ? <>
                            <button disabled={summaryIsEmpty} className={`${summaryIsEmpty ? "btn-disabled" : "btn-primary"} whitespace-nowrap`} onClick={() => updateAction()}>{t('save-changes')}</button>
                            <button disabled={summaryIsEmpty} className={`${summaryIsEmpty ? "btn-disabled" : "btn-primary"} whitespace-nowrap`} onClick={() => saveAction()}>{t('save-as-new')}</button>
                            <button className="btn-primary whitespace-nowrap" onClick={() => unlinkAction()}>{t("unlink-subfamily-feature")}</button>
                        </>
                        : <button disabled={summaryIsEmpty} className={`${summaryIsEmpty ? "btn-disabled" : "btn-primary"} whitespace-nowrap`} onClick={() => saveAction()}>{t('save')}</button>
                    }
                    <div className="grow" />
                    <button className="btn-secondary" onClick={() => cancelAction()}>{t('cancel')}</button>
                </div>
            </div>
        </Modal>
    )
}

type Sibling = {
    internalReference: string,
    claimNumber: number,
}

function ClaimScopeButton({onClick, claimScopeSummary, claimScopeId, siblings}: {onClick: () => void, claimScopeSummary: string, claimScopeId: number, siblings: Sibling[]}) {
    return (
        <button
            className="text-left text-xs border-2 border-pcx-300 rounded-md p-2 hover:bg-pcx-100"
            onClick={onClick}
        >
            <div className="flex flex-row gap-2">
                <div className="h-20 w-32 shrink-0 mb-1"><PlainImage {...{ entity: "claim-scope", entityId: claimScopeId, clickable: false }} /></div>
                <div className="overflow-hidden line-clamp-5 text-ellipsis h-20 mb-1" dangerouslySetInnerHTML={{ __html: claimScopeSummary }} />
            </div>
            <div className="flex flex-row flex-wrap gap-1 mt-2">
                {siblings.map(m =>
                    <div key={m.internalReference + '-' + m.claimNumber} className="ribbon text-xs w-fit border border-pcx-400 rounded-sm" >
                        {m.internalReference}: {m.claimNumber}
                    </div>
                )}
            </div>
        </button>
    )
}

function stripTags(html) {
    const tmpDiv = document.createElement('div')
    tmpDiv.innerHTML = html
    return tmpDiv.textContent || tmpDiv.innerText || ""
}