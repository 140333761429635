import { Helmet } from "react-helmet-async"
import { Link, Outlet, useNavigate, useParams } from "react-router-dom"
import { Menu, MenuButton, MenuItem, MenuItems } from "@headlessui/react"
import { useTranslation, Trans } from "react-i18next"
import { Formik, Form, Field } from "formik"
import clsx from "clsx"
import _ from 'lodash'

import Modal from "../components/Modal"
import DeleteButton from "../components/DeleteButton"
import { TmImage, TrademarkBreadcrumbs } from "./TrademarkPortfolio"
import { useTrademarks, Trademark, TrademarkFamily } from "./TrademarksProvider"
import { trademarkFamilyUrl, trademarkUrl } from "./utils"
import { IconChevronDown, IconEdit } from "../components/icons"
import TrademarkComments from "./TrademarkComments"
import ProtectionIcon from "../components/ProtectionIcon"
import { Commodity } from "../products/products"
import { useRoles } from "../user/Auth"
import { emptyStringAsUndefined } from "../utils/strings"
import { FocusedBrowser } from "../documents/FocusedBrowser"
import { trademark_family } from "../data"
import { LinkedTabCard } from "../components/TabCard"
import { useProducts } from "../products/ProductsProvider"
import { createContext, useContext } from "react"
import { KeyValues } from "../patents/Family"
import { nameOf } from "../agents/utils"
import { createChangedTrademarkFamilyState } from "../components/update"
import { TrademarkFamilyChanged } from "../components/update/TrademarkChanged"

export default function TrademarkFamilyView() {
    const {t} = useTranslation()
    const {hasDocuments} = useRoles()

    const {reference} = useParams()

    const { trademarkFamilyByReference } = useTrademarks()

    const family = trademarkFamilyByReference[reference]

    if (!family) return null

    //const tree = buildPriorityTree(trademarks)

    return <>
        {/* @ts-ignore */}
        <Helmet>
            <title>{reference} | {t('trademarks')} | Patent Cockpit</title>
        </Helmet>
        <div className="portfolio-menu">
            <h2 className="flex flex-row gap-1 md:gap-4 whitespace-nowrap shrink min-w-0 grow pr-4">
                <TrademarkBreadcrumbs {...{ family }} />
            </h2>
        </div>
        <div className="main-content bg-pcx-100 pt-0">
            <div className="w-fit lg:min-w-4xl">
                <TrademarkFamilyProvider family={family}>
                    <LinkedTabCard links={[
                        { to: ".", label: t("overview") },
                        hasDocuments && { to: "documents", label: t("documents") }
                    ]} />
                </TrademarkFamilyProvider>
            </div>
        </div>
        <Outlet />
    </>
}

const TrademarkFamilyContext = createContext<TrademarkFamily | undefined>(undefined)

function TrademarkFamilyProvider({family, children}: {family: TrademarkFamily, children: React.ReactNode}) {
    return <TrademarkFamilyContext.Provider value={family}>{children}</TrademarkFamilyContext.Provider>
}

export function useTrademarkFamily() {
    return useContext(TrademarkFamilyContext)
}

export function TrademarkFamilyOverview() {
    const { isEditUser } = useRoles()
    const family = useTrademarkFamily()

    if (!family) return null

    return <div className="grid grid-cols-1 lg:grid-cols-[minmax(40rem,48rem)_400px] xl:grid-cols-[minmax(40rem,48rem)_480px] divide-pcx-200 max-lg:divide-y-2 lg:divide-x-2 gap-2 w-fit">
        <div>
            <TrademarkDetails {...{ family, isEditUser }} />
        </div>
        <div className="max-lg:pt-2 lg:pl-2">
            <TrademarkComments familyId={family.familyId} />
        </div>
        <Outlet />
    </div>
}

export function TrademarkFamilyDocuments() {
    const family = useTrademarkFamily()

    if (!family) return null

    return <FocusedBrowser entity={trademark_family} internalReference={family.reference} parentName={family.name} />
}

function findPriorityDate(trademarks: Trademark[], trademarkById: Record<number, Trademark>) {

    function findBasis(trademark: Trademark, visited: Set<number> = new Set()): Trademark | undefined {
        const basis = trademark.priorityId
        if (!basis) return trademark
        if (visited.has(basis)) return undefined
        const next = trademarkById[basis]
        return next ? findBasis(next, visited.add(basis)) : trademark
    }

    return _(trademarks).map(t => findBasis(t)).map(t => t?.applicationDate).min()
}

function TrademarkDetails({ family, isEditUser }: { family: TrademarkFamily, isEditUser: boolean }) {
    const {t} = useTranslation()
    const navigate = useNavigate()

    const { trademarksByFamilyId, deleteTrademarkFamily, ownersByTrademarkId, contactsByTrademarkId, trademarkById } = useTrademarks()
    const trademarks = trademarksByFamilyId[family.familyId] ?? []

    const owners = _(trademarks).flatMap(t => ownersByTrademarkId[t.trademarkId] ?? []).uniqBy('agentId').map(nameOf).sort().value()
    const contacts = _(trademarks).flatMap(t => contactsByTrademarkId[t.trademarkId] ?? []).uniqBy('agentId').map(nameOf).sort().value()

    const priorityDate = findPriorityDate(trademarks, trademarkById)

    return <>
        <div className="flex flex-col-reverse sm:flex-row gap-4 pb-2 border-b-2 border-pcx-200">
            <TmImage {...{ family, isEditable: isEditUser }} />
            <div className="grow">
                <div className="flex flex-row gap-1">
                    <h2 className="grow">{family.reference}: {family.name}</h2>
                    {isEditUser && <>
                        <Link className="btn-primary p-px w-5 h-5" to="edit"><IconEdit /></Link>
                        <DeleteButton className="btn-warn p-px w-5 h-5" deleteAction={() => deleteTrademarkFamily(family).then(() => navigate("/trademarks/portfolio"))} />
                    </>}
                </div>
                {emptyStringAsUndefined(family.externalReference?.trim()) && <div className="label mt-1">{family.externalReference}</div>}
                <p className="mt-2 max-w-prose">{family.description}</p>
            </div>
        </div>
        <div className="pt-2">
            <div className="grid grid-cols-1 sm:grid-cols-[160px_1fr] gap-x-4 gap-y-2">
                <div className="sm:col-start-2 flex flex-row flex-wrap gap-2 justify-between">
                    <h3 className="">{t('countries')}</h3>
                    {isEditUser && <AddMemberButton />}
                </div>
                <TrademarksByStatus status="planned" trademarks={trademarks.filter(t => t.status === 'planned')} hideEmpty />
                <TrademarksByStatus status="applied" trademarks={trademarks.filter(t => t.status === 'applied')} />
                <TrademarksByStatus status="registered" trademarks={trademarks.filter(t => t.status === 'registered')} />
                <TrademarksByStatus status="stopped" trademarks={trademarks.filter(t => t.status === 'stopped')} />

                <KeyValues title={t('owners')}         >{owners.map(agent =>   <div key={agent} className={agentStyle}>{agent}</div>)}</KeyValues>
                <KeyValues title={t('contact-persons')}>{contacts.map(agent => <div key={agent} className={agentStyle}>{agent}</div>)}</KeyValues>
                <KeyValues title={t('priorityDate')}>{priorityDate && <span>{priorityDate}</span>}</KeyValues>
            </div>
        </div>
        <div className="pt-2 mt-4 border-t-2 border-pcx-200 sm:pl-44">
            <TrademarkfamilyProducts {...{ familyId: family.familyId }} />
        </div>
        <TrademarkFamilyChanged />
    </>
}

const agentStyle = "after:content-[';_'] last:after:content-['']"

function TrademarksByStatus({status, trademarks = [], hideEmpty = false}: {status: Trademark['status'], trademarks: Trademark[], hideEmpty?: boolean}) {
    const {t} = useTranslation()

    if (hideEmpty && trademarks.length === 0) return null

    const counts = _.countBy(trademarks, 'countryCode')

    return <>
        <div className="label sm:text-right">{t(status)}:</div>
        <div className="max-sm:pl-2 inline-flex flex-row flex-wrap gap-1 self-start items-start ">
            <span className="text-slate-500 hidden last:inline">-</span>
            {_(trademarks)
                .sortBy(t => t.countryCode)
                .map(t =>
                    <Link key={t.reference} className="ribbon ribbon-clickable py-px" to={trademarkUrl(t)} title={t.reference}>
                        {counts[t.countryCode] > 1 
                            ? `${t.countryCode} (${t.reference})`
                            : t.countryCode
                        }
                    </Link>)
                .value()}
        </div>
    </>
}

/*
function buildPriorityTree(members: Trademark[], parentId = undefined) {
    const children = members
        .filter(entity => entity.priorityId === parentId)
        .map(parent => ({
            parent,
            children: buildPriorityTree(members, parent.trademarkId)
        }));

    return children;
}

function renderTree(tree, first = true) {
    return tree.map(({ parent, children }) => {
        return (
            <div key={parent.trademarkId}>
                <div className="flex flex-row gap-1 items-start">
                    {!first && <MinusIcon className="w-6 h-6 text-pcx-700" />}
                    <div>
                        <Link className="text-pcx-700" to={trademarkUrl(parent)}>{parent.reference}</Link>
                        {children && children.length > 0 &&
                            renderTree(children, false)}
                    </div>
                </div>
            </div>
        );
    });
}*/

function AddMemberButton() {
    const {t} = useTranslation()

    const {hasEuipoImport} = useRoles()
    const family = useTrademarkFamily()
    const { trademarksByFamilyId } = useTrademarks()

    const trademarks = trademarksByFamilyId[family.familyId] ?? []

    const itemStyle = "px-2 pr-6 py-2 hover:bg-pcx-400 font-medium text-pcx-500 hover:text-white -indent-4 pl-6 whitespace-nowrap"

    return (
        <Menu as="div"
            className='relative h-fit text-sm'
        >
            <MenuButton className={clsx(
                'flex flex-row items-center gap-2 rounded-sm border border-l border-pcx-500 p-1 pl-2',
                trademarks?.length > 0 ? 'text-pcx-500 hover:text-white hover:bg-pcx-400' : 'text-white bg-pcx-500 hover:bg-pcx-600'
            )}>
                <span className="whitespace-nowrap">{t("add-new-family-member")}</span>
                <IconChevronDown />
            </MenuButton>
            <MenuItems className='absolute z-10 flex flex-col min-w-full w-fit top-9 right-0 rounded-sm shadow-md border border-pcx-500 bg-white max-h-[50vh] overflow-y-auto'>
                <MenuItem>
                    <Link className={itemStyle} to="add">
                        {t("add-new-family-member")}
                    </Link>
                </MenuItem>
                {hasEuipoImport &&
                    <MenuItem>
                        <Link className={itemStyle} to="import">
                            {t("import")} (EUIPO/USPTO)
                        </Link>
                    </MenuItem>}

                {_(trademarks)
                    .sortBy(m => m.reference)
                    .map((m, i) =>
                        <MenuItem key={`fam-${i}`}>
                            <Link
                                className={itemStyle}
                                to={{ 
                                    pathname: "add", 
                                    search: `?copyFrom=${encodeURIComponent(m.trademarkId)}`
                            }}>
                                <Trans i18nKey="copy-from-name" values={{name:  `${m.reference} (${m.countryCode})`}} />
                            </Link>
                        </MenuItem>
                    )
                    .value()}

            </MenuItems>
        </Menu>
    )
}

export function PostTrademarkFamily() {
    const { t } = useTranslation()
    const { hasDocuments } = useRoles()
    const { reference } = useParams()
    const navigate = useNavigate()
    const { trademarkFamilies, trademarkFamilyByReference, postTrademarkFamily } = useTrademarks()

    const family = trademarkFamilyByReference[reference]
    const isAdding = !family

    const otherFamilyReferences = new Set(_(trademarkFamilies).filter(f => f.familyId !== family?.familyId).map(f => f.reference).value())

    const initialValues = family ?? {
        name: '',
        reference: '',
        description: '',
    }
    // TODO: unique reference constraint
    return (
        <Modal>
            <Formik
                initialValues={initialValues}
                onSubmit={async (values: TrademarkFamily) => {
                    const result = await postTrademarkFamily(values)
                    const state = createChangedTrademarkFamilyState({ hasDocuments, newTrademarkFamily: result, oldTrademarkFamily: family })
                    navigate(trademarkFamilyUrl(result), {state})
                }}
                validate={(values: TrademarkFamily) => {
                    const errors: Record<string, string> = {}
                    if (!values.reference) {
                        errors.reference = t('required')
                    } else if (otherFamilyReferences.has(values.reference)) {
                        errors.reference = t('reference-used')
                    }
                    return errors
                }}
            >{({ touched, errors }) =>
                <Form>
                    <div className="p-4 flex flex-col gap-4">
                        <h2>{isAdding ? t('add-trademark-family') : t('edit-trademark-family', { name: initialValues.reference })}</h2>
                        <label className="w-full">
                            <div className="label"> {t('reference')}</div>
                            <Field name="reference" required autoFocus className="form-input w-full mt-1" type="text" />
                            {touched.reference && errors.reference && <div className="text-red-500 mt-1">{errors.reference}</div>}
                        </label>
                        <label className="w-full">
                            <div className="label">{t('externalReference')}</div>
                            <Field name="externalReference" className="form-input w-full mt-1" type="text" />
                        </label>
                        <label className="w-full">
                            <div className="label">{t('name')}</div>
                            <Field name="name" className="form-input w-full mt-1" type="text" />
                        </label>
                        <label className="w-full">
                            <div className="label">{t('description')}</div>
                            <Field name="description" className="form-textarea mt-1 w-full sm:min-h-[10rem]" as="textarea" />
                        </label>
                    </div>
                    <div className="bg-pcx-200 flex flex-row-reverse gap-4 p-4">
                        <input type="submit" className="btn-primary" value={t('save')} />
                        {/* @ts-ignore */}
                        <Link to={-1} className="btn-secondary">{t('cancel')}</Link>
                    </div>
                </Form>
                }</Formik>
        </Modal>
    )
}

function TrademarkfamilyProducts({familyId}) {
    const {t} = useTranslation()
    const {isEditUser} = useRoles()

    const {trademarkProductMappingsByFamilyId} = useTrademarks()
    const { commodityById } = useProducts()

    function commodityName({commodityClass, commodityReference}: Pick<Commodity, 'commodityClass' | 'commodityReference'>) {
        return `${commodityClass ?? t('unclassified')}: ${commodityReference}`
    }

    const protectedProducts = _(trademarkProductMappingsByFamilyId[familyId] ?? [])
        .filter(m => m.protected)
        .map(m => commodityById[m.commodityId])
        .filter(c => c !== undefined)
        .sortBy((c: Commodity) => commodityName(c).toLocaleLowerCase())
        .value()

    return <>
        {isEditUser && <Link to={`mapping/edit?familyId=${familyId}`} className="btn-secondary p-px h-5 w-5 float-right mr-1"><IconEdit /></Link>}
        <h3>{t('products')}</h3>
        <ul className="list-none text-slate-700 pl-0">
            <li className="hidden last:block text-slate-500">{t('no-affected-products')}</li>
            {protectedProducts.map(({ isThirdParty, commodityId, commodityClass, commodityReference }) =>
                <li key={commodityId} className="flex flex-row gap-3 items-center">
                    <ProtectionIcon {...{ isThirdParty }} /> {commodityName({ commodityClass, commodityReference })}
                </li>)}
        </ul>
    </>
}