import { createContext, useContext } from "react";
import { Claim } from "./MemberClaimsProvider";
import { useCrud } from "../BackendProvider";
import { ClaimScope } from "./ClaimScope";
import _ from "lodash";
import { usePatents } from "../patents/PatentsProvider";

const ClaimsContext = createContext({
    claims: [] as Claim[],
    postClaim: (claim: Claim) => Promise.resolve({}),
    deleteClaim: (claim: Claim) => Promise.resolve({}),

    aggregatedClaims: {} as AggregatedClaims,
    claimScopesByFamily: {} as Record<number, number[]>,

    claimScopes: [] as ClaimScope[],
    claimScopeById: {} as Record<number, ClaimScope>,
    postClaimScope: (claimScope: ClaimScope) => Promise.resolve({}),
    deleteClaimScope: (claimScope: ClaimScope) => Promise.resolve({}),

    isLoading: false,
    reload: () => {},
})

export function useClaims() {
    return useContext(ClaimsContext)
}

export type AggregatedClaims = Record<number, { 
    claimsByMemberId: Record<number, ClaimVersion[]>,
    latestClaimScopes: number[],
    allClaimScopes: number[],
}>

export interface ClaimVersion {
    version: string
    versionDate: string
    claims: Claim[]
}

export function ClaimsProvider({children}) {
    const {members} = usePatents()

    const {data: claims, postMutation: postClaim, deleteMutation: deleteClaim, isLoading: isClaimsLoading, reload: reloadClaims} =
        useCrud<Claim>('claim', c => c.claimId)

    const {data: claimScopes, postMutation: postClaimScope, deleteMutation: deleteClaimScope, isLoading: isClaimScopesLoading, reload: reloadClaimScopes} =
         useCrud<ClaimScope>('claim-scope', c => c.claimScopeId)

    const claimScopeById = _.keyBy(claimScopes, 'claimScopeId')
    const claimsByClaimScopeId = _(claims).groupBy(c => c.claimScopeId).value()

    const familyByMemberId: Record<number, number> = _(members).map(m => [m.familyMemberId, m.patentFamilyId]).fromPairs().value()

    const aggregatedClaims: AggregatedClaims = _(claims)
        .groupBy(c => familyByMemberId[c.familyMemberId])
        .mapValues(claims => {
            const claimsByMemberId = _(claims)
                .groupBy(c => c.familyMemberId)
                .mapValues(cs => _(cs)
                    .groupBy(c => c.version)
                    .map(byVersion => ({version: byVersion[0].version, versionDate: byVersion[0].versionDate, claims: _.sortBy(byVersion, c => c.claimNumber)}))
                    .sortBy('versionDate')
                    .value())
                .value()
            // only latest claim scopes so we don't show e.g. for one member two claim scopes although only the latest one is relevant
            const latestClaimScopes = _(claimsByMemberId)
                .flatMap(claimVersions => claimVersions[claimVersions.length - 1].claims.map(c => c.claimScopeId).filter(Boolean))
                .uniq()
                .value()
            const allClaimScopes = _(claims).map(c => c.claimScopeId).uniq().value()

            return {claimsByMemberId, latestClaimScopes, allClaimScopes}
        })
        // .filter((cs, familyId) => familyById[familyId] !== undefined)
        .value()
    // console.log({aggregatedClaims})

    const claimScopesByFamily = _(claimScopes)
        .groupBy(c => familyByMemberId[claimsByClaimScopeId[c.claimScopeId]?.[0]?.familyMemberId])
        .mapValues(cs => _(cs).map(c => c.claimScopeId).uniq().value())
        .value()


    const isLoading = isClaimsLoading || isClaimScopesLoading

    const value = {
        claims, postClaim, deleteClaim, 
        aggregatedClaims, claimScopesByFamily,
        claimScopes, claimScopeById, postClaimScope, deleteClaimScope,
        isLoading, reload: () => {reloadClaims(); reloadClaimScopes()}
    }

    return <ClaimsContext.Provider {...{value}}>
        {children}
    </ClaimsContext.Provider>
}
