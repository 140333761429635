import { today, getLocalTimeZone } from "@internationalized/date";
import { Fragment, useState } from "react"
import { Dialog, DialogPanel, DialogTitle, Popover, PopoverButton, PopoverPanel, Transition, TransitionChild } from "@headlessui/react";
import { Link, useNavigate, useSearchParams } from "react-router-dom"
import { ChatBubbleBottomCenterTextIcon, ExclamationTriangleIcon } from "@heroicons/react/20/solid";
import { ExclamationTriangleIcon as OutlineExclamationTriangleIcon } from "@heroicons/react/24/outline";
import { Trans, useTranslation } from "react-i18next"
import { Field, Form, Formik, useField, useFormikContext } from "formik";
import clsx from "clsx";
import _ from "lodash";

import { usePatents } from "../../patents/PatentsProvider"
import { IpRight, PcCostCenter, PcCostCenterLink, PcIpRight, useDennemeyer, useIpRight, useStopIpRight, useTimeline, ValidationMessage } from "../DennemeyerProvider"
import { Member } from "../../patents/patents"
import Modal from "../../components/Modal"
import { IconSpinner } from "../../components/icons";
import { deriveStatus, extractDiscrepancy } from "./../utils"
import { isOpen as isMaintenanceActionOpen } from "./../settings/instruction_timeline";
import { isImported, renewalStatusExtraction, RenewalStatusInfo } from "../modern_states";
import { parseNumber } from "../../utils/strings";
import { DatePicker } from "../../components/input/DatePicker";
import { missingInformation } from "./../check";
import { ipSubTypes as allIpSubTypes, origins } from '..'
import { CostCenterSelection } from "./../IpImport";
import { PostIpRight, useIpRightImport } from "./PaymentHandlingProvider";
import { plusPeriod } from "../../utils/dates";
import { InformationCircleIcon } from "@heroicons/react/24/solid";
import { addOwnerUrl } from "../settings";

// EDIT Modal
export function MemberRenwalsEdit() {
    const [searchParams] = useSearchParams()

    const {memberById } = usePatents()

    const { ipRightById, owners } = useDennemeyer()

    const ipRightId = parseNumber(searchParams.get('ipRightId'))

    const familyMemberId = parseNumber(searchParams.get('familyMemberId'))
    const member = memberById[familyMemberId]

    //const dennemeyerId = searchParams.get('dennemeyerId') ?? undefined

    const pcIpRight = ipRightById[ipRightId]

    if (!member) {
        console.warn("Member not found")
        return null
    }

    const fallbackOwnerId = _(owners).minBy(o => o.name)?.ownerId

    if (fallbackOwnerId === undefined)
        return <NoOwnerModal />

    const missing = missingInformation(member)

    if (missing.length > 0) 
        return <NotEnoughDataModal {...{missingInformation: missing, member, pcIpRight}} />

    return <MemberPostRenewal {...{member, pcIpRight, fallbackOwnerId}} />
}

function MemberPostRenewal({member, pcIpRight, fallbackOwnerId}: {member: Member, pcIpRight?: PcIpRight, fallbackOwnerId: number}) {
    const {t} = useTranslation()

    const navigate = useNavigate()

    const familyMemberId = member.familyMemberId

    const { validationsByIpRightId, owners, } = useDennemeyer()
    const {initial, postIpRight, ipRight, isLoading} = useIpRightImport(familyMemberId, fallbackOwnerId)

    if (isLoading) return null

    const ipRightId = pcIpRight?.ipRightId

    // TODO: remove
    const discrepancy = ipRight && extractDiscrepancy(member, ipRight)

    if (discrepancy) {
        console.warn(`Patent Cockpit: ${discrepancy.pc} vs. Dennemeyer: ${discrepancy.dm}`)
    }

    const validations = validationsByIpRightId[ipRightId] ?? []
    const status = renewalStatusExtraction({ member, pcIpRight, ipRight, validationErrors: validations, })
    const imported = isImported(status)

    if (status.substatus === 'missing-data') {
        console.warn("Missing data. This shouldn't happen here...")
    }

    const canStop = ipRight?.Status !== 'Inactive' && imported
    const action = paymentHandlingAction({status, imported})
    const backOfficeNotice = action === 'reactivate' || action === 'initiate'
    // TODO: if reactivating, extract next due date from last maintenance action
    if (action === 'reactivate' && !initial?.firstDueDate && ipRight?.MaintenanceActions?.length > 0) {
        const today = new Date().toISOString().slice(0, 10)
        initial['firstDueDate'] = _(ipRight.MaintenanceActions)
            .map(ma => ma.DueDate)
            .filter(d => d > today)
            .min()
    }

    const minDate = member.validated 
        ? today(getLocalTimeZone()).add({months: -2, days: 1}) 
        : today(getLocalTimeZone()).add({days: 1})
    
    // console.log({origins})
    return (
        <Modal {...{overflowHidden: false}}>
            <Formik
                initialValues={initial}
                onSubmit={async (ipRight: PostIpRight) => {
                    const { status } = deriveStatus(member)
                    await postIpRight({...ipRight, status})
                    navigate("..")
                }}
                enableReinitialize
            >{({isSubmitting}) => 
                <Form>
                    <div className="p-4 space-y-4">
                        <h2>{member.internalReference}: {t('payment-handling')}</h2>
                        {/* {discrepancy && 
                            <div>
                                <ExclamationTriangleIcon className="w-5 h-5 inline-block text-warn-500 mr-1" />
                                Patent Cockpit: {discrepancy.pc} vs. Dennemeyer: {discrepancy.dm}
                            </div>} */}
                        <ValidationErrors {...{validations}} />
                        <div className="grid grid-cols-1 sm:grid-cols-[auto_auto] gap-x-3 gap-y-2 items-center">

                            <SubTypeSelector {...member} />

                            <label htmlFor="origin" className="label" title="Origin">{t('forecast.filing-procedure')}</label>
                            <Field id="origin" name="origin" as="select" className="form-select">
                                {origins.map(origin => <option key={origin.name} value={origin.name}>{t(origin.description)}</option>)}
                            </Field>

                            <label htmlFor="ownerId" className="label">{t('owner')}</label>
                            <Field id="ownerId" name="ownerId" as="select" className="form-select">
                                {owners.map(owner => <option key={owner.ownerId} value={owner.ownerId}>{owner.name}</option>)}
                            </Field>

                            <label htmlFor="costCenters" className="label">{t('cost-centers')}</label>
                            <CostCenterSelectionField name="costCenters" ipRightId={ipRightId} />

                            <div className='sm:col-span-2 border-b-2 border-slate-600/20' />

                            <label htmlFor="firstDueDate" className="label self-start sm:pt-2.5 normal-case">{t('first-due-date-with-payment-provider')}</label>
                            <div>
                                <Field name="firstDueDate" className="form-input" as={DatePicker} minValue={minDate}/>
                                <p className='max-w-prose text-xs text-slate-600 mt-2'>{t('first-due-date-comment')}</p>
                            </div>

                            <div className="empty:hidden sm:col-span-2">
                                <NextDueDateTooClose name="firstDueDate" />
                            </div>

                            {backOfficeNotice && <>
                                <div className='sm:col-span-2 border-b-2 border-slate-600/20' />
                                <div className="sm:col-span-2 border-l-4 border-pcx-300 bg-pcx-200 text-pcx-600 text-center p-4">
                                    <ExclamationTriangleIcon className="size-4 text-pcx-400 inline mr-2" />
                                    {t('backoffice-7-days')}
                                </div>
                            </>}
                        </div>
                    </div>
                    <div className="flex flex-col gap-4 bg-pcx-200 p-4 rounded-b-xl overflow-hidden">
                        <div className="flex flex-col sm:flex-row-reverse gap-4 sm:items-baseline">
                            <button type="submit" className="btn-primary flex flex-row gap-2 items-center" disabled={isSubmitting}>
                                {t(renewalHandlingActionName(action))}
                                {isSubmitting && <IconSpinner className="inline size-4 animate-spin" />}
                            </button>
                            <Link className="btn-secondary text-center" to="..">{t('back')}</Link>
                            <div className="sm:mr-auto">
                                {canStop
                                    ? <StopDmRightButton {...{ member, ipRight, ipRightId, disabled: !canStop }} />
                                    : null}
                            </div>
                        </div>
                    </div>
                </Form>
            }</Formik>
        </Modal>
    )
}

function ValidationErrors({validations}: {validations: ValidationMessage[]}) {
    const {t} = useTranslation()

    return validations.length > 0
        ? <div className="border-b-2 border-slate-600/20 pb-2">
            <p className="text-red-900 font-medium mb-1">{t("errors-from-last-attempt")}</p>
            <ul className="list-disc space-y-2 pl-8">
                {validations.map(v =>
                    <li key={v.validationId} className="text-red-700 list-outside">
                        <span title={v.message}>{t("dm_messages." + v.errorCode)}</span>
                        <Popover className="relative inline-block ml-2">
                            <PopoverButton><ChatBubbleBottomCenterTextIcon className="inline-block h-4 w-4 " /></PopoverButton>
                            <PopoverPanel className="absolute z-10 max-w-prose p-4 bg-white rounded-md shadow-lg text-xs border ring-1 ring-red-700/5">
                                <p className="text-slate-700">{v.message}</p>
                                <div className="flex flex-row-reverse gap-4 mt-4">
                                    <PopoverButton className="btn-secondary">{t('close')}</PopoverButton>
                                    <PopoverButton as="a" href={`mailto:support@patent-cockpit.com?body=${encodeURIComponent(v.message)}`} className="btn-tertiary">{t("support")}</PopoverButton>
                                </div>
                            </PopoverPanel>
                        </Popover>
                    </li>
                )}
            </ul>
        </div>
        : null
}

type PaymentHandlingAction = 'edit' | 'initiate' | 'reactivate'

export function paymentHandlingAction({status, imported}: {status: RenewalStatusInfo, imported: boolean}): PaymentHandlingAction {
    return (status.substatus === 'stopped' || status.substatus === 'inactive') && imported
        ? 'reactivate'
        : status.status === 'ready'
        ? 'edit'
        : 'initiate'
}

export function renewalHandlingActionName(paymentHandlingAction: PaymentHandlingAction) {
    return {
        'edit': 'edit-payment-handling',
        'initiate': 'initiate-payment-handling',
        'reactivate': 'reactivate-payment-handling',
    }[paymentHandlingAction]
}

function SubTypeSelector({familyMemberId, patentFamilyId, ipType}: Member) {
    const {t} = useTranslation()
    // At first we assume that there will be only one parent (for divisionals and continuations)
    const {membersByFamilyId} = usePatents()

    const {values, setFieldValue} = useFormikContext<PostIpRight>()

    const ipSubTypes = allIpSubTypes[ipType] ?? []

    const ipSubType = values.ipSubType
    const members = (membersByFamilyId[patentFamilyId] ?? []).filter((m: Member) => m.familyMemberId !== familyMemberId)
    const parentEnable = ipSubTypes.find(t => t.name === ipSubType)?.withLink && members.length > 0 
    // console.log({parentEnable, values})

    const name = 'ipSubType'
    return <>
        <label htmlFor={name} className="label" title="ipSubType">{t('specialIpType')}</label>
        <Field id={name} name={name} as="select" className="form-select">
            {ipSubTypes.map(type => <option key={type.name} value={type.name}>{t(type.label ?? type.name)}</option>)}
        </Field>
        <label htmlFor='parent' className={clsx('label', !parentEnable && 'opacity-40')} title="Former">{t('linked-ip-right')}</label>
        <select
            name="parent" id="parent" className='form-select disabled:opacity-40' disabled={!parentEnable}
            value={(parentEnable && values.parent) ?? '-1'} onChange={e => setFieldValue('parent', e.target.value)}
        >
            <option value={-1}>{t('select')}...</option>
            {_(members)
                .sortBy(m => m.internalReference.toLowerCase())
                .map((m: Member) =>
                    <option key={m.familyMemberId} value={m.familyMemberId}>
                        {m.internalReference}
                    </option>)
                .value()}
        </select>
    </>
}

function CostCenterSelectionField({name, ipRightId}: {name: string, ipRightId?: number}) {
    const [, meta, helpers] = useField<(PcCostCenter & PcCostCenterLink)[]>(name)
    const { setValue } = helpers
    const { value } = meta

    return <CostCenterSelection {...{
        value, ipRightId, onChange: ({target: {value}}) => {
            setValue(value)
        }
    }} />
}

function NextDueDateTooClose({name}: {name: string}) {
    const [, meta] = useField<string>(name)
    const { value } = meta

    const limit = plusPeriod(new Date(), {days: 14})

    if (value && value < limit) {
        return (
            <div className="p-3 border-l-4 border-pcx-400 bg-pcx-100 text-pcx-700 flex flex-row items-center gap-4">
                <InformationCircleIcon className="size-6 text-pcx-400" />
                <div className="max-w-2xl text-sm">
                    Next due date is soon. Write additionally an email to the payment provider to make sure, the payment is handled in time.
                </div>
            </div>
        )
    } else {
        return null
    }
}

export function NoOwnerModal() {
    const {t} = useTranslation()
    return (
        <Modal>
            <div className="p-4">
                <h3 className="mb-2">{t('no-owner-defined')}</h3>
                <p>
                    <Trans
                        i18nKey='define-owner'
                        components={{ here: <Link className="underline decoration-pcx-500 hover:decoration-pcx-400" to={addOwnerUrl} /> }}
                    />
                </p>
            </div>
            <div className="p-4 flex flex-row-reverse gap-4 bg-pcx-200">
                <Link className="btn-primary" to={addOwnerUrl}>{t('add-owner-details')}</Link>
                <Link className="btn-secondary" to="..">{t('cancel')}</Link>
            </div>
        </Modal>
    )
}

function NotEnoughDataModal({missingInformation, pcIpRight, member}: {missingInformation: string[], member: Member, pcIpRight?: PcIpRight}) {
    const {t} = useTranslation()

    const {ipRightById} = useDennemeyer()
    const { validationsByIpRightId, } = useDennemeyer()
    const {ipRight: _ipRight} = useIpRight(pcIpRight?.dennemeyerId)
    const ipRight = _ipRight?.Data

    const ipRightId = pcIpRight?.ipRightId
    const validations = validationsByIpRightId[pcIpRight?.ipRightId] ?? []

    const status = renewalStatusExtraction({ member, pcIpRight, ipRight, validationErrors: validations, })

    const canStop = status.status === 'ready'


    // const {ignore} = useDennemeyer()
    // const navigate = useNavigate()

    const translated = _(missingInformation).map(missing => t(missing)).sortBy().value()

    // async function doIgnore() {
    //     await ignore(familyMemberId)
    //     navigate("..")
    // }

    return (
        <Modal>
            <div className='p-4'>
                <h2 className='mb-4'>{t("missing-information")}</h2>
                <p className='sm:text-lg text-gray-800'>{t("missing-information-no-registration")}</p>
                <div className='flex flex-row gap-4 items-center'>
                    <div className='max-sm:hidden px-4 text-warn-600'><OutlineExclamationTriangleIcon className='h-12 w-12' /></div>
                    <ul className='leading-relaxed text-lg lg:columns-xs lg:max-h-32'>
                        {translated.map(missing => <li key={missing}>{missing}</li>)}
                    </ul>
                </div>
                <p className='mt-4 sm:text-lg text-gray-800'>{t("edit-case-complete-info")}</p>
            </div>
            <div className='p-4 bg-pcx-200 flex flex-col sm:flex-row-reverse gap-4'>
                <Link className='btn-primary max-sm:text-center' to={"../edit"}>{t('edit')}</Link>
                <Link className='btn-secondary max-sm:text-center' to={".."}>{t('back')}</Link>

                {/* <button onClick={() => doIgnore()} className='btn-tertiary sm:mr-auto'>{t('ignore')}</button> */}
                <div className="grow max-sm:hidden" />
                {canStop && <StopDmRightButton {...{ member, ipRightId, ipRight }} />}
            </div>
        </Modal>
    )
}

function StopDmRightButton({member, ipRight, ipRightId, disabled}: {member: Member, ipRight: IpRight, ipRightId?: number, disabled?: boolean}) {
    const {t} = useTranslation()
    const navigate = useNavigate()

    const {postMember} = usePatents()
    const {calculateDueDates} = useTimeline()
    const { fullStop } = useStopIpRight(ipRightId)
    
    const [isOpen , setIsOpen ] = useState(false)
    const [isSubmitting, setIsSubmitting] = useState(false)
    const alreayStoppedWithPc = member?.familyMemberStatus === 'stopped'
    const [alsoStopWithPc, setAlsoStopWithPc] = useState(true)

    const closeModal = () => setIsOpen(false)

    async function handleStop() {
        if (!isSubmitting) {
            setIsSubmitting(true)
            if (alsoStopWithPc && !alreayStoppedWithPc) {
                await postMember({ ...member, familyMemberStatus: 'stopped' })
            }
            await fullStop()
            setIsSubmitting(false)
            setIsOpen(false)
            navigate("..")
        }
    }

    const openMaintenanceActions = (ipRight?.MaintenanceActions ?? [])
        .map(ma => ({...ma, ...calculateDueDates(ma.DueDate)}))
        .filter(ma => isMaintenanceActionOpen(ma.status))

    //console.log({openMaintenanceActions, maintenanaceActions: ipRight?.MaintenanceActions})
    return (
        <>
            <button {...{ disabled }} type="button" onClick={() => setIsOpen(!isOpen)} className='shrink-0 btn-warn-secondary px-2 py-1 whitespace-nowrap'>
                {t('stop-payment-handling')}
            </button>
            <Transition appear show={isOpen} as={Fragment}>
                <Dialog as="div" className="relative z-50" onClose={closeModal}>
                    {/* Background */}
                    <TransitionChild
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <div className="fixed inset-0 backdrop-filter backdrop-blur-sm bg-black/25" />
                    </TransitionChild>

                    <div className="fixed inset-0 overflow-y-auto">
                        <div className="flex min-h-full items-center justify-center p-4 text-center">
                            <TransitionChild
                                as={Fragment}
                                enter="ease-out duration-300"
                                enterFrom="opacity-0 scale-95"
                                enterTo="opacity-100 scale-100"
                                leave="ease-in duration-200"
                                leaveFrom="opacity-100 scale-100"
                                leaveTo="opacity-0 scale-95"
                            >
                                <DialogPanel className="w-full max-w-lg transform overflow-hidden rounded-sm bg-white text-left align-middle shadow-lg transition-all">
                                    <DialogTitle as="h3" className="p-4" >
                                        {member.internalReference}: {t('renewals-desc.stop')}
                                    </DialogTitle>
                                    <div className="p-4 pt-0">
                                        <p className='font-base text-gray-500'>
                                            {t('renewals-desc.really-stop')}
                                        </p>
                                    </div>
                                    {openMaintenanceActions.length > 0 &&
                                        <div className='px-4 pb-4'>
                                            <div className='bg-warn-200 rounded px-4 py-2 text-warn-900 flex flex-row gap-4 items-center'>
                                                <ExclamationTriangleIcon className="w-8 h-8 inline-block text-warn-500" />
                                                {/* TODO: Translations */}
                                                <div>
                                                    <p>If you stop, the following fee payments will be cancelled</p>
                                                    <ul>
                                                        {openMaintenanceActions.map(ma =>
                                                            <li key={ma.DennemeyerId}>
                                                                {ma.Annuity && `Annuity ${ma.Annuity}; `}Due: {ma.instructionDueDate}
                                                            </li>)}
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>}
                                    {!alreayStoppedWithPc && <label className='flex flex-row gap-2 px-4'>
                                        <input type="checkbox" checked={alsoStopWithPc} onChange={() => setAlsoStopWithPc(!alsoStopWithPc)} />
                                        <span className='text-gray-500'>{t('stop-pc-case')}?</span>
                                    </label>}

                                    <div className="mt-4 p-4 bg-pcx-200 flex flex-row-reverse gap-2">
                                        <button type="button" className='btn-warn' onClick={() => handleStop()}>
                                            {t('stop')} {isSubmitting && <IconSpinner className="inline w-5 h-5 animate-spin" />}
                                        </button>
                                        <button
                                            type="button"
                                            className="btn-secondary"
                                            onClick={closeModal}
                                        >
                                            {t('back')}
                                        </button>
                                    </div>
                                </DialogPanel>
                            </TransitionChild>
                        </div>
                    </div>
                </Dialog>
            </Transition>
        </>
    )
}