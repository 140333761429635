import { useState } from "react"
import { getCaseInfo, parseXmlResponse } from "./uspto"
import { CopyButton } from "../components/CopyButton"
import { useMessages } from "../Messages"
import { IconSpinner } from "../components/icons"

/*
{
    "countryCode": "US",
    "applicationNumber": "78857587",
    "applicationDate": "2006-04-10",
    "registrationNumber": "3531057",
    "registrationDate": "2008-11-11",
    "words": "SAFETY LOCK TECHNOLOGY",
    "image": "78857587.png",
    "classes": [
      {
        "number": 7,
        "description": "Electric mixers for waste water; mechanical mixing machines; mixing tools for the connection to the aforementioned mixers or mixing machines, in particular hyperbolid-mixers to be detachably mounted to a mixing-shaft for purposes of mixing waste water"
      }
    ],
    "brandType": "Combined",
    "owner": "Invent Umwelt-und Verfahrenstechnik AG"
 }*/

const header = [
    //'familyName',
    //'familyReference',
    //'familyExtReference',
    //'description',
    //'reference',
    //'memberExtReference',
    'countryCode',
    'brandType',
    'words',
    'applicationNumber',
    'applicationDate',
    'registrationNumber',
    'registrationDate',
    //'renewalDate',
    //'status',
    //'fromWipo',
    'url',
    'trademarkClasses',
    //'comment',
    'owner',
    //'contactPerson'
]
// https://branddb.wipo.int/en/similarname/brand/US502006078857587

export default function UsptoImportView() {
    const {setErrorMessage} = useMessages()

    const [number, setNumber] = useState('')
    const [result, setResult] = useState(undefined)

    async function onSubmit() {
        setResult(-1)
        try {
            const response = await getCaseInfo(number)
            const body = await response.text()

            const parser = new DOMParser();
            const xml_doc = parser.parseFromString(body, "text/xml");

            const tm = parseXmlResponse(xml_doc)
            if (xml_doc.activeElement?.tagName === 'parsererror') {
                console.warn(xml_doc.activeElement.textContent)
                throw new Error(body)
            }

            const url = `https://branddb.wipo.int/en/similarname/brand/${tm.countryCode}50${tm.applicationDate?.slice(0, 4)}0${tm.applicationNumber}`

            const trademarkClasses = tm.classes?.map(e => e.number).join(',')

            setResult({ ...tm, url, trademarkClasses })
        } catch (err) {
            console.warn(err)
            setErrorMessage(err.message)
            setResult(undefined)
        }
    }

    return <>
        <div className="modern-header-row">
            <h2>USPTO Import</h2>
        </div>
        <div className="p-4">
            <div className="max-w-sm flex flex-col gap-2">
                <label htmlFor="number">Application Number</label>
                <input id="number"
                    type="text" className="form-input invalid:border-warn-400 invalid:text-warn-800 invalid:focus:ring-warn-400"
                    value={number} onChange={e => setNumber(e.target.value)}
                    pattern="^\d{8}$" title="Please enter an 8-digit number"
                />
                <button className="btn-primary" onClick={onSubmit}>
                    Importieren {result === -1 && <IconSpinner className="inline mb-1 ml-2 animate-spin size-4" />}
                </button>
            </div>
            {result !== undefined && result !== -1 && 
                <div className="mt-8">
                    <CopyButton id="results" />
                    <table id="results" className="mt-4 text-xs">
                        <thead>
                            <tr className="border-b-2 border-pcx-300">
                                {header.map((e, i) =>
                                    <th key={i} className="px-1 py-1 font-medium text-pcx-700 capitalize text-left">
                                        {e}
                                    </th>)}
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                {header.map((e, i) => {
                                    const v = result[e]
                                    return <td key={i} className="px-1 py-1 whitespace-nowrap">
                                        {e === 'url' ? <a className="underline" target="_blank" rel="noreferrer" href={v}>{v}</a> : v}
                                    </td>
                                })}
                            </tr>
                        </tbody>
                    </table>
                </div>}
        </div>
    </>
}