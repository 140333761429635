import { parseBackend } from "../../backend";
import { BrandStatus, BrandType, Trademark } from "../../trademarks/TrademarksProvider";

export function searchBrand(registrationNumber: string): Promise<EuipoTrademark | EuipoError> {
    return parseBackend(fetch(`/api/euipo/pass-through/trademark-search/trademarks/${registrationNumber}`));
}

export type EuipoError = {
    type: string,
    title: string,
    detail: string,
    status: number,
}

// a function that checks if the response is a trademark or an error
export function isTrademark(response: EuipoTrademark | EuipoError): response is EuipoTrademark {
    return (response as EuipoTrademark).applicationNumber !== undefined;
}

export type EuipoTmType = "WORD" | "FIGURATIVE" | "SHAPE_3D" | "COLOUR" | "SOUND" | "HOLOGRAM" | "OLFACTORY" |
        "POSITION" | "PATTERN" | "MOTION" | "MULTIMEDIA" | "OTHER";

export interface EuipoTrademark {
    applicationNumber: string;
    applicationDate: string;
    applicantReference: string;
    applicationLanguage: string;
    secondLanguage?: string;
    markKind?: "INDIVIDUAL" | "EU_COLLECTIVE" | "EU_CERTIFICATION";
    markFeature?: EuipoTmType;
    markBasis?: "EU_TRADEMARK" | "INTERNATIONAL_TRADEMARK";
    wordMarkSpecification?: { verbalElement: string };
    markImage?: { 
        imageFormat: "JPG" | "TIFF",
        colourClaim: EuipoTranslatedText[],
        viennaClasses: string[] };
    markSound?: { soundFormat: "MP3" };
    markVideo?: { videoFormat: "MP4", viennaClasses: string[] };
    markModel? : { modelFormat: "OBJ" | "X3D" | "STL", viennaClasses: string[] };
    applicants?: EuipoPerson[];
    representatives?: EuipoPerson[];
    receiptDate?: string;
    registrationDate?: string;
    designationDate?: string;
    publicationDate?: string;
    expiryDate?: string;
    status: EuipoStatus;
    statusDate: string;
    description?: EuipoTranslatedText[];
    disclaimer?: EuipoTranslatedText[];
    goodsAndServices: { classNumber: number, description: { language: string, terms: string[] }[]}[];
    priorities?: {
        country: string,
        applicationNumber?: string,
        applicationDate?: string,
        partialIndicator?: boolean,
        status: "TO_DETERMINE" | "CLAIMED" | "REFUSED" | "ABANDONED",
    }[];
    seniorities?: EuipoSeniority[];
    // There are more possible fields, but I am too lazy
}

export type EuipoTranslatedText = { language: string, text: string}

export interface EuipoPerson { office: "EM" | "WO", identifier: string, name: string }[];

export interface EuipoSeniority {
    seniorityKind: "NATIONAL_REGISTRATION_IN_MEMBER_STATE" | "INTERNATIONAL_REGISTRATION_IN_MEMBER_STATE" 
    country: string;
    applicationNumber: string;
    applicationDate: string;
    registrationNumber: string;
    registrationDate: string;
    priorityDate: string;
    partialIndicator: boolean;
    status: "CLAIMED" | "ACCEPTED" | "REFUSED" | "PARTIALLY_REFUSED" | "ABANDONED" | "DEFICIENCY";
}

/*
Status of the trade mark

For EUTMs:

RECEIVED. The trade mark application has been accepted by the Office (has met the minimum filing requirements) and has not yet been assigned to an examiner.
UNDER_EXAMINATION. The trade mark application has been accepted by the Office (has met the minimum filing requirements) and that this application has been assigned to an examiner.
APPLICATION_PUBLISHED. The trade mark application has been added to the Registry for that Office and has been published in a way that allows the public to challenge the registration.
REGISTRATION_PENDING. Transitory status prior to registration.
REGISTERED. The trade mark application has been registered with the Office.
WITHDRAWN. The owner of the trade mark application withdrew (e.g. abandoned) the application and the application is no longer active.
REFUSED. This trade mark application was refused, dismissed, or invalidated by the Office and this application is no longer active.
OPPOSITION_PENDING. The registered trade mark has been published for opposition, at which time one or more challenges to registration were filed but they have not yet been decided.
APPEALED. An appeal of the Office’s final refusal to register a pending trade mark application is currently pending.
CANCELLATION_PENDING. This trade mark application has been registered with the Office, but it is currently undergoing a challenge which may result in its removal from the registry.
CANCELLED. The trade mark application was registered, but subsequently it was cancelled or invalidated and removed from the registry.
SURRENDERED. The trade mark application was registered, but subsequently voluntarily removed from the registry by action of the owner.
EXPIRED. The trade mark application registered, but subsequently removed from the registry when the registration was not successfully maintained.
APPEALABLE.
FOR IRs:

RECEIVED. The trade mark application has been accepted by the Office (has met the minimum filing requirements) and has not yet been assigned to an examiner.
UNDER_EXAMINATION. The trade mark application has been accepted by the Office (has met the minimum filing requirements) and that this application has been assigned to an examiner.
OPPOSITION_PENDING. The registered trade mark has been published for opposition, at which time one or more challenges to registration were filed but they have not yet been decided.
START_OF_OPPOSITION_PERIOD.
ACCEPTANCE_PENDING
REFUSED. This trade mark application was refused, dismissed, or invalidated by the Office and this application is no longer active.
ACCEPTED. The trade mark application has been registered with the Office.
APPEALED. An appeal of the Office’s final refusal to register a pending trade mark application is currently pending.
REMOVED_FROM_REGISTER. The trade mark application registered, but subsequently removed from the registry when the registration was not successfully maintained.
CANCELLED. The trade mark application was registered, but subsequently it was cancelled or invalidated and removed from the registry.
CANCELLATION_PENDING.
APPEALABLE.
*/
export type EuipoStatus = "RECEIVED" | "UNDER_EXAMINATION" | "APPLICATION_PUBLISHED" | "REGISTRATION_PENDING" | "REGISTERED" | "WITHDRAWN" | "REFUSED" | "OPPOSITION_PENDING" | "APPEALED" | "CANCELLATION_PENDING" | "CANCELLED" | "SURRENDERED" | "EXPIRED" | "APPEALABLE" | "START_OF_OPPOSITION_PERIOD" | "ACCEPTANCE_PENDING" | "ACCEPTED" | "REMOVED_FROM_REGISTER"

export type EuipoImportResult = Partial<Trademark> & {owner?: string | number, classes: {number: number, description: string}[]}

export function toPcTrademark(trademark: EuipoTrademark, language: string = 'en', reference: string = ''): EuipoImportResult {
    const classes = trademark.goodsAndServices.map(g => {
        const number = g.classNumber
        const description = (g.description.find(d => d.language === language)?.terms ?? g.description[0].terms ?? []).join('; ')
        return {number, description}
    })
    // TODO: agents? owners?
    const owner = trademark.applicants?.[0]?.name

    return {
        reference,
        words: trademark.wordMarkSpecification?.verbalElement,
        countryCode: 'EU',
        brandType: toPcType(trademark.markFeature),
        applicationNumber: trademark.applicationNumber,
        applicationDate: trademark.applicationDate ?? trademark.receiptDate,
        registrationNumber: trademark.applicationNumber,
        registrationDate: trademark.registrationDate,

        renewalDate: trademark.expiryDate,
        status: toPcStatus(trademark.status),
        url: `https://branddb.wipo.int/en/brand/EM500000${trademark.applicationNumber}`,

        owner, classes,
    }
}

function toPcType(euipoType: EuipoTmType): BrandType {
    switch(euipoType) {
        case "WORD": return "Word";
        case "FIGURATIVE": return "Image";
        case "SHAPE_3D": return "3D";
        case "COLOUR": return "Colour";
        case "SOUND": return "Sound";
        case "HOLOGRAM": return "Hologram";
        case "OLFACTORY": return "Smell";
        case "POSITION": return "Position";
        case "PATTERN": return "Pattern";
        case "MOTION": return "Motion";
        case "MULTIMEDIA": return "Multimedia";
        case "OTHER": default: return "Other";
    }
}

function toPcStatus(status: EuipoStatus): BrandStatus {
    switch(status) {
        case "RECEIVED": return "applied";
        case "UNDER_EXAMINATION": return "applied";
        case "APPLICATION_PUBLISHED": return "applied";
        case "REGISTRATION_PENDING": return "applied";
        case "REGISTERED": return "registered";
        case "WITHDRAWN": return "stopped";
        case "REFUSED": return "applied";
        case "OPPOSITION_PENDING": return "applied";
        case "APPEALED": return "applied";
        case "CANCELLATION_PENDING": return "applied";
        case "CANCELLED": return "stopped";
        case "SURRENDERED": return "stopped";
        case "EXPIRED": return "stopped";
        case "APPEALABLE": return "applied";
        case "START_OF_OPPOSITION_PERIOD": return "applied";
        case "ACCEPTANCE_PENDING": return "applied";
        case "ACCEPTED": return "registered";
        case "REMOVED_FROM_REGISTER": return "stopped";
        default: return "applied";
    }
}