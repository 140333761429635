import { Fragment } from "react";
import { Link } from "react-router-dom";
import { Menu, MenuButton, MenuItem, MenuItems, Transition } from "@headlessui/react";
import { BoltIcon, TagIcon, UserCircleIcon, UserGroupIcon, UserIcon } from "@heroicons/react/24/solid";
import clsx from "clsx";

import { IconCog, IconArrowLeftRight, IconPowerOff } from "../components/icons";
import { useTranslation } from "react-i18next"
import { useAuth, useRoles } from "../user/Auth"
import { useBackend } from "../BackendProvider"
import { useUserSettings } from "../user/UserSettingsProvider"
import { QuestionMarkCircleIcon } from "@heroicons/react/24/outline";


export function UserMenu() {
    const { t } = useTranslation()

    const {teams, user: aUser} = useAuth()
    const {isAdmin, isSuperAdmin} = useRoles()

    const user = aUser?.name

    const { images } = useBackend()
    const { userSettings } = useUserSettings()

    const avatar = images.find(i => i.entity === "user" && i.entityId === userSettings?.userId)
    const displayName = userSettings?.displayName ?? user

    const userNavigation = [
        {to: "/settings/user", name: t("settings"), icon: <IconCog />},
        isAdmin && {to: "/settings/team", name: t("team-settings"), icon: <UserGroupIcon className="h-6 w-6" />},
        isSuperAdmin && {to: "/settings/admin", name: "Admin", icon: <BoltIcon className="h-6 w-6" />},
        {},
        {to: "/settings/agents", name: t("agents"), icon: <UserIcon className="h-6 w-6" />},
        {to: "/settings/tags", name: t("tags"), icon: <TagIcon className="h-6 w-6" />},
        isSuperAdmin && {to: "/epo", name: "EPO Import", icon: <span>&#x1F1EA;&#x1F1FA;</span>}, //<CloudArrowDownIcon className="h-6 w-6" />},
        isSuperAdmin && {to: "/uspto", name: "USPTO Import", icon: <span>&#x1F1FA;&#x1F1F8;</span>}, //<CloudArrowDownIcon className="h-6 w-6" />},
        {},
        teams?.length > 1 && {to: "/teams", name: t("switch-team"), icon: <IconArrowLeftRight />},
        // TODO: Teams settings, admin settings
        {href: "mailto:support@patent-cockpit.com", name: t("support"), icon: <QuestionMarkCircleIcon className="h-6 w-6" />},
        {to: "/signout", name: t("log-out"), icon: <IconPowerOff />}
    ].filter(Boolean)

    return (
        <Menu as="div" className="relative inline-block text-left">
            <div>
                <MenuButton 
                    title={displayName}
                    className="relative flex max-w-xs items-center rounded-full text-sm focus:outline-none focus:ring-2 focus:ring-pcx-500 ring-offset-2 hover:ring-2 hover:ring-pcx-400"
                >
                    <span className="absolute -inset-1.5" />
                    <span className="sr-only">Open user menu</span>
                    {avatar?.url
                        ? <div className="h-8 w-8 rounded-full overflow-hidden flex items-center justify-center">
                            <img className="min-w-full min-h-full xbg-cover" src={avatar.url} alt="" />
                        </div>
                        : <UserCircleIcon className="h-7 w-7 rounded-full text-gray-700 hover:text-pcx-700" />}
                    <span className="max-xl:hidden ml-2 mr-2 text-sm font-semibold leading-6 text-gray-900" aria-hidden="true">{displayName}</span>
                </MenuButton>
            </div>
            <Transition
                as={Fragment}
                enter="transition ease-out duration-200"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
            >
                <MenuItems className="absolute right-0 z-50 mt-2 w-48 origin-top-right rounded-md overflow-hidden bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                    <div className="px-4 py-2 text-sm text-gray-400">{displayName}</div>
                    <div className="w-full h-px bg-gray-200" />
                    {userNavigation.map((item, itemIndex) => !item.name
                        ? <div key={itemIndex} className="w-full h-px bg-gray-200" />
                        : <MenuItem key={itemIndex}>
                            {({ focus }) => (
                                'href' in item
                                    ? <a href={item.href} className={menuItemStyle(focus)}>
                                        <span className="w-7">{item.icon}</span> {item.name}
                                    </a>
                                    : <Link
                                        to={item.to}
                                        className={menuItemStyle(focus)}
                                    >
                                        <span className="w-7">{item.icon}</span> {item.name}
                                    </Link>
                            )}
                        </MenuItem>
                    )}
                </MenuItems>
            </Transition>
        </Menu>
    )
}

function menuItemStyle(active: boolean) {
    return clsx(
        active ? 'bg-pcx-100' : '',
        'flex flex-row gap-2 px-4 py-2 text-sm text-gray-700 whitespace-nowrap'
    )
}